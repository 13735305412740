import webui from "@tinqin/tinqin-web-ui";

//dashboards to be loaded from BE
//specific widgets are loaded depending on context (gp, solidarm, corporate)
const getDashboardConfig = async ({ id = "", defaultRequestOptions = {} } = {}) => {
    let module;
    switch (id) {
        case "oav":
            module = await import(
                /* webpackChunkName: "dashboard.oav" */ `./oav/index.js`
            );
            //loading oav FE module
            await module.loadOAV();
            return module.getConfig();
        case "diagnostics":
                module = await import(
                    /* webpackChunkName: "dashboard.diagnostics" */ `./diagnostics/index.js`
                );
                //loading oav FE module
                await module.loadOAV();
                return module.getConfig();
        default:
            const dispatch = webui.getDispatchMethod();
            return dispatch(webui.getActionByName("request.request")(defaultRequestOptions));
    }
};

export { getDashboardConfig };