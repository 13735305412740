import React, { useCallback } from "react";
import webui from "@tinqin/tinqin-web-ui";
import { IComponentProps, SirenEntityBuilder } from "../../utils/commonTypes";
import EntityBuilder from "@tinqin/tinqin-web-ui/src/utils/EntityBuilder";
import ContactLinks from "../contact-links/ContactLinks";
import SpecialPhone from "../special-phone";

export const ContactPhone: React.FC<IComponentProps> = props => {

    const checkIfSpecialPhone = useCallback(() => {
        const data = props.data || {};
        const siren: SirenEntityBuilder = EntityBuilder.of(data);
        const number: SirenEntityBuilder = siren.getChild("number");
        return !!number.getProperty("type");
    }, [props]);


    if (checkIfSpecialPhone()) {
        return <SpecialPhone { ...props }/>;
    }
    return <ContactLinks { ...props } />;
};

export default webui.connectComponent()(ContactPhone);