import React from "react";
import webui, { BaseComponent } from "@tinqin/tinqin-web-ui";
const extractComponentProps = webui.getUtils().extractComponentProps;

//Container rendering children with separator between them
class SeparatorContainer extends BaseComponent {
    render() {
        if (this.props.config.bindTo) {
            const data = this.props.data ? this.props.data.properties || {} : null;
            if (!data) {
                return this.getEmptyContent();
            }
        }
        const props = extractComponentProps(this.props, {
            additionalProps: ["extraClasses"],
            propsDefaults: {
                extraClasses: ""
            }
        });
        const children = this.resolveChildren();
        const separatedChildren = [];
        children.forEach((child, index) => {
            if (index !== 0) {
                separatedChildren.push(
                    <div
                        key={`separator-${index}`}
                        className={`tq-line-separator ${props.extraClasses}`}
                    />
                );
            }
            separatedChildren.push(child);
        });
        return separatedChildren;
    }
}

export default webui.connectComponent()(SeparatorContainer);
