import React, { useCallback, useMemo } from "react";
import webui from "@tinqin/tinqin-web-ui";
import { IComponentProps, SirenEntityBuilder } from "../../utils/commonTypes";
import EntityBuilder from "@tinqin/tinqin-web-ui/src/utils/EntityBuilder";
import COLORS from "Assets/colors";
import { xssFilter } from "../../utils/xssFilter";

const extractDataProps = (data: IComponentProps["data"] = {}) => {
    const siren: SirenEntityBuilder = EntityBuilder.of(data);

    const buttonTitle: string = siren.getChild("buttonTitle").getValue() || siren.getChild("number").getValue();
    const description: string = siren.getChild("description").getValue();
    const color: string = siren.getChild("color").getValue() || siren.getChild("phoneColor").getValue();
    return {
        description,
        buttonTitle,
        color
    };
};

export const ContactLinks: React.FC<IComponentProps> = props => {
    const onClickHandler = useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.preventDefault();
            webui.getUtils().dispatchSirenActions(props, ["default", "click"]);
        },
        [props]
    );
    
    const dataProps = extractDataProps(props.data);
    const descriptionInnerHTML: string = useMemo(() => xssFilter.process(dataProps.description), [dataProps.description]);

    if (!props.data) {
        return webui.getUtils().getEmptyContent();
    }


    const textColorStyle: React.CSSProperties = dataProps.color ? { color: COLORS[dataProps.color] } : {};
    const borderColorStyle: React.CSSProperties = dataProps.color ? { borderColor: COLORS[dataProps.color] } : {};
    const clickable: string = dataProps.buttonTitle ? " tq-clickable" : "";
    return (
        <div className={"un-flex-phone-wrapper" + clickable} style={borderColorStyle}>
            {dataProps.buttonTitle && (
                <div className="tq-title" onClick={onClickHandler}>
                    <span className="tq-text-node">{dataProps.buttonTitle}</span>
                </div>
            )}
            {descriptionInnerHTML && (
                <div
                    className="colorInherited"
                    style={textColorStyle}
                    dangerouslySetInnerHTML={{
                        __html: descriptionInnerHTML
                    }}
                    data-hyperlinks-container={true}
                />
            )}
        </div>
    );
};

export default webui.connectComponent()(ContactLinks);