import webui from "@tinqin/tinqin-web-ui";
const { addEntity } = webui.getActionByName("utils");

export const PRESET_CONFIG = "PRESET_CONFIG";

const OAV_DATA = "oav";
export const QUOTE_PROCESS_ID = "quoteId";
export const HOST_TIMEOUT = "hostTimeout";
const VALIDATE_ID = "validate";
const PARENT_CONTEXT = "gp";

function presetConfig(path, value, options) {
    return {
        type: PRESET_CONFIG,
        payload: {
            path: path,
            toSet: value,
            ...options
        }
    };
}
export function triggerPresetConfig(path, value, options = {}) {
    return dispatch => {
        return dispatch(presetConfig(path, value, options));
    };
}
const requestQuoteIdValidation = () => {
    const quoteId = localStorage.getItem(QUOTE_PROCESS_ID);
    if (quoteId) {
        localStorage.setItem(VALIDATE_ID, quoteId);
    }
};

const getQuoteData = () => dispatch => {
    //cache will be null if the first tab is closed right after opening the second.
    const cache = localStorage.getItem(OAV_DATA);
    if (cache) {
        const cacheData = JSON.parse(cache);
        cacheData.class.push("skipDataSanitize");
        const triggerSet = webui.getActionByName("component.triggerSet");
        const toSet = {
            class: ["oavContainer"],
            context: "oav",
            entities: [cacheData]
        };
        const configToSet = {
            id: "oav-container",
            cached: true
        };
        dispatch(triggerSet("oav-container", toSet));
        dispatch(triggerPresetConfig(["oav-container"], configToSet));
        return true;
    } else if (cache === null) {
        return "NO_DATA";
    }
    return false;
};
export const quoteActiveOnAnotherTabRetrieveData = (
    sirenAction,
    widgetId,
    currentPath
) => dispatch => {
    requestQuoteIdValidation();
    const interval = setInterval(() => {
        const done = dispatch(getQuoteData());
        if (done) {
            clearInterval(interval);
            clearQuoteProcess();
        }
    }, 200);
};

// export const clearQuoteProcess = (sirenAction, widgetId, currentPath) => dispatch => {
export const clearQuoteProcess = () => {
    console.log("Clearing localStorage after retrieve");
    const startedProcess = localStorage.getItem(QUOTE_PROCESS_ID);
    const oavData = localStorage.getItem(OAV_DATA);
    if (startedProcess || oavData) {
        localStorage.removeItem(QUOTE_PROCESS_ID);
        localStorage.removeItem(HOST_TIMEOUT);
        localStorage.removeItem(VALIDATE_ID);
        localStorage.removeItem(OAV_DATA);
    }
};

export const checkDashboard = (sirenAction, widgetId, currentPath) => (dispatch, getState) => {
    let currentDashboard = getState().getIn(["routes", "location", "state", "dashboard"]);
    let quoteQuestionnaire = getState().getIn(["data", "quote-questionnaire"]);
    if (currentDashboard) {
        currentDashboard = currentDashboard.toJS();
    }
    if (quoteQuestionnaire) {
        quoteQuestionnaire = quoteQuestionnaire.toJS();
    }
    const excludedDashboards = sirenAction.properties?.exclude || [];
    //the process is active between "quote-questionnaire" and "quote-contact-form"
    if (excludedDashboards.includes(currentDashboard.id) && quoteQuestionnaire) {
        throw new Error("<silent>:Questionnaire renders");
    }
};

export const getPopInData = (sirenAction, widgetId, currentPath) => async dispatch => {
    const target = sirenAction.targets[0];
    const requestOptions = {
        method: "POST",
        endpoint: sirenAction.href,
        payload: {
            parentContext: PARENT_CONTEXT
        },
        headers: {
            "Content-Type": "application/json"
        }
    };
    const request = webui.getActionByName("request.request");
    const popinData = await dispatch(request(requestOptions));
    if (popinData) {
        dispatch(addEntity(target, popinData));
    }
};
