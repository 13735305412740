import webui from "@tinqin/tinqin-web-ui";
import ResponsiveHOC from "./../../../HOC/components/responsive/index";

//container for displaying different components based on data.properties.cardType instead of config.type
//to consider this component for default component when there is no config.type but there is config.bindTo
const StoryCardTypeContainer = props => {
    const getChildType = type => {
        switch (type) {
            case "video": {
                if (["L", "XL"].includes(props.size)) {
                    return {
                        type: "container",
                        extraClasses: `un-banner-story-wrapper un-banner-story-wrapper--video`,
                        children: [
                            {
                                type: "container",
                                extraClasses: "tq-clickable un-banner-story un-banner-story--video",
                                children: [
                                    {
                                        type: "textOutput",
                                        tag: "div",
                                        extraClasses: "un-banner-story--video-badge",
                                        bindTo: "{parent}.badge"
                                    },
                                    {
                                        type: "videoPlayer",
                                        bindTo: "{parent}"
                                    }
                                ]
                            }
                        ]
                    };
                }
                return {
                    type: "videoCard",
                    extraClasses: "un-banner-story-wrapper un-banner-story-wrapper--video",
                    actions: [
                        {
                            actionType: "buildVideoModal",
                            targets: ["home.videoModal"],
                            actions: [
                                {
                                    actionType: "openModal",
                                    targets: ["home.videoModal"]
                                }
                            ]
                        }
                    ]
                };
            }
            case "story":
            default: {
                return {
                    type: "storyCardWrapper",
                    children: [
                        {
                            type: "storyCard",
                            bindTo: "{parent}"
                        }
                    ]
                };
            }
        }
    };

    if (!props.data) {
        return webui.getUtils().getEmptyContent();
    }
    const dataProps = webui.getUtils().extractComponentProps(props, {
        additionalProps: ["cardType", "extraClasses"],
        propsDefaults: {
            cardType: "story",
            extraClasses: ""
        }
    });

    const { type, id, extraClasses, actions, children } = getChildType(dataProps.cardType);

    const childConfig = Object.assign({}, props.config, {
        type,
        extraClasses,
        bindTo: props.computedBindTo
    });
    if (id) {
        childConfig.id = id;
    }
    const configActions = props.config.actions || [];
    if (actions) {
        childConfig.actions = configActions.concat(actions);
    } else {
        childConfig.actions = configActions;
    }
    if (children) {
        childConfig.children = children;
    }
    const options = {
        parentBindTo: props.parentBindTo
    };
    return webui.getUtils().resolveChild(props, childConfig, 0, options);
};

export default webui.connectComponent()(ResponsiveHOC(StoryCardTypeContainer));
