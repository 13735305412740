import { getFieldValue } from "./utils";

const OPENED_BURGER_BODY_CLASS = "un-burger-open";

export default (sirenAction, widgetId, currentPath) => {
    //Items in targetDependencies will be strings representing the classes that identify the element(s) to impact.
    const target = sirenAction.target || "";
    const fields = sirenAction.fields || [];
    const hideWindowScrollBar =
        getFieldValue(fields, "hideWindowScrollBar") !== undefined
            ? getFieldValue(fields, "hideWindowScrollBar")
            : true;
    const bodyClass = getFieldValue(fields, "bodyClass") || OPENED_BURGER_BODY_CLASS;
    const selectBy = getFieldValue(fields, "selectBy") || "id";
    return (dispatch, getState) => {
        let element;
        const body = document.querySelector("body");
        if (selectBy === "class") {
            const elements = document.getElementsByClassName(target);
            element = elements.item(0);
        } else {
            element = document.getElementById(target);
        }
        element.style.display = element.style.display === "none" ? "" : "none";
        if (hideWindowScrollBar) {
            if (typeof body.classList.value !== "undefined") {
                body.classList.contains(bodyClass)
                    ? (body.classList.value = body.classList.value.replace(bodyClass, ""))
                    : body.classList.add(bodyClass);
            }
        }
    };
};
