import { useEffect, useRef } from "react";

type SomeFunction = (...args: any[]) => void;
type Timeout = ReturnType<typeof setTimeout>;
/**
 * 
 * @param func The original, non debounced function
 * @param wait The delay (in ms) for the function to return
 * @param immediate Executes the function at first and than waits until the timer ends
 * @returns The debounced function, which will run only if the debounced function has not been called in the last (delay) ms
 */
const useDebounce = (func: SomeFunction, wait: number = 500, immediate?: boolean) => {
    const timer = useRef<Timeout>();
    // const mounted = useRef<boolean>(false);
    useEffect(() => {
        return () => {
            if (!timer.current) return;
            clearTimeout(timer.current);
        };
    }, []);
    const debouncedFunction = ((...args) => {
        const later = () => {
            timer.current = null;
            if (!immediate) func(...args);
        };

        const callNow = immediate && !timer.current;
        clearTimeout(timer.current);
        timer.current = setTimeout(later, wait);
        if (callNow) func(...args);
    });
    return debouncedFunction;
};

export default useDebounce;
