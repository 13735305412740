import AnalyticsContainer from "./container/AnalyticsContainer";
import ArticleAidBox from "./article-aid-box/ArticleAidBox";
import ArticleBoxLogo from "../../article/components/ArticleBoxLogo";
import BackgroundImageContainer from "./container/BackgroundImageContainer";
import BackgroundContainer from "./container/BackgroundContainer";
import BannerStoryCarouselItem from "./banner-story/BannerStoryCarouselItem";
import Button from "./button-tmp";
import ClickContainer from "./container/clickContainer";
import ContactLinks from "./contact-links/ContactLinks";
import ContactMessage from "./contact-message/ContactMessage";
import FlexContainer from "./flex-container";
import FlexItem from "./flex-container/FlexItem";
import FormConsent from "./form-consent/FormConsent";
import FullScreenDropdown from "./full-screen-dropdown/FullScreenDropdown";
import HasValueContainer from "./container/HasValueContainer";
import HeaderContainer from "./container/HeaderContainer";
import HorizontalScrollingContainer from "./container/HorizontalScrollingContainer";
import HyperLinkButton from "./hyperlinks/HyperLinkButton";
import HyperlinksContainer from "./container/HyperlinksContainer";
import HyperLinkWrapper from "./hyperlinks/HyperLinkWrapper";
import Image from "./image/Image";
import InputPhone from "./input-phone/InputPhone";
import LazyContainer from "./container/LazyContainer";
import Links from "./links/index";
import LoadingContainer from "./container/LoadingContainer";
import MaintenancePopUp from "./modal/MaintanencePopUp";
import NestedLinksContainer from "./links/NestedLinksContainer";
import NotBoundContainer from "./container/NotBoundContainer";
import Portal from "./portal/index";
import Recaptcha from "./google-recaptcha/Recaptcha";
import RelatedArticlesContainer from "./container/RelatedArticlesContainer";
import ResponsiveContainer from "../../HOC/components/responsive/responsiveContainer";
import RichText from "./richText/RichText";
import RichTitle from "./richTitle/RichTitle";
import RubricsCarousel from "../../slides/components/RubricsCarousel";
import SeparatorContainer from "./container/SeparatorContainer";
import SimpleOutputDate from "./output-publish-date/OutputPublishDate";
import SocialLinks from "./links/SocialLinks";
import SolutionBox from "./solution-box/SolutionBox";
import StepBulletSlider from "./step-bullet-slider";
import StickyFooter from "./sticky-footer/StickyFooter";
import StoryCard from "./story-card/Card";
import StoryCardWrapper from "./story-card/Wrapper";
import Trigger from "./trigger/Trigger";
import TypeContainer from "./container/TypeContainer";
import UiStateContainer from "./container/UiStateContainer";
import UneoDropdown from "./dropdown/UneoDropdown";
import VideoPlayer from "./video-player/VideoPlayer";
import ResponsiveVideoComponent from "./video-player/ResponsiveVideoComponent";
import StoryCardTypeContainer from "./container/StoryCardTypeContainer";
import VideoCard from "./video-card";
import BannerSuperStory from "./banner-super-story/BannerSuperStory";
import GridLayout from "./../components/grid";
import ImageTitle from "./image-title/ImageTitle";
import TextOutput from "./text-output/TextOutput";
import Logo from "./logo/Logo";
import Modal from "@tinqin/tinqin-web-ui/src/components/modal/Modal";
import ExpandableTextOutput from "./expandable-text-output/ExpandableTextOutput";
import MediaModal from "./mediaModal/mediaModal";
import Breadcrumb from "./breadcrumb/Breadcrumb";
import SpecialPhone from "./special-phone";
import QuoteNotificationContainer from "./container/QuoteNotificationContainer";
import ContactPhone from "./contact-phone/ContactPhone";
import BodyClass from "./body-class/BodyClass";

const components = {
    analyticsContainer: AnalyticsContainer,
    articleAidBox: ArticleAidBox,
    articleBoxLogo: ArticleBoxLogo,
    backgroundImageContainer: BackgroundImageContainer,
    backgroundContainer: BackgroundContainer,
    bannerStoryCarouselItem: BannerStoryCarouselItem,
    button: Button,
    clickContainer: ClickContainer,
    contactLinks: ContactLinks,
    contactMessage: ContactMessage,
    flexContainer: FlexContainer,
    flexItem: FlexItem,
    formConsent: FormConsent,
    fullScreenDropdown: FullScreenDropdown,
    recaptcha: Recaptcha,
    hasValueContainer: HasValueContainer,
    headerContainer: HeaderContainer,
    horizontalScrollingContainer: HorizontalScrollingContainer,
    hyperLinkButton: HyperLinkButton,
    hyperlinksContainer: HyperlinksContainer,
    hyperLinkWrapper: HyperLinkWrapper,
    image: Image,
    inputPhone: InputPhone,
    lazyContainer: LazyContainer,
    links: Links,
    loadingContainer: LoadingContainer,
    maintenancePopUp: MaintenancePopUp,
    nestedLinksContainer: NestedLinksContainer,
    notBoundContainer: NotBoundContainer,
    portal: Portal,
    relatedArticlesContainer: RelatedArticlesContainer,
    responsiveContainer: ResponsiveContainer,
    richText: RichText,
    richTitle: RichTitle,
    rubricsCarousel: RubricsCarousel,
    separatorContainer: SeparatorContainer,
    simpleOutputDate: SimpleOutputDate,
    socialLinks: SocialLinks,
    solutionBox: SolutionBox,
    stepBulletSlider: StepBulletSlider,
    stickyFooter: StickyFooter,
    storyCard: StoryCard,
    storyCardWrapper: StoryCardWrapper,
    trigger: Trigger,
    typeContainer: TypeContainer,
    uiStateContainer: UiStateContainer,
    uneoDropdown: UneoDropdown,
    videoPlayer: VideoPlayer,
    storyCardTypeContainer: StoryCardTypeContainer,
    videoCard: VideoCard,
    bannerSuperStory: BannerSuperStory,
    grid: GridLayout,
    textOutput: TextOutput,
    responsiveVideoComponent: ResponsiveVideoComponent,
    imageTitle: ImageTitle,
    logo: Logo,
    modal: Modal,
    expandableTextOutput: ExpandableTextOutput,
    mediaModal: MediaModal,
    uneoBreadcrumb: Breadcrumb,
    specialPhone: SpecialPhone,
    quoteNotificationContainer: QuoteNotificationContainer,
    contactPhone: ContactPhone,
    bodyClass: BodyClass
};

export default components;
