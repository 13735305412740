import React from "react";
import webui, { BaseComponent } from "@tinqin/tinqin-web-ui";
import { triggerMatomoEvents } from "../../actions/front-end/triggerMatomoEvents";
import EntityBuilder from "@tinqin/tinqin-web-ui/src/utils/EntityBuilder";

class MatomoEventsContainer extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle:
                EntityBuilder.of(this.props.analyticsData, { inplace: true })
                    .getChild("meta")
                    .getChild("title")
                    .getValue() || "Page name not defined in Database"
        };
    }

    componentDidMount() {
        if (this.props.analyticsData) {
            triggerMatomoEvents(this.state.pageTitle);
        }
    }

    render() {
        return <>{this.props.children}</>;
    }
}

export default webui.connectComponent()(MatomoEventsContainer);
