import components from "./components";
import componentsConfig from "./components/config";
import actions, { sirenActionsMapping } from "./actions";
import reducers from "./reducers";
import templates from "./templates";
//styles
//
import "./styles/base.less";
import "./styles/flex-container.less";
import "./styles/main.less";
import "./styles/oav.less";

//images
import "../../../assets/images";

export default {
    components,
    componentsConfig,
    actions,
    sirenActionsMapping,
    reducers,
    templates
};
