import { getBrowserData } from "Common/components/modal/supportedBrowsersUpdateNotification";
/**
 * This action looks for redirectUrl parameter and if the parameter is empty it puts the current location href;
 * This was necessary for the SSO redirect when you click on login button from the header and the SSO BE has no way to get the current url
 */

export default (sirenAction, widgetId, currentPath) => dispatch => {
    if (sirenAction) {
        const redirectUrl =
            sirenAction?.parameters?.redirectUrl === ""
                ? window.location.href
                : sirenAction?.parameters?.redirectUrl;
        const target = sirenAction.properties?.target || "_blank";
        let href = sirenAction.href;
        if (redirectUrl) {
            href += `?redirectUrl=${redirectUrl}`;
        }
        //Additional check for safari is added because safari do not support window.open() in async calls and setTimeout is workaround
        const browser = getBrowserData();
        if (browser.browserName === "Safari") {
            setTimeout(() => {
                window.open(href, target);
            });
        } else {
            window.open(href, target);
        }
    }
};
