import React from "react";
import webui from "@tinqin/tinqin-web-ui";
import { Helmet } from "react-helmet";
import EntityBuilder from "@tinqin/tinqin-web-ui/src/utils/EntityBuilder";
import { convertRelativePaths } from "../../utils/relativePaths";

const TWITTER_TYPE = "summary";
const OPEN_GRAPH_TYPE = "website"; //or "article"

function MetaTags(props) {
    const data = props.data ? props.data.properties || {} : null;
    if (!data) {
        return "";
    }
    const dataEntity = EntityBuilder.of(props.data, { inplace: true });
    const title = dataEntity.getChild("meta.title").getValue() || "";
    const description = dataEntity.getChild("meta.description").getValue() || "";
    let imageSrc = dataEntity.getChild("meta.image").getProperty("src") || "";
    if (imageSrc) {
        imageSrc = convertRelativePaths(imageSrc);
    }
    const twitterCardType = dataEntity.getChild("meta.twitter.type").getValue() || TWITTER_TYPE;
    const twitterTitle = dataEntity.getChild("meta.twitter.title").getValue() || title;
    const twitterDescription =
        dataEntity.getChild("meta.twitter.description").getValue() || description;
    let twitterImageSrc = dataEntity.getChild("meta.twitter.image").getProperty("src");
    if (twitterImageSrc) {
        twitterImageSrc = convertRelativePaths(twitterImageSrc);
    } else {
        twitterImageSrc = imageSrc;
    }
    const ogType = dataEntity.getChild("meta.facebook.type").getValue() || OPEN_GRAPH_TYPE;
    const ogTitle = dataEntity.getChild("meta.facebook.title").getValue() || title;
    const ogDescription =
        dataEntity.getChild("meta.facebook.description").getValue() || description;
    let ogImageSrc = dataEntity.getChild("meta.facebook.image").getProperty("src");
    if (ogImageSrc) {
        ogImageSrc = convertRelativePaths(ogImageSrc);
    } else {
        ogImageSrc = imageSrc;
    }
    const ogURL = window.location.href;
    const seo = dataEntity.getChild("meta.seo");
    const canonicalUrl = seo.getProperty("canonical") || null;
    const noIndex = seo.getProperty("noIndex") || null;
    return (
        <Helmet>
            <title>{title}</title>
            {noIndex && <meta name="robots" content="noindex,follow" />}
            <meta name="description" content={description} />
            <meta name="twitter:card" content={twitterCardType} />
            <meta name="twitter:title" content={twitterTitle} />
            <meta name="twitter:description" content={twitterDescription} />
            <meta name="twitter:image" content={twitterImageSrc}></meta>
            <meta property="og:type" content={ogType} />
            <meta property="og:title" content={ogTitle} />
            <meta property="og:description" content={ogDescription} />
            <meta property="og:image" content={ogImageSrc} />
            <meta property="og:url" content={ogURL} />
            {window.globalSettings.cpApplicationVersion && (
                <meta
                    name="application-version"
                    content={window.globalSettings.cpApplicationVersion}
                />
            )}
            {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        </Helmet>
    );
}

export default webui.connectComponent()(MetaTags);
