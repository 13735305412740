"use client";
import { useEffect, useState } from "react";

export default function LinkedInInsight() {
    const feature = window.globalSettings.featureEndpoint + "/linkedin-insight";
    const [data,setData] = useState({});
    if (!Object.keys(data).length){
        fetch(feature, {
            method: "GET",
            headers: {
                Accept: "application/json"
            }
        })
            .then(res => res.json())
            .then(data => {
                setData(data);
            });

    }

    const settings = data["settings"] || {};
    const enabled = data["enabled"];
    const id = settings["id"];
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [consentGiven, setConsentGiven] = useState(false);

    useEffect(() => {
        // Set the initial consent state
        const initialConsent = !!(window as any)?.tarteaucitronConsents?.linkedininsighttag
            ?.accepted;
        if (initialConsent !== consentGiven) {
            setConsentGiven(initialConsent);
        }
        // Define the event listener callback function
        function handleConsentChange(event) {
            if (
                event.detail.linkedininsighttag &&
                event.detail.linkedininsighttag.accepted !== consentGiven
            ) {
                setConsentGiven(event.detail.linkedininsighttag.accepted);
            }
        }
        // Add the event listener when the component mounts
        document.addEventListener("tarteaucitronConsentChange", handleConsentChange);
        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener("tarteaucitronConsentChange", handleConsentChange);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!scriptLoaded && !!document.head.querySelector("script#linkedin-insight")) {
            setScriptLoaded(true);
            return;
        }

        if (enabled && id && !scriptLoaded && consentGiven) {
            const headElement = document.getElementsByTagName("head")[0];

            const firstScript = document.createElement("script");
            firstScript.type = "text/javascript";
            firstScript.id = "linkedin-insight";
            firstScript.innerHTML = `
_linkedin_partner_id = ${id};
window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
window._linkedin_data_partner_ids. push(_linkedin_partner_id);
            `;
            headElement.appendChild(firstScript);

            const secondScript = document.createElement("script");
            secondScript.type = "text/javascript";
            secondScript.innerHTML = `
(function(l) { if (!l){window.lintrk = function(a,b){
window.lintrk.q.push([a,b])
}; 
window.lintrk.q=[]} var s = document. getElementsByTagName("script")[0];
var b = document.createElement("script"); b.type = "text/javascript";
b.async = true; b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
s.parentNode.insertBefore(b, s);})(window.lintrk);
`;
            headElement.appendChild(secondScript);

            const noScript = document.createElement("noscript");
            noScript.innerHTML = `
<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=${id}&fmt=gif" />
            `;
            headElement.appendChild(noScript);

            setScriptLoaded(true);
        }
    }, [enabled, id, scriptLoaded, consentGiven]);

    return null;
}
