import { getDashboardConfig } from "./dashboards";
import { getMixinConfig } from "./mixins";
import { getWidgetConfig } from "./widgets";

const load = async ({ id = "", type = "widget", defaultRequestOptions = {} } = {}) => {
    // console.log(`Requestion static config for ${type} with id: ${id}.`);
    if (type === "dashboard") {
        return getDashboardConfig({ id, defaultRequestOptions });
    }
    if (type === "mixin") {
        return getMixinConfig({ id, defaultRequestOptions });
    }
    return getWidgetConfig({ id, defaultRequestOptions });
};

export default { load };
