export function smoothScrollTo(sirenAction, widgetId, currentPath) {
    const target = sirenAction.targets || [];
    let additionalOffset = 50;
    if (sirenAction.properties?.calcHeader) {
        const header = document.getElementsByClassName("tq-header")[0];
        if (header) {
            additionalOffset += header.offsetHeight;
        }
    }
    return dispatch => {
        const elementToScrollTo = document.querySelector(`[data-unique-id="${target}"]`);
        if (elementToScrollTo) {
            const elTop = elementToScrollTo.getBoundingClientRect().top;
            const scrollTop =
                elTop +
                (window.pageYOffset || document.documentElement.scrollTop) -
                additionalOffset;
            window.scrollTo({
                top: scrollTop,
                behavior: "smooth"
            });
        }
    };
}
export const scrollToTop = (sirenAction, widgetId, currentPath) => {
    const target = sirenAction.targets || [];
    return dispatch => {
        const elementToScroll = document.querySelector(target[0]);
        if (elementToScroll) {
            elementToScroll.scrollTop = 0;
        }
    };
};
