import React from "react";
import webui, { BaseComponent } from "@tinqin/tinqin-web-ui";

const extractComponentProps = webui.getUtils().extractComponentProps;

class StoryWrapper extends BaseComponent {
    onClick = () => {
        this.dispatchSirenActions();
    };

    buildClassName() {
        const props = extractComponentProps(this.props, {
            additionalProps: ["color", "extraClasses"],
            propsDefaults: { extraClasses: "" }
        });

        let colorClass = props.color ? `un-banner-${props.color}` : "";
        const storyData = this.props.data.properties || { type: "" };
        if (storyData.type === "corporate") {
            props.extraClasses += " un-banner-story-wrapper-corp";
            colorClass = "";
        }

        return `un-banner-story-wrapper ${colorClass} ${props.extraClasses}`;
    }

    render() {
        if (!this.props.data) {
            return null;
        }
        const children = this.resolveChildren();
        return (
            <div className={this.buildClassName()} onClick={this.onClick}>
                {children}
            </div>
        );
    }
}

export default webui.connectComponent()(StoryWrapper);
