import RemoveCssClass from "./front-end/remove-css-class";
import ToggleCssClass from "./front-end/toggle-css-class";
import DisplayNone from "./front-end/display-none";
import ToggleDisplay from "./front-end/toggleDisplay";
import ThemeToggleContactForm from "./front-end/themeToggleContactForm";
import StickyFooterClose from "./front-end/stickyFooterClose";
import { increaseFontSize, decreaseFontSize } from "./front-end/fontControl";
import { initialScrollToNextItem, scrollToSelectedFolderItem } from "./front-end/initialScroll";
import { search as findCloseContacts } from "./google-map/search";
import {
    updateContactMessageOnSelection,
    updatePhoneOnCountrySelection,
    onContactFormTypeSelection,
    toggleMemberCheckbox,
    handleBirthDate
} from "./front-end/contactForm";
import {
    removeElementValue,
    onItemExpandFAQ,
    onSearchFAQ,
    scrollToResults
} from "./front-end/faqPage";
import { isCorporate } from "./front-end/isCorporate";
import { triggerRecaptcha, executeRecaptcha } from "../components/google-recaptcha/Recaptcha";
import getCurrentDashboardId from "./front-end/getCurrentDashboardId";
import { onSearch, onSearchFilterChange } from "./front-end/search";
import {
    buildPageAddress,
    buildBootDashboardAction,
    compareDashboardAddresses
} from "./page/PageAddress";
import { onLocationUpdated } from "./page/onLocationUpdated";
import { changeCalendarItems } from "./front-end/manageCalendarEvents";
import { setGlobalProperty, getGlobalProperty } from "./front-end/manageGlobalProperties";
import { changeVisibilityState } from "./front-end/changeVisibilityState";
import { openCookiesPanel } from "./front-end/openCookiesPanel";
import { handleHyperlink } from "./page/Hyperlinks";
import { smoothScrollTo, scrollToTop } from "./front-end/smoothScrollTo";
import { contactFormSmoothScrollTo } from "./front-end/contactFormSmoothScrollTo";
import { toggleSection } from "./front-end/toggleSection";
import { buildVideoModal } from "./front-end/buildVideoModal";
import {
    triggerMatomoEvent,
    trackCatalogCategories,
    trackApplyButton,
    trackVideo,
    trackSocialLinksItem,
    trackProjectsFilter,
    trackMySpaceButtonMatomoEvent,
    trackOfferDependingOnUserType,
    trackContactFormSent,
    trackPageView,
    trackSearchSuggestions
} from "./front-end/triggerMatomoEvents";
import { trackSiteSearch } from "./front-end/triggerMatomoSiteSearch";
import { chatBotCommands } from "./front-end/chatBotCommands";
import { toggleClassMainMenuItem } from "./front-end/toggleClassMainMenuItem";
import openTargetWithPopulatedUrlParameter from "./front-end/openTargetWithPopulatedUrlParameter";
import {
    onDashboardLoaded,
    restoreScrolledPositionForDashboard
} from "./front-end/manageScrollRestoration";
import appendChildTo from "./front-end/appendChildTo";
import {
    quoteActiveOnAnotherTabRetrieveData,
    clearQuoteProcess,
    checkDashboard,
    getPopInData
} from "./front-end/retrieveQuoteData";
import focusElement from "./front-end/focusElement";
import { playRadio } from "./front-end/radioActions";

const actions = {
    //Note: The place for your custom/project actions.
    uneo: {
        removeCssClass: RemoveCssClass,
        toggleCssClass: ToggleCssClass,
        displayNone: DisplayNone,
        increaseArticleFontSize: increaseFontSize,
        decreaseArticleFontSize: decreaseFontSize,
        toggleDisplay: ToggleDisplay,
        themeToggleContactForm: ThemeToggleContactForm,
        stickyFooterClose: StickyFooterClose,
        initialScrollToNextItem,
        scrollToSelectedFolderItem,
        findCloseContacts,
        updateContactMessageOnSelection,
        updatePhoneOnCountrySelection,
        onContactFormTypeSelection,
        removeElementValue,
        isCorporate,
        triggerRecaptcha,
        executeRecaptcha,
        toggleMemberCheckbox,
        handleBirthDate,
        onItemExpandFAQ,
        onSearchFAQ,
        scrollToResults,
        onSearch,
        onSearchFilterChange,
        getCurrentDashboardId,
        changeCalendarItems,
        setGlobalProperty,
        getGlobalProperty,
        changeVisibilityState,
        openCookiesPanel,
        handleHyperlink,
        smoothScrollTo,
        scrollToTop,
        contactFormSmoothScrollTo,
        toggleSection,
        buildVideoModal,
        triggerMatomoEvent,
        trackCatalogCategories,
        trackApplyButton,
        trackVideo,
        trackSocialLinksItem,
        trackProjectsFilter,
        trackMySpaceButtonMatomoEvent,
        trackOfferDependingOnUserType,
        trackContactFormSent,
        trackPageView,
        trackSearchSuggestions,
        chatBotCommands,
        toggleClassMainMenuItem,
        openTargetWithPopulatedUrlParameter,
        appendChildTo,
        quoteActiveOnAnotherTabRetrieveData,
        clearQuoteProcess,
        checkDashboard,
        getPopInData,
        trackSiteSearch,
        focusElement,
        playRadio
    },
    application: {
        buildBootDashboardAction,
        buildPageAddress,
        compareDashboardAddresses
    },
    dashboard: {
        onDashboardLoaded: onDashboardLoaded,
        onLocationUpdated: onLocationUpdated,
        onWidgetsLoaded: restoreScrolledPositionForDashboard
    }
};

export default actions;
export const sirenActionsMapping = {
    removeCssClass: "uneo.removeCssClass",
    toggleCssClass: "uneo.toggleCssClass",
    displayNone: "uneo.displayNone",
    increaseArticleFontSize: "uneo.increaseArticleFontSize",
    decreaseArticleFontSize: "uneo.decreaseArticleFontSize",
    toggleDisplay: "uneo.toggleDisplay",
    themeToggleContactForm: "uneo.themeToggleContactForm",
    stickyFooterClose: "uneo.stickyFooterClose",
    initialScrollToNextItem: "uneo.initialScrollToNextItem",
    scrollToSelectedFolderItem: "uneo.scrollToSelectedFolderItem",
    findCloseContacts: "uneo.findCloseContacts",
    updateContactMessageOnSelection: "uneo.updateContactMessageOnSelection",
    updatePhoneOnCountrySelection: "uneo.updatePhoneOnCountrySelection",
    onContactFormTypeSelection: "uneo.onContactFormTypeSelection",
    removeElementValue: "uneo.removeElementValue",
    triggerRecaptcha: "uneo.triggerRecaptcha",
    executeRecaptcha: "uneo.executeRecaptcha",
    toggleMemberCheckbox: "uneo.toggleMemberCheckbox",
    handleBirthDate: "uneo.handleBirthDate",
    onItemExpandFAQ: "uneo.onItemExpandFAQ",
    getCurrentDashboardId: "uneo.getCurrentDashboardId",
    onSearchFAQ: "uneo.onSearchFAQ",
    scrollToResults: "uneo.scrollToResults",
    onSearch: "uneo.onSearch",
    onSearchFilterChange: "uneo.onSearchFilterChange",
    changeCalendarItems: "uneo.changeCalendarItems",
    setGlobalProperty: "uneo.setGlobalProperty",
    getGlobalProperty: "uneo.getGlobalProperty",
    changeVisibilityState: "uneo.changeVisibilityState",
    openCookiesPanel: "uneo.openCookiesPanel",
    handleHyperlink: "uneo.handleHyperlink",
    smoothScrollTo: "uneo.smoothScrollTo",
    scrollToTop: "uneo.scrollToTop",
    contactFormSmoothScrollTo: "uneo.contactFormSmoothScrollTo",
    toggleSection: "uneo.toggleSection",
    buildVideoModal: "uneo.buildVideoModal",
    triggerMatomoEvent: "uneo.triggerMatomoEvent",
    trackCatalogCategories: "uneo.trackCatalogCategories",
    trackApplyButton: "uneo.trackApplyButton",
    trackVideo: "uneo.trackVideo",
    trackSocialLinksItem: "uneo.trackSocialLinksItem",
    trackProjectsFilter: "uneo.trackProjectsFilter",
    trackMySpaceButtonMatomoEvent: "uneo.trackMySpaceButtonMatomoEvent",
    trackOfferDependingOnUserType: "uneo.trackOfferDependingOnUserType",
    trackContactFormSent: "uneo.trackContactFormSent",
    trackPageView: "uneo.trackPageView",
    trackSearchSuggestions: "uneo.trackSearchSuggestions",
    chatBotCommands: "uneo.chatBotCommands",
    toggleClassMainMenuItem: "uneo.toggleClassMainMenuItem",
    openTargetWithPopulatedUrlParameter: "uneo.openTargetWithPopulatedUrlParameter",
    appendChildTo: "uneo.appendChildTo",
    quoteActiveOnAnotherTabRetrieveData: "uneo.quoteActiveOnAnotherTabRetrieveData",
    clearQuoteProcess: "uneo.clearQuoteProcess",
    checkDashboard: "uneo.checkDashboard",
    getPopInData: "uneo.getPopInData",
    trackSiteSearch: "uneo.trackSiteSearch",
    focusElement: "uneo.focusElement",
    playRadio: "uneo.playRadio"
};
