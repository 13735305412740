import React, { useEffect, useRef, useCallback, useState } from "react";
import webui from "@tinqin/tinqin-web-ui";
import { IComponentProps } from "../../utils/commonTypes";
import useDebounce from "../../hooks/useDebounce";

//Depending on maxRowsBeforeExpandable we should render simple text ot text with hidden part toggling on button click
export const ExpandableTextOutput: React.FC<IComponentProps> = props => {
    const mountedRef: React.MutableRefObject<any> = useRef();
    const textRef: React.MutableRefObject<any> = useRef();
    const resizeListenerRef: React.MutableRefObject<any> = useRef();
    const [isExpanded, setIsExpanded] = useState(false);
    const [isExpandable, setIsExpandable] = useState(false);

    const {
        tag,
        extraClasses,
        value,
        maxRowsBeforeExpandable,
        expanded
    }: {
        tag: string;
        extraClasses: string;
        value: string;
        maxRowsBeforeExpandable: number;
        expanded: boolean;
    } = webui.getUtils().extractComponentProps(props, {
        additionalProps: ["tag", "extraClasses", "value", "maxRowsBeforeExpandable", "expanded"],
        propsDefaults: {
            tag: "p",
            extraClasses: "",
            maxRowsBeforeExpandable: 3,
            expanded: false
        },
        aliases: { value: "label" }
    });

    const updateHeight = useCallback(() => {
        if (textRef.current) {
            const titleTextStyles = window.getComputedStyle(textRef.current);
            const defaultHeight = parseFloat(titleTextStyles.getPropertyValue("height"));
            const lineHeight = parseFloat(titleTextStyles.getPropertyValue("line-height"));
            const shouldBeExpandable =
                defaultHeight >= lineHeight * (maxRowsBeforeExpandable + 0.5);
            setIsExpandable(shouldBeExpandable);
            //if should be expandable set expanded state
            if (shouldBeExpandable) {
                setIsExpanded(expanded);
            }
        }
    }, [textRef, maxRowsBeforeExpandable, expanded]);

    //Add debounce on resize
    resizeListenerRef.current = useDebounce(() => {
        updateHeight();
    }, 200);

    useEffect(() => {
        // If is expanded by default
        if (!mountedRef.current && expanded) {
            setIsExpanded(true);
            mountedRef.current = true;
        }
        if (textRef.current) {
            resizeListenerRef.current();
            window.addEventListener("resize", resizeListenerRef.current);
            return (): void => {
                window.removeEventListener("resize", resizeListenerRef.current);
            };
        }
    }, [textRef, expanded]);

    const toggleExpandedState = useCallback(() => {
        setIsExpanded(!isExpanded);
    }, [isExpanded]);

    if (!value) {
        return webui.getUtils().getEmptyContent();
    }
    const textComponent = React.createElement(tag, { className: extraClasses, ref: textRef }, [
        value,
        ...webui.getUtils().resolveChildren(props)
    ]);
    const isExpandedClass = isExpanded ? "tq-expanded" : "";
    if (isExpandable) {
        return (
            <>
                <div
                    className={`tq-section tq-expandable tq-section-container ${isExpandedClass}`}
                >
                    <div className="tq-section-header">{textComponent}</div>
                </div>
                <button
                    className="tq-button un-expand-collapse-btn"
                    onClick={toggleExpandedState}
                >
                    <i className="tq-o-icons tq-icon-arrows-right"></i>
                </button>
            </>
        );
    } else {
        return textComponent;
    }
};

export default webui.connectComponent({
    actions: { dispatchSirenActions: "siren.dispatchSirenActions" }
})(ExpandableTextOutput);
