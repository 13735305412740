import React, { useEffect } from "react";
import webui from "@tinqin/tinqin-web-ui";
import { IComponentProps } from "../../utils/commonTypes";

//TODO manage case for multiple BodyClass components with equal "extrClasses"
export const BodyClass: React.FC<IComponentProps> = props => {
    const {
        extraClasses
    }: {
        extraClasses: string;
    } = webui.getUtils().extractComponentProps(props, {
        additionalProps: ["extraClasses"],
        propsDefaults: {
            extraClasses: ""
        }
    });
    useEffect(() => {
        const body = document.querySelector("body");
        if (typeof body.classList.value !== "undefined" && !!extraClasses) {
            if (!body.classList.contains(extraClasses)) {
                body.classList.add(extraClasses);
            }
        }
        return () => {
            if (typeof body.classList.value !== "undefined" && !!extraClasses) {
                body.classList.value = body.classList.value.replace(extraClasses, "");
            }
        };
    }, [extraClasses]);

    return null;
};

export default webui.connectComponent()(BodyClass);
