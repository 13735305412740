import React from "react";
import SupportedBrowsersUpdateNotification from "../components/modal/supportedBrowsersUpdateNotification";

const calculateMainHeight = (dashboardId = "") => {
    const isCorp = dashboardId.includes("corp");
    const windowHeight = window.innerHeight;
    //cp header height = 0px, because its child is fixed
    const headerElement = document.getElementsByClassName("tq-header")[0];
    let headerHeight = isCorp ? 168 : 132;
    if (headerElement) {
        headerHeight = headerElement.offsetHeight;
    }

    const footerElement = document.getElementById("portal");
    let footerHeight = isCorp ? 381 : 365;
    if (footerElement) {
        footerHeight = footerElement.offsetHeight;
    }
    if (dashboardId === "search" || dashboardId === "search-corp") {
        return windowHeight - headerHeight - footerHeight;
    }
    return windowHeight - headerHeight;
};

const getMainMinHeight = (dashboardId = "") => `${calculateMainHeight(dashboardId)}px`;

/* add GP dom elements knowledge that it is an OAV content */
const toggleElementsVisibilityForOAV = dashboardId => {
    const body = document.body;
    const isOAV = dashboardId === "oav" || dashboardId === "diagnostics";
    body.classList[isOAV ? "add" : "remove"]("oav-content");
    body.classList[isOAV ? "remove" : "add"]("cp-content");
};
export default class OneTemplate extends React.Component {
    render() {
        const props = this.props;
        let mainClass = "uneo-cp";

        if (window?.globalSettings?.pfHeader) {
            mainClass += " header-cp-pf";
        } else {
            mainClass += " uneo-cp-default";
        }
        if (props.template === "CorporateTemplate") {
            mainClass = "uneo-corp";
        }
        if (props.template === "HomeGeneralPublic") {
            mainClass = "uneo-gp-home";
        }
        if (props.template === "HomeCommunityPlatform") {
            mainClass += " uneo-cp-home";
        }
        if (props.template === "NoFooterMarginCorp") {
            mainClass = "uneo-corp un-no-mt-footer-corp";
        }
        if (props.template === "NoFooterMargin") {
            mainClass += " un-no-mt-footer";
        }
        const mainMinHeight = getMainMinHeight(this.props.dashboardId);
        toggleElementsVisibilityForOAV(this.props.dashboardId);
        return (
            <div className={mainClass}>
                <header id={"pageHeader"}>{props.regions.header}</header>
                <div id="ribbon" />
                <div id={"tolk"} />
                <main style={{ minHeight: mainMinHeight }}>{props.regions.main}</main>
                <div id="radio" />
                <footer>{props.regions.footer}</footer>
                <div id={"portal"} />
                {props.regions.outside}
                <SupportedBrowsersUpdateNotification />
            </div>
        );
    }
}
