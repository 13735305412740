import { isCorporate } from "../front-end/isCorporate";
import webui from "@tinqin/tinqin-web-ui";

//starting with "/" and not a drupal address (/sites/default/files)
const isInternalNodeRegex = /^(?!\/sites\/default\/files)\/.*/;

const isExternalHyperlink = (address = "") => {
    return !address.startsWith(window.location.origin);
};

const findHyperlinkElement = element => {
    if (!element || !element.attributes || element.attributes["data-hyperlinks-container"]) {
        return false;
    }
    if (element.tagName === "A" && element.attributes.href) {
        return element;
    } else {
        return findHyperlinkElement(element.parentNode);
    }
};

//in case a hyperlink for internal page is clicked
//requests a switchContainer action and dispatches the result
//otherwise open link in new tab
export function handleHyperlink(event, widgetId) {
    return async dispatch => {
        const hyperlinkElement = findHyperlinkElement(event.target);
        if (hyperlinkElement) {
            const href = hyperlinkElement.attributes.href.value;
            if (href === "/cookies-popup") {
                event.stopPropagation();
                event.preventDefault();
                window.tarteaucitron.userInterface.openPanel();
            } else if (isInternalNodeRegex.test(href)) {
                event.stopPropagation();
                event.preventDefault();
                let endpoint = "";
                const isCorporateLink = dispatch(isCorporate(widgetId));

                if (isCorporateLink) {
                    endpoint = "/node/corporate";
                } else {
                    endpoint = "/node/public";
                }
                //https://jira.tinqin.com/browse/UCP-737
                //Regarding relative internal links
                endpoint += `?id=${href}`;
                const requestAction = await dispatch(
                    webui.getActionByName("request.request")({
                        method: "GET",
                        endpoint
                    })
                );
                const dispatchAction = webui.getActionByName("siren.dispatchSirenActions");
                dispatch(dispatchAction([requestAction]));
            } else if (isExternalHyperlink(href)) {
                event.stopPropagation();
                event.preventDefault();
                if (href.trim() !== "") {
                    window.open(href);
                }
            }
            //if not internal node or external link,
            //means it is internal full hyperlink (inside an anchor element)
        }
    };
}
