import React, { useCallback } from "react";
import webui from "@tinqin/tinqin-web-ui";
import EntityBuilder from "@tinqin/tinqin-web-ui/src/utils/EntityBuilder";
import SpecialPhone from "./SpecialPhone";
import { IComponentProps, SirenEntityBuilder } from "../../utils/commonTypes";

const ReduxSpecialPhone: React.FC<IComponentProps> = props => {
    const onClick = useCallback(() => {
        webui.getUtils().dispatchSirenActions(props, ["default", "click"]);
    }, [props]);

    const extractProps = useCallback(() => {
        const data = props.data || {};
        const siren: SirenEntityBuilder = EntityBuilder.of(data);
        const description: string = siren.getChild("description").getValue();
        const number: SirenEntityBuilder = siren.getChild("number");
        const numberValue: string = number.getValue();
        const numberType: string = number.getProperty("type");
        const numberTariff: string = number.getProperty("tariff");
        const phoneProps = {
            description,
            numberValue,
            numberType,
            numberTariff
        };
        return phoneProps;
    }, [props]);

    if (!props.data) {
        return webui.getUtils().getEmptyContent();
    }
    const extractedProps = extractProps();
    return extractedProps.numberValue ? (
        <SpecialPhone {...extractedProps} onClick={onClick} />
    ) : null;
};

export default webui.connectComponent()(ReduxSpecialPhone);
