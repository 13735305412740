import React, { useCallback } from "react";
import webui from "@tinqin/tinqin-web-ui";
import { clearUiStateDeep } from "@tinqin/tinqin-web-ui/src/actions/Component";
import { IComponentProps } from "../../../common/utils/commonTypes";

const MediaModal: React.FC<IComponentProps> = props => {
    const dispatch = webui.getDispatchMethod();

    const closeModal = useCallback((): void => {
        const setUiState = webui.getActionByName("component.setUiState");
        dispatch(setUiState(props.widgetId, props.computedBindTo, { visible: false }));
        dispatch(webui.getActionByName("modal.clearGlobalModalState")());
        dispatch(clearUiStateDeep(props.widgetId, props.computedBindTo));
    }, [props, dispatch]);

    if (!props.data) {
        return webui.getUtils().getEmptyContent();
    }
    const componentProps = webui.getUtils().extractComponentProps(props, {
        additionalProps: ["extraClasses", "bodyClasses"],
        propsDefaults: { extraClasses: "", bodyClasses: "" },
        aliases: { href: "src" }
    });

    if (props.uiState.visible) {
        return (
            <div className={`un-cp-fullscreen-modal ${componentProps.extraClasses}`}>
                <div className="un-cp-modal-close tq-clickable" onClick={closeModal}>
                    <i className="tq-icon-close"></i>
                </div>
                <div className={`${componentProps.bodyClasses}`}>
                    {webui.getUtils().resolveChildren(props)}
                </div>
            </div>
        );
    }
    return null;
};

export default webui.connectComponent({
    uiState: true
})(MediaModal);
