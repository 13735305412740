import React from "react";
import webui, { BaseComponent } from "@tinqin/tinqin-web-ui";

const extractComponentProps = webui.getUtils().extractComponentProps;

class SocialLinks extends BaseComponent {
    onLinkClick = () => {
        this.dispatchSirenActions(["default", "click"]);
    };
    onClick = (childEntity, childActions) => {
        const dispatch = webui.getDispatchMethod();
        const dispatchAction = webui.getActionByName("siren.dispatchSirenActions");
        const data = childEntity.data;
        const actions = [...childActions, ...(data.actions || [])];
        dispatch(dispatchAction([...actions], this.props.widgetId, childEntity.computedBindTo));
        return event => {
            event.preventDefault();
        };
    };

    buildItems() {
        const data = this.props.data || {};
        const children = data.entities || [];
        const props = this.getProps();

        return children.map((child, index) => {
            const childProps = SocialLinks.getChildProps(
                child,
                this.props?.config.childConfig || {}
            );
            const computedBindTo = this.props?.computedBindTo + "." + child.class[0];
            const childActions = childProps.actions || [];
            const target = childProps.target || props.target;
            return (
                <li
                    key={`link-${index}`}
                    onClick={() => this.onClick({ computedBindTo, data: child }, childActions)}
                >
                    <a
                        href={childProps.href}
                        target={target}
                        rel={"noreferrer"}
                        onClick={this.onLinkClick}
                        aria-label={child.class[0]}
                    >
                        {childProps.icon && <i className={childProps.icon} />}
                        {childProps.imgSrc && (
                            <img src={childProps.imgSrc} alt={childProps.imgAlt ?? ""} />
                        )}
                        {childProps.label && (
                            <span className="tq-text-node">{childProps.label}</span>
                        )}
                    </a>
                </li>
            );
        });
    }

    getProps() {
        return extractComponentProps(this.props, {
            additionalProps: ["extraClasses", "target"],
            propsDefaults: {
                target: "_self"
            }
        });
    }

    static getChildProps(child, config = {}) {
        const childProps = { config, data: child };
        return extractComponentProps(childProps, {
            additionalProps: ["href", "label", "icon", "target", "actions", "imgSrc", "imgAlt"],
            aliases: {
                href: "link",
                label: "value",
                icon: "image"
            }
        });
    }

    render() {
        const props = this.getProps();
        const items = this.buildItems();
        return <ul className={`tq-list-new ${props.extraClasses}`}>{items}</ul>;
    }
}

export default webui.connectComponent({
    actions: { dispatchSirenActions: "siren.dispatchSirenActions" }
})(SocialLinks);
