const IdleTimer = ({ timeout, onTimeout }: { timeout: number, onTimeout: () => void }) => {

    let interval: NodeJS.Timer, timeoutTracker: NodeJS.Timer;
    let expiredTime =  Date.now() + timeout * 1000;

    const updateExpiredTime = () => {
        if (timeoutTracker) {
            clearTimeout(timeoutTracker);
        }
        timeoutTracker = setTimeout(() => {
            expiredTime = Date.now() + timeout * 1000;
        }, 200);
    };

    const interactionTracker = () => {
        window.addEventListener("mousemove", updateExpiredTime);
        window.addEventListener("scroll", updateExpiredTime);
        window.addEventListener("click", updateExpiredTime);
        window.addEventListener("keydown", updateExpiredTime);
    };

    const trackerCleanUp = () => {
        clearInterval(interval);
        window.removeEventListener("mousemove", updateExpiredTime);
        window.removeEventListener("scroll", updateExpiredTime);
        window.removeEventListener("click", updateExpiredTime);
        window.removeEventListener("keydown", updateExpiredTime);
    };

    const startInterval = () => {
        updateExpiredTime();

        interval = setInterval(() => {
            const expectedExpiredTime = expiredTime || 0;
            //check if the current timer has passed the expected expire time
            if (expectedExpiredTime < Date.now()) {
                if (onTimeout) {
                    onTimeout();
                    trackerCleanUp();
                }
            }
        }, 1000);
    };

    interactionTracker();
    startInterval();

    return {
        trackerCleanUp
    };
};

export default IdleTimer;