import React from "react";
import webui, { BaseComponent } from "@tinqin/tinqin-web-ui";
import visibleHOC from "../../../HOC/components/visible/visibleHOC";

//lazy loaded container
//will render contents only if close to the viewport
class LazyContainer extends BaseComponent {
    state = {
        afterSomeTime: false
    };
    // componentDidMount() {
    //     setTimeout(() => {
    //         this.setState({ afterSomeTime: true });
    //     }, 2000);
    // }
    render() {
        if (!this.props.data) {
            return "";
        }
        //issue with Prerender.io not rendering full content
        // if (this.props.isVisible || this.state.afterSomeTime) {
        //     // console.log("lazy " + this.props.computedBindTo + " is visible.");
        //     return <>{this.resolveChildren()}</>;
        // } else {
        //     return null;
        // }

        return <>{this.resolveChildren()}</>;
    }
}

//using additional wrapper in order to take configuration of responsiveContainer
//and apply it to the ResponsiveHOC
function LazyHOCWrapper(props) {
    const dataProps = webui.getUtils().extractComponentProps(props, {
        additionalProps: ["extraClasses"]
    });
    const HOCComponent = visibleHOC(LazyContainer, {
        once: true,
        offset: 300,
        extraClasses: dataProps.extraClasses || ""
    });
    return <HOCComponent {...props}></HOCComponent>;
}

export default webui.connectComponent({
    actions: { dispatchSirenActions: "siren.dispatchSirenActions" }
})(LazyHOCWrapper);
