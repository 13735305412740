import React from "react";
import webui from "@tinqin/tinqin-web-ui";

export const ImageTitle = props => {
    if (!props.data) {
        return webui.getUtils().getEmptyContent();
    }
    const dataProps = webui.getUtils().extractComponentProps(props, {
        additionalProps: ["tag", "extraClasses", "imageSrc", "value"],
        propsDefaults: { tag: "h3" },
        aliases: {
            value: "label"
        }
    });

    const Tag = dataProps.tag;
    return (
        <Tag className={"tq-title"}>
            { dataProps.imageSrc && <img src={dataProps.imageSrc} alt={dataProps.value} />}
            {dataProps.value}
        </Tag>
    );
};

export default webui.connectComponent({
    actions: {
        triggerSet: "component.triggerSet"
    }
})(ImageTitle);
