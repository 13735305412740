import React from "react";
import webui, { BaseComponent } from "@tinqin/tinqin-web-ui";
import Link from "./Link";
import { getAddressFromActions } from "../../actions/page/PageAddress";
import ResponsiveHOC from "../../../HOC/components/responsive";

const extractComponentProps = webui.getUtils().extractComponentProps;
const closeBurgerAction = {
    actionType: "toggleDisplay",
    target: "un-burger",
    fields: [
        {
            name: "selectBy",
            value: "class"
        }
    ]
};

const trackMatomoClickEvent = {
    actionType: "triggerMatomoEvent",
    properties: {
        category: "Burger C",
        action: "Cliquer",
        name: ""
    }
};

class LinksContainer extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            isExpanded: false
        };

        this.toggleExpand = this.toggleExpand.bind(this);
    }

    toggleExpand() {
        this.setState({ isExpanded: !this.state.isExpanded });
    }

    onLinkClick(actions = [], label) {
        if (actions.length) {
            trackMatomoClickEvent.properties.eventName = label;
            return this.props.dispatchSirenActions(
                //closing burger when switching to different pages
                [...actions, trackMatomoClickEvent, closeBurgerAction],
                this.props.widgetId,
                this.props.computedBindTo,
                ["default"]
            );
        }
    }

    createExpandBtn() {
        const icon = this.state.isExpanded ? "tq-icon-minus" : "tq-icon-plus";
        return (
            <li key={"button-expand"} onClick={this.toggleExpand}>
                <button className="tq-button">
                    <i className={icon}></i>
                </button>
            </li>
        );
    }

    addLinksContainer(list, child, index, additionalColor = "") {
        const childProps = LinksContainer.getChildProps(child);
        childProps.style = {
            color: additionalColor
        };
        if (!child.actions) childProps.style.cursor = "default";
        list.push(
            <Link
                key={`level-1-link-${index}`}
                {...childProps}
                onClick={() => this.onLinkClick(child.actions, childProps.label)}
            />
        );
    }

    buildItems() {
        const props = this.getProps();
        const children = this.props.entities || [];
        const result = [];

        children.forEach((child, index) => {
            const btn = this.createExpandBtn();
            let visible = this.props.visible <= props.visible ? this.props.visible : props.visible;
            if (this.props.size === "S" || this.props.size === "M") {
                visible = 0;
            }
            if (visible < children.length) {
                if (!this.state.isExpanded) {
                    if (index < visible) {
                        this.addLinksContainer(result, child, index);
                    } else if (index === visible) {
                        result.push(btn);
                    }
                } else {
                    this.addLinksContainer(result, child, index);
                    if (index === children.length - 1) {
                        result.push(btn);
                    }
                }
            } else {
                this.addLinksContainer(result, child, index);
            }
        });

        return result;
    }

    getProps() {
        return extractComponentProps(this.props, {
            additionalProps: ["visible"],
            propsDefaults: {
                target: "_self",
                visible: Number.MAX_VALUE
            }
        });
    }

    static getChildProps(child) {
        const childProps = { config: {}, data: child };
        const result = extractComponentProps(childProps, {
            additionalProps: ["href", "label", "icon", "target", "extraClasses"],
            aliases: {
                href: "link",
                label: "value",
                icon: "image"
            },
            propsDefaults: {
                extraClasses: "tq-link tq-hyperlink",
                target: "_self"
            }
        });
        if (childProps.data && childProps.data.actions) {
            result.href = getAddressFromActions(childProps.data.actions);
        }
        return result;
    }

    render() {
        return this.buildItems();
    }
}

export default webui.connectComponent({
    actions: { dispatchSirenActions: "siren.dispatchSirenActions" }
})(ResponsiveHOC(LinksContainer));
