import React from "react";
import webui, { BaseComponent } from "@tinqin/tinqin-web-ui";
import EntityBuilder from "@tinqin/tinqin-web-ui/src/utils/EntityBuilder";
import { traverseAndApplyUnderlineColor } from "../../utils/InlineStyles";
import { xssFilter } from "../../utils/xssFilter";
import ResponsiveHOC from "../../../HOC/components/responsive";
import { getAddressFromActions } from "./../../actions/page/PageAddress";
const extractComponentProps = webui.getUtils().extractComponentProps;

class StoryCard extends BaseComponent {
    constructor(props) {
        super(props);
        this.underLineSection = React.createRef();
    }
    onClick = () => {
        this.dispatchSirenActions();
    };
    onButtonClick = (event, actions) => {
        event.preventDefault();
        if (actions.length) {
            return this.props.dispatchSirenActions(
                [...actions],
                this.props.widgetId,
                this.props.computedBindTo,
                ["default", "onSubscribe"]
            );
        }
    };
    onHyperLinkClick = event => {
        event.preventDefault();
    };
    componentDidMount() {
        const node = this.underLineSection.current;
        if (node) {
            traverseAndApplyUnderlineColor(node);
        }
    }
    componentDidUpdate() {
        const node = this.underLineSection.current;
        if (node) {
            traverseAndApplyUnderlineColor(node);
        }
    }
    render() {
        if (!this.props.data) {
            return this.getEmptyContent();
        }
        const actions = this.props.data.actions || [];
        const props = extractComponentProps(this.props, {
            additionalProps: [
                "image",
                "description",
                "logo",
                "logoAlt",
                "extraClasses",
                "hoverTitle"
            ],
            propsDefaults: { extraClasses: "" }
        });
        const className = "un-banner-story tq-panel tq-banner tq-banner-overlay tq-text-top";
        const clickableClass = actions.length ? "tq-clickable" : "";

        const dataEntity = EntityBuilder.of(this.props.data, { inplace: true });
        const badge = dataEntity.getChild("badge").getValue();
        const memberBadge = dataEntity.getChild("memberBadge").getValue();
        const subHeading = dataEntity.getChild("subHeading").getProperty("title");
        const category = dataEntity.getChild("category").getValue();
        const logo = dataEntity.getChild("logo");
        const image = dataEntity.getChild("image");
        const mobileImage = dataEntity.getChild("mobileImage");
        const logoSrc = logo.getProperty("src");
        const logoAlt = logo.getProperty("alt");
        const title = dataEntity.getChild("title").getValue();
        const description = dataEntity.getChild("description").getValue();
        const subscribeEntity = dataEntity.getChild("subscribe");
        const subscribe = subscribeEntity.getLabel();
        const subscribeEntityActions = subscribeEntity.getActions();
        const configActions = this.props.config.actions || [];
        const onSubscribeActions = configActions.filter(action =>
            action.events?.includes("onSubscribe")
        );
        let backgroundImageStyle = {};
        if (props.image) {
            backgroundImageStyle = { backgroundImage: `url("${props.image}")` };
        } else if (image) {
            if (this.props.size !== "XL") {
                backgroundImageStyle = {
                    backgroundImage: `url("${mobileImage.getProperty("src")}")`
                };
            } else {
                backgroundImageStyle = { backgroundImage: `url("${image.getProperty("src")}")` };
            }
        }
        const hoverTitleMarkup = (
            <h6 className="tq-panel-title">
                <span
                    className="tq-text-node"
                    dangerouslySetInnerHTML={{
                        __html: xssFilter.process(props.hoverTitle)
                    }}
                />
            </h6>
        );

        const cardActions = actions?.length ? actions : subscribeEntityActions;
        return (
            <div className={"un-anchor-custom"} onClick={this.onHyperLinkClick}>
                <div className={`${className} ${clickableClass}`} onClick={this.onClick}>
                    <div className="un-banner-story-bg" style={backgroundImageStyle} />
                    {props.logo && (
                        <img
                            className="un-banner-story-logo"
                            src={props.logo}
                            alt={props.logoAlt || "story logo"}
                        />
                    )}
                    <div className="tq-banner-content">
                        {badge && (
                            <span className=" tq-badge ">
                                <span className="tq-text-node">{badge}</span>
                            </span>
                        )}
                        {category && (
                            <div
                                className={
                                    "un-banner-story-category" +
                                    (memberBadge ? " un-banner-story-member" : "")
                                }
                            >
                                <div className="un-banner-story-category-name">
                                    <p>{category}</p>
                                </div>
                                {logoSrc && (
                                    <div className="un-banner-story-category-logo">
                                        <img src={logoSrc} alt={logoAlt} />
                                    </div>
                                )}
                                {memberBadge && (
                                    <div className="un-banner-story-category-badge">
                                        <p className="tq-text-node">{memberBadge}</p>
                                    </div>
                                )}
                            </div>
                        )}
                        {(title || description) && (
                            <div className="tq-story-panel-content">
                                {title && (
                                    <div className="tq-panel-title">
                                        <span className="tq-text-node">{title}</span>
                                    </div>
                                )}
                                {description && (
                                    <div className="tq-banner-description">
                                        <p>{description}</p>
                                    </div>
                                )}
                            </div>
                        )}
                        {subscribe && (
                            <div className="un-cp-banner-btn">
                                <button
                                    className="tq-btn"
                                    onClick={e =>
                                        this.onButtonClick(e, [
                                            ...onSubscribeActions,
                                            ...subscribeEntityActions
                                        ])
                                    }
                                >
                                    <span className="tq-text-node">{subscribe}</span>
                                </button>
                            </div>
                        )}
                        {subHeading && (
                            <a href={getAddressFromActions(cardActions)}>
                                <h2 className="tq-panel-title">
                                    <span
                                        className="tq-text-node"
                                        dangerouslySetInnerHTML={{
                                            __html: xssFilter.process(subHeading)
                                        }}
                                        ref={this.underLineSection}
                                    />
                                </h2>
                            </a>
                        )}
                        {props.hoverTitle ? (
                            !subHeading ? (
                                <a href={getAddressFromActions(cardActions)}>{hoverTitleMarkup}</a>
                            ) : (
                                hoverTitleMarkup
                            )
                        ) : null}
                        {props.description && (
                            <div className="tq-banner-description">
                                <span
                                    className="tq-text-node"
                                    dangerouslySetInnerHTML={{
                                        __html: xssFilter.process(props.description)
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default webui.connectComponent({
    actions: { dispatchSirenActions: "siren.dispatchSirenActions" }
})(ResponsiveHOC(StoryCard));
