import webui from "@tinqin/tinqin-web-ui";
const {
    setEntityProps,
    getEntity,
    setEntityChildren,
    getEntityValue,
    getParentPath,
    getEntityProps
} = webui.getActionByName("utils");

// use case for Je sui/Je suet when one of them is selected and not with default option
//use the selected value
export function updateContactMessageOnSelection(sirenAction, widgetId, curentPath) {
    return dispatch => {
        const parentPath = getParentPath(curentPath);
        const context = webui.getGlobalProperty("applicationContext");
        const adherentSection = document.getElementsByClassName("adherentSection").item(0);
        const isAdherentSectionVisible = adherentSection ? adherentSection.style.display : "none";
        const targetPath = parentPath + ".form.message";
        const profileSelectionPath = parentPath + ".profileSelection";
        const profileSelectionPrefix = dispatch(
            getEntityProps(profileSelectionPath, "selectionPrefix")
        );
        const { value: profileSelectionValue, label: profileSlectionLabel } = dispatch(
            getDropdownValue(profileSelectionPath)
        );
        const businessLineSelectionPath = parentPath + ".businessLineSelection";
        const businessSelectionPrefix = dispatch(
            getEntityProps(businessLineSelectionPath, "selectionPrefix")
        );
        const { value: businessLineSelectionValue, label: businessLineSelectionLabel } = dispatch(
            getDropdownValue(businessLineSelectionPath)
        );
        const noProfileSelection = !profileSelectionValue || profileSelectionValue === "0";
        const noBusinessLineSelection =
            !businessLineSelectionValue || businessLineSelectionValue === "0";
        let constructedMessage = "";
        //in case no value is selected, leave empty text area
        if (noProfileSelection && noBusinessLineSelection) {
            constructedMessage = "";
        } else if (!noProfileSelection) {
            //if there is profile selected
            if (!(isAdherentSectionVisible !== "none" && context.solidarm)) {
                constructedMessage = profileSelectionPrefix
                    ? `${profileSelectionPrefix} ${profileSlectionLabel}.`
                    : `${profileSlectionLabel}.`;
            }
            if (!noBusinessLineSelection) {
                constructedMessage += businessSelectionPrefix
                    ? ` ${businessSelectionPrefix} ${businessLineSelectionLabel}.`
                    : ` ${businessLineSelectionLabel}.`;
            }
        } else {
            //if there is bussiness line selected
            constructedMessage = businessSelectionPrefix
                ? `${businessSelectionPrefix} ${businessLineSelectionLabel}.`
                : ` ${businessLineSelectionLabel}.`;
        }
        // Change of behaviour, new field to be displayed
        dispatch(
            setEntityProps(targetPath, {
                constructedMessage
            })
        );
    };
}

function getDropdownValue(path) {
    return dispatch => {
        const dropdownData = dispatch(getEntity(path));
        if (dropdownData) {
            const value = dropdownData.properties && dropdownData.properties.value;
            const selectionEntity = dropdownData.entities.find(
                entity => entity.properties.id === value
            );
            if (!selectionEntity) {
                return {};
            }
            const label = selectionEntity.properties.label;
            return { value, label };
        } else {
            return {};
        }
    };
}

export function updatePhoneOnCountrySelection(sirenAction, widgetId, curentPath) {
    return (dispatch, getState) => {
        const parentPath = getParentPath(curentPath);
        const targetPath = parentPath + ".phone";
        const country = dispatch(updateFieldOnDropdownSelection(curentPath, targetPath, "value"));
        //In case the country code is not France we want to replace French numbers specific validation with more general one
        let phoneNumberInputValidationSchema;
        if (typeof country === "object" && country.properties && country.properties.id !== "FR") {
            phoneNumberInputValidationSchema = /^(\+)?([0-9\s]+)?$/m;
        }
        dispatch(setEntityProps(targetPath, { phoneNumberInputValidationSchema }));
    };
}

export function onContactFormTypeSelection(sirenAction, widgetId, currentPath) {
    return dispatch => {
        const context = webui.getGlobalProperty("applicationContext");
        const selectedType = dispatch(getEntityValue(currentPath));
        const adherentSection = document.getElementsByClassName("adherentSection").item(0);
        const nonAdherentSection = document.getElementsByClassName("nonAdherentSection").item(0);
        const clientNumberFields = document.getElementsByClassName("clientNumberField");
        const dateOfBirthFields = document.getElementsByClassName("dateOfBirthField");
        //update validation rules
        const parentPath = getParentPath(currentPath);
        // reset error messages
        dispatch(setEntityChildren(parentPath + ".form.errors", []));
        const clientNumberPath = parentPath + ".form.clientNumber";
        const dateOfBirthPath = parentPath + ".form.dateOfBirth";
        const businessSelection = parentPath + ".businessLineSelection";
        const profileSelection = parentPath + ".profileSelection";
        const profileSelectionValue = dispatch(getDropdownValue(profileSelection));
        // reset message field when switch form type to adherent or there is NO profile selected.
        // http://jira.tinqin.com/browse/UGP-2040
        const messageFieldPath = parentPath + ".form.message";

        if ((selectedType === "adherent" && context.solidarm) || !profileSelectionValue.value) {
            dispatch(
                setEntityProps(messageFieldPath, {
                    constructedMessage: ""
                })
            );
        } else if (profileSelectionValue.value) {
            const profileSelectionPrefix = dispatch(
                getEntityProps(profileSelection, "selectionPrefix")
            );
            const constructedMessage = profileSelectionPrefix
                ? `${profileSelectionPrefix} ${profileSelectionValue.label}.`
                : `${profileSelectionValue.label}.`;

            dispatch(
                setEntityProps(messageFieldPath, {
                    constructedMessage
                })
            );
        }
        const businessSelectionValue = dispatch(getDropdownValue(businessSelection));
        if (businessSelectionValue.value) {
            dispatch(setEntityProps(businessSelection, { value: [] }));
        }
        // ---------- It is commented because it needs to be prefilled
        // const profileSelectionValue = dispatch(getDropdownValue(profileSelection));
        // if (profileSelectionValue.value) {
        //     dispatch(setEntityProps(profileSelection, { value: [] }));
        // }
        //show/hide fields
        if (selectedType === "adherent") {
            if (clientNumberFields) {
                clientNumberFields[0].classList.remove("tq-hide");
                clientNumberFields[0].style.display = "";
                if (clientNumberFields[1]) {
                    clientNumberFields[1].classList.remove("tq-hide");
                    clientNumberFields[1].style.display = "";
                }
            }
            adherentSection.style.display = "";
            nonAdherentSection.style.display = "none";
            if (dateOfBirthFields) {
                dateOfBirthFields[0].style.display = "none";
                if (dateOfBirthFields[1]) {
                    dateOfBirthFields[1].style.display = "none";
                }
            }
            dispatch(
                setEntityProps(clientNumberPath, {
                    hidden: false
                })
            );
            dispatch(
                setEntityProps(dateOfBirthPath, {
                    hidden: true,
                    value: ""
                })
            );
            dispatch(
                setEntityProps(businessSelection, {
                    required: true
                })
            );
        } else {
            if (clientNumberFields) {
                clientNumberFields[0].classList.remove("tq-hide");
                clientNumberFields[0].style.display = "none";
                if (clientNumberFields[1]) {
                    clientNumberFields[1].classList.remove("tq-hide");
                    clientNumberFields[1].style.display = "none";
                }
            }
            adherentSection.style.display = "none";
            nonAdherentSection.style.display = "";
            if (dateOfBirthFields) {
                dateOfBirthFields[0].style.display = "";
                if (dateOfBirthFields[1]) {
                    dateOfBirthFields[1].style.display = "";
                }
            }
            dispatch(
                setEntityProps(clientNumberPath, {
                    hidden: true,
                    value: ""
                })
            );
            dispatch(
                setEntityProps(dateOfBirthPath, {
                    hidden: false
                })
            );
        }
    };
}

function updateFieldOnDropdownSelection(dropdownId, targetId, selectionProperty = "label") {
    return dispatch => {
        const dropdown = dispatch(getEntity(dropdownId));
        if (dropdown) {
            const selectionId = dropdown.properties.value;
            if (!selectionId) {
                return;
            }
            const selectionEntity = dropdown.entities.find(
                entity => entity.properties.id === selectionId
            );
            if (!selectionEntity) {
                return;
            }
            dispatch(
                setEntityProps(targetId, {
                    value: selectionEntity.properties[selectionProperty]
                })
            );
            return selectionEntity;
        }
    };
}

function toggleFormField(fieldClassName, fieldPath, toggle = true) {
    return dispatch => {
        const element = document.getElementsByClassName(fieldClassName).item(0);
        element.style.display = toggle ? "" : "none";
        if (toggle) {
            element.classList.remove("tq-hide");
        }
        return dispatch(
            setEntityProps(fieldPath, {
                hidden: !toggle
            })
        );
    };
}

export function toggleMemberCheckbox(action, widgetId, currentPath) {
    return dispatch => {
        const parentPath = getParentPath(currentPath);
        dispatch(setEntityChildren(parentPath + ".errors", []));
        const clientNumberClassName = "clientNumberField";
        const clientNumberPath = parentPath + ".clientNumber";
        const dateOfBirthFieldClassName = "dateOfBirthField";
        const dateOfBirthPath = parentPath + ".dateOfBirth";
        const selection = dispatch(getEntityValue(currentPath));
        if (selection) {
            dispatch(toggleFormField(clientNumberClassName, clientNumberPath, true));
            dispatch(toggleFormField(dateOfBirthFieldClassName, dateOfBirthPath, false));
            dispatch(setEntityProps(dateOfBirthPath, { value: "" }));
        } else {
            dispatch(toggleFormField(clientNumberClassName, clientNumberPath, false));
            dispatch(toggleFormField(dateOfBirthFieldClassName, dateOfBirthPath, true));
        }
    };
}

export function handleBirthDate(sirenAction, widgetId, currentPath) {
    return dispatch => {
        const props = dispatch(getEntityProps(currentPath));
        const value = props.value;
        const oldValue = props.oldValue || null;

        const digits = value.replaceAll("/", "");
        let newValue = "";
        for (let i = 0; i < digits.length && i < 8; i++) {
            newValue += digits[i];
            if (i === 1 || i === 3) newValue += "/";
        }
        if (
            oldValue &&
            oldValue[oldValue.length - 1] === "/" &&
            digits === oldValue.replaceAll("/", "")
        ) {
            newValue = newValue.slice(0, -1);
        }
        if (digits !== value && digits.length < 8 && oldValue && value.length < oldValue.length) {
            dispatch(setEntityProps(currentPath, { oldValue: newValue }));
        } else {
            dispatch(setEntityProps(currentPath, { oldValue: newValue, value: newValue }));
        }
    };
}
