/**
 * Created by g.lambov on 13.11.2017.
 */
const documentedProperties = [
    {
        name: "extraClasses",
        type: "String",
        defaultValue: "n/a",
        isRequired: "false",
        description: "Extra css classes to be added to Carousel container."
    },
    {
        name: "children",
        type: "React.Children",
        defaultValue: "n/a",
        isRequired: "true",
        description: "Carousel component nests your content, so some children are expected."
    },
    {
        name: "currentSlide",
        type: "Number",
        defaultValue: "0",
        isRequired: "false",
        description: "Manually set the index of the slide to be shown at first (0-based indexing)."
    },
    {
        name: "visibleSlides",
        type: "Number",
        defaultValue: "1",
        isRequired: "false",
        description: "Defines the number of slides to be visible at a time."
    },
    {
        name: "slidesToScroll",
        type: "Number",
        defaultValue: "1",
        isRequired: "false",
        description: "Number of slides to scroll at once."
    },
    {
        name: "showNavigationArrows",
        type: "Boolean",
        defaultValue: "true",
        isRequired: "false",
        description: "Should we show Left and right navigation arrows."
    },
    {
        name: "hiddenNavigationArrows",
        type: "Boolean",
        defaultValue: "false",
        isRequired: "false",
        description:
            "Class applied to navigation arrows if they are enabled, show arrows only onhover"
    },
    {
        name: "showPaginationIndicators",
        type: "Boolean",
        defaultValue: "true",
        isRequired: "false",
        description: "Show indicators at the bottom with links for changing the current item."
    },
    {
        name: "paginationIndicatorsOutside",
        type: "Boolean",
        defaultValue: "false",
        isRequired: "false",
        description: "Css class applied to the PaginationIndicators if they are enabled."
    },
    {
        name: "navigationIndicatorsType",
        type: "String",
        defaultValue: "circle",
        isRequired: "false",
        description:
            'Sets the style of the pagination indicators. Supported: "circle" and "bullets".'
    },
    {
        name: "verticalSlideDirection",
        type: "Boolean",
        defaultValue: "false",
        isRequired: "false",
        description: "Enable the slides to transition vertically."
    },
    {
        name: "autoPlay",
        type: "Boolean",
        defaultValue: "false",
        isRequired: "false",
        description: "Autoplay mode active."
    },
    {
        name: "autoPlayTimeInterval",
        type: "Number",
        defaultValue: "3000",
        isRequired: "false",
        description: "Time interval for autoplay iteration (in milliseconds)."
    },
    {
        name: "stopOnHover",
        type: "Boolean",
        defaultValue: "false",
        isRequired: "false",
        description: "Stop autoplay while mouse is over the carousel."
    },
    {
        name: "endlessRotation",
        type: "Boolean",
        defaultValue: "true",
        isRequired: "false",
        description: "Infinite loop sliding."
    },
    {
        name: "fade",
        type: "Boolean",
        defaultValue: "false",
        isRequired: "false",
        description: "Carousel use fade for slides transition."
    },
    {
        name: "onChange",
        type: "Function",
        defaultValue: "n/a",
        isRequired: "false",
        description: "A callback to be called when a carousel slide item change the position."
    },
    {
        name: "isSimpleCarousel",
        type: "Boolean",
        defaultValue: "false",
        isRequired: "false",
        description: "Property to determinate if carousel is just a simple slider"
    }
];

export default documentedProperties;
