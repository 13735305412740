import React from "react";
import webui, { BaseComponent } from "@tinqin/tinqin-web-ui";
import UneoCarousel from "./uneo-carousel/UneoCarousel";

class RubricsCarousel extends BaseComponent {
    onClick = () => {
        this.dispatchSirenActions();
    };

    getProps() {
        return webui.getUtils().extractComponentProps(this.props, {
            additionalProps: [
                "extraClasses",
                "visibleSlides",
                "hideArrowsBelow",
                "endlessRotation",
                "isSimpleCarousel"
            ],
            propsDefaults: {
                extraClasses: "",
                visibleSlides: 4,
                endlessRotation: true,
                isSimpleCarousel: false
            }
        });
    }

    render() {
        if (!this.props.data) {
            return this.getEmptyContent();
        }

        const props = this.getProps();
        const children = this.resolveChildren();
        const showNavigationArrows = props.hideArrowsBelow
            ? children.length > props.hideArrowsBelow
            : false;

        const visibleSlides =
            props.visibleSlides > children.length ? children.length : props.visibleSlides;
        const useCarousel = props.visibleSlides <= children.length;

        return (
            <>
                {!useCarousel && <div className={"un-theme-box-container"}>{children}</div>}
                {useCarousel && (
                    <UneoCarousel
                        visibleSlides={visibleSlides}
                        extraClasses={props.extraClasses}
                        showNavigationArrows={showNavigationArrows}
                        endlessRotation={props.endlessRotation}
                        isSimpleCarousel={props.isSimpleCarousel}
                    >
                        {children}
                    </UneoCarousel>
                )}
            </>
        );
    }
}

RubricsCarousel.displayName = "RubricsCarousel";

export default webui.connectComponent({
    actions: { dispatchSirenActions: "siren.dispatchSirenActions" }
})(RubricsCarousel);
