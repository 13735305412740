import React from "react";
import webui, { BaseComponent } from "@tinqin/tinqin-web-ui";
import EntityBuilder from "@tinqin/tinqin-web-ui/src/utils/EntityBuilder";
import { getAddressFromActions } from "../../actions/page/PageAddress";
import { xssFilter } from "../../utils/xssFilter";
import "@tinqin/design-uneo-site-cp/less/cards.less";

class SolutionBox extends BaseComponent {
    onClick = event => {
        event.preventDefault();
        this.dispatchSirenActions();
    };

    gatherProps() {
        const data = this.props.data || {};
        const siren = EntityBuilder.of(data);

        let titleImage = siren.getChild("title-image");
        const titleImageAlt = titleImage.getProperty("alt");
        titleImage = titleImage.getProperty("src");
        let bottomImage = siren.getChild("bottom-image");
        const bottomImageAlt = bottomImage.getProperty("alt");
        bottomImage = bottomImage.getProperty("src");
        let backgroundImage = siren.getChild("background-image");
        const backgroundImageAlt = backgroundImage.getProperty("alt");
        backgroundImage = backgroundImage.getProperty("src");
        let text = siren.getChild("text");
        text = text.getValue();
        let mainColor = siren.getChild("main-color");
        mainColor = mainColor.getValue();

        return {
            titleImage,
            titleImageAlt,
            bottomImage,
            bottomImageAlt,
            backgroundImage,
            backgroundImageAlt,
            text,
            mainColor
        };
    }

    render() {
        if (!this.props.data) {
            return this.getEmptyContent();
        }

        const props = this.gatherProps();
        const {
            titleImage,
            titleImageAlt,
            bottomImage,
            bottomImageAlt,
            backgroundImage,
            backgroundImageAlt,
            text,
            mainColor
        } = props;
        const color = `un-card-uneo-${mainColor}`;
        const mainClass = `tq-panel un-anchor-custom tq-card tq-clickable-card un-card-uneo ${color} tq-img-card`;

        return (
            <div className={mainClass} onClick={this.onClick}>
                <div className="un-card-logo-box">
                    <img className="un-card-logo-img" src={titleImage} alt={titleImageAlt} />
                </div>
                <div className="un-card-main-img">
                    <img
                        className="tq-img tq-thumb-img"
                        src={backgroundImage}
                        alt={backgroundImageAlt}
                    />
                </div>
                <div className="tq-card-content">
                    <a href={getAddressFromActions(this.props.data.actions)}>
                        <h3 className="tq-panel-title tq-title-background">
                            <span
                                className="tq-text-node"
                                dangerouslySetInnerHTML={{
                                    __html: xssFilter.process(text)
                                }}
                            />
                        </h3>
                    </a>
                </div>
                {(bottomImage || bottomImageAlt) && (
                    <div className="tq-text-content">
                        <div>
                            <p className="tq-paragraph tq-text-center">
                                <span className="tq-image">
                                    <img
                                        className="tq-image"
                                        src={bottomImage}
                                        alt={bottomImageAlt}
                                    />
                                </span>
                            </p>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default webui.connectComponent({
    actions: { dispatchSirenActions: "siren.dispatchSirenActions" }
})(SolutionBox);
