import React from "react";
import PropTypes from "prop-types";

export default class DropdownOption extends React.Component {
    render() {
        let className = this.props.selected ? "tq-current" : "";
        className += this.props.disabled ? " tq-disabled" : "";
        if (this.props.withCheckbox && this.props.selected) {
            className += " tq-selected";
        }

        return (
            <li
                id={this.props.code}
                tabIndex="-1"
                data-code={this.props.code}
                className={className}
            >
                {this.props.withCheckbox && (
                    <div className="tq-checkbox" data-code={this.props.code}>
                        <input
                            disabled={this.props.disabled}
                            checked={this.props.selected}
                            onChange={() => {}} //Due to React warning!
                            className="tq-checkbox"
                            type="checkbox"
                            tabIndex="-1"
                            data-code={this.props.code}
                            value="beer"
                        />
                    </div>
                )}
                {this.props.icon && <i className={this.props.icon} />}
                {this.props.label && (
                    <span data-code={this.props.code} className="tq-text-node">
                        {this.props.label}
                    </span>
                )}
            </li>
        );
        //NOTE: the all the markup here also have data-code, because of IE, that thinks span is and input is not focusable!...
    }
}

DropdownOption.propTypes = {
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
    withCheckbox: PropTypes.bool,
    code: PropTypes.string,
    icon: PropTypes.string,
    label: PropTypes.string
};
