/**
 * Created by g.lambov on 13.11.2017.
 */
import React from "react";
import PropTypes from "prop-types";
import documentedProps from "./documentation/CarouselDocumentedProps";
import { defaultProps } from "./implementation/defaultProps";
import AutoPlay from "./implementation/AutoPlay";
import Animation from "./implementation/Animation";
import { computeTargetSlide, computeCurrentSlide } from "./implementation/utils";

export default class UneoCarousel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            animating: false,
            currentSlide: this.props.currentSlide || defaultProps.currentSlide,
            isMouseEntered: false,
            contentStyle: this.getWrapperStyle()
        };
        this.carouselWrapper = React.createRef();
        this.Animation = new Animation(this.props);
        this.bindEventHandlers();
    }

    componentDidMount() {
        if (!this.props.children) {
            return;
        }

        if (!this.props.fade) {
            const style = this.Animation.computeTransition(
                this.state.currentSlide,
                this.state.contentStyle
            );
            this.timer = setTimeout(
                this.setState({ contentStyle: style }, () => {
                    clearTimeout(this.timer);
                    this.timer = undefined;
                }),
                3
            );
        }

        if (this.props.autoPlay) {
            this.AutoPlay = new AutoPlay(
                this.props,
                this.carouselWrapper,
                this.increment,
                this.stopOnHover,
                this.startOnLeave
            );
            this.AutoPlay.setupAutoPlay();
        }
    }

    componentWillUnmount() {
        if (this.props.autoPlay) {
            this.AutoPlay.destroyAutoPlay();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.currentSlide !== this.state.currentSlide) {
            this.moveToSlide(nextProps.currentSlide);
        }

        if (nextProps.autoPlay !== this.props.autoPlay) {
            if (nextProps.autoPlay) {
                this.AutoPlay = new AutoPlay(
                    this.props,
                    this.carouselWrapper,
                    this.increment,
                    this.stopOnHover,
                    this.startOnLeave
                );
                this.AutoPlay.setupAutoPlay();
            } else {
                this.AutoPlay.destroyAutoPlay();
            }
        }
    }

    bindEventHandlers() {
        // Make sure event handlers have the correct context
        this.stopOnHover = this.stopOnHover.bind(this);
        this.startOnLeave = this.startOnLeave.bind(this);
        this.decrement = this.decrement.bind(this);
        this.increment = this.increment.bind(this);
        this.changeSlide = this.changeSlide.bind(this);
    }

    stopOnHover() {
        this.setState({ isMouseEntered: true });
    }

    startOnLeave() {
        this.setState({ isMouseEntered: false });
    }

    moveToSlide(slideIndex) {
        // Functionality of animateSlide and postSlide is merged into this function
        // console.log("slideHandler", slideIndex);
        const slidesCount = this.props.children.length;

        if (this.state.animating) {
            return;
        }

        // Don't change slide if it's not EndlessRotation and slideIndex is not into the range
        if (
            !this.props.isSimpleCarousel &&
            !this.isEndlessRotation() &&
            (slideIndex < 0 || slideIndex >= slidesCount)
        ) {
            return;
        } else if (
            this.props.isSimpleCarousel &&
            !this.isEndlessRotation() &&
            (slideIndex < 0 || slideIndex >= slidesCount - (this.props.visibleSlides - 1))
        ) {
            return;
        }

        if (this.props.fade) {
            // Fade slides animation
            const targetSlide = computeTargetSlide(slideIndex, slidesCount);
            this.setState({ currentSlide: targetSlide }, () => {
                this.handleOnChange();
            });
        } else {
            // Horizontal and Vertical slides animation
            const targetSlide = slideIndex;
            const isEndlessRotation = this.isEndlessRotation();
            const currentSlide = computeCurrentSlide(slideIndex, slidesCount, isEndlessRotation);

            // Slide Transition happens here.
            // Animated transition happens to targetSlide and
            // non-animated transition happens to currentSlide.
            this.setState(
                {
                    animating: true,
                    currentSlide: currentSlide,
                    contentStyle: this.Animation.computeAnimateTransition(
                        targetSlide,
                        this.state.contentStyle
                    )
                },
                () => {
                    this.animationEndTimer = setTimeout(() => {
                        this.setState({
                            animating: false,
                            contentStyle: this.Animation.computeTransition(
                                currentSlide,
                                this.state.contentStyle
                            )
                        });

                        this.handleOnChange();
                        clearTimeout(this.animationEndTimer);
                        this.animationEndTimer = undefined;
                    }, defaultProps.animationTimeInterval);
                }
            );
        }
        // don't reset autoPlay when stopOnHover is enabled, doing so will trigger a call to
        // autoPlay more than once and will result in the interval function not being cleared correctly.
        if (this.props.autoPlay && this.state.isMouseEntered === false) {
            this.AutoPlay.resetAutoPlay();
        }
    }

    decrement(event) {
        if (event) {
            event.stopPropagation();
        }

        this.moveToSlide(this.state.currentSlide - this.getSlidesToScroll());
    }

    increment(event) {
        if (event) {
            event.stopPropagation();
        }

        this.moveToSlide(this.state.currentSlide + this.getSlidesToScroll());
    }

    getSlidesToScroll() {
        let slidesToScroll = this.props.slidesToScroll || defaultProps.slidesToScroll;
        const visibleSlides = this.props.visibleSlides || defaultProps.visibleSlides;

        if (slidesToScroll >= visibleSlides) {
            slidesToScroll = visibleSlides;
        }

        return slidesToScroll;
    }

    changeSlide(event) {
        if (event && event.stopPropagation) {
            event.stopPropagation();
        }

        const newIndex = event.currentTarget.value;
        let style = {};
        if (!this.props.fade) {
            style = this.Animation.computeAnimateTransition(newIndex, this.state.contentStyle);
        }

        this.setState({ currentSlide: newIndex, contentStyle: style }, () => {
            if (this.props.autoPlay && this.state.isMouseEntered === false) {
                this.AutoPlay.resetAutoPlay();
            }

            this.handleOnChange();
        });
    }

    handleOnChange() {
        if (this.props.onChange) {
            this.props.onChange(this.state);
        }
    }

    buildContainerClass() {
        let className = "tq-carousel ";
        className += this.props.extraClasses || "";
        className += this.props.paginationIndicatorsOutside ? " tq-bullets-out " : "";
        className += this.props.verticalSlideDirection ? " tq-carousel-v " : "";
        className += this.props.navigationIndicatorsType === "circle" ? " tq-circle-bullets " : "";
        className += this.props.fade ? " tq-carousel-fade " : "";
        className += this.props.hiddenNavigationArrows ? " tq-hidden-arrows " : "";

        return className;
    }

    buildNavIndicators() {
        // carousel pagination bullets
        const showPaginationIndicators =
            typeof this.props.showPaginationIndicators === "boolean"
                ? this.props.showPaginationIndicators
                : defaultProps.showPaginationIndicators;

        if (showPaginationIndicators) {
            return (
                <div className="tq-carousel-bullets">
                    <ul>
                        {React.Children.map(this.props.children, (item, index) => {
                            // Each Carousel child response to one pagination item.
                            if (!React.isValidElement(item)) return;

                            let className, changeSlideHandler;
                            if (this.state.currentSlide === index) {
                                className = "tq-active";
                            } else {
                                changeSlideHandler = this.changeSlide;
                            }

                            return (
                                <li
                                    className={className}
                                    onClick={changeSlideHandler}
                                    value={index}
                                    key={index}
                                />
                            );
                        })}
                    </ul>
                </div>
            );
        }
    }

    renderSlides() {
        const slidesContent = [],
            preCloneSlides = [],
            postCloneSlides = [];
        const slidesCount = React.Children.count(this.props.children);

        React.Children.forEach(this.props.children, (currentSlide, index) => {
            if (!React.isValidElement(currentSlide)) return;
            const className =
                this.state.currentSlide === index && this.props.fade
                    ? "tq-slide tq-active-slide"
                    : "tq-slide";
            let key;
            const visibleSlides = this.props.visibleSlides || defaultProps.visibleSlides;
            const slideWidth = this.getSlideWidth(visibleSlides);

            slidesContent.push(
                <div
                    key={"original" + index}
                    data-index={index}
                    className={className}
                    style={{ width: slideWidth }}
                >
                    {currentSlide}
                </div>
            );

            // Add cloning slides for proper endless rotation.
            if (this.isEndlessRotation() && !this.props.fade) {
                if (index >= slidesCount - visibleSlides) {
                    key = -(slidesCount - index);
                    preCloneSlides.push(
                        <div
                            key={"precloned" + key}
                            data-index={key}
                            className="tq-slide"
                            style={{ width: slideWidth }}
                        >
                            {currentSlide}
                        </div>
                    );
                }

                if (index < visibleSlides) {
                    key = slidesCount + index;
                    postCloneSlides.push(
                        <div
                            key={"postcloned" + key}
                            data-index={key}
                            className="tq-slide"
                            style={{ width: slideWidth }}
                        >
                            {currentSlide}
                        </div>
                    );
                }
            }
            // Add post-cloned slides when visibleSlides > 1 and transition to last one
            // console.log(this.props.isSimpleCarousel);
            if (!this.props.isSimpleCarousel) {
                if (!this.isEndlessRotation() && !this.props.fade) {
                    if (visibleSlides > 1 && index < visibleSlides - 1) {
                        const key = slidesCount + index;
                        postCloneSlides.push(
                            <div
                                key={"postcloned" + key}
                                data-index={key}
                                className="tq-slide"
                                style={{ width: slideWidth }}
                            >
                                {currentSlide}
                            </div>
                        );
                    }
                }
            }
        });

        return preCloneSlides.concat(slidesContent, postCloneSlides);
    }

    isEndlessRotation() {
        if (typeof this.props.endlessRotation === "boolean") {
            return this.props.endlessRotation;
        }

        return defaultProps.endlessRotation;
    }

    buildSlideControls() {
        // carousel slide arrows
        let prevArrow, nextArrow;
        const showNavigationArrows =
            typeof this.props.showNavigationArrows === "boolean"
                ? this.props.showNavigationArrows
                : defaultProps.showNavigationArrows;

        if (showNavigationArrows) {
            let prevArrowClass = "tq-icon-arrow-left-big";
            let nextArrowClass = "tq-icon-arrow-right-big";

            if (this.props.verticalSlideDirection) {
                prevArrowClass = "tq-icon-arrow-up-big";
                nextArrowClass = "tq-icon-arrow-down-big";
            }

            prevArrow = (
                <div className="tq-arrow tq-prev" onClick={this.decrement}>
                    <i className={prevArrowClass} />
                </div>
            );
            nextArrow = (
                <div className="tq-arrow tq-next" onClick={this.increment}>
                    <i className={nextArrowClass} />
                </div>
            );

            if (this.props.isSimpleCarousel) {
                if (
                    this.state.currentSlide ===
                    this.props.children.length - this.props.visibleSlides
                ) {
                    nextArrow = null;
                }
                if (this.state.currentSlide === 0) {
                    prevArrow = null;
                }
            }
        }

        return { prevArrow, nextArrow };
    }

    getWrapperStyle() {
        // Each Carousel child can be placed in a single slide.
        // For an animated transition, it is necessary to calculate the width/height of the container.
        // And slide width/height percent depend of direction (horizontal or vertical).
        const wrapperStyle = {};

        if (!this.props.fade) {
            const visibleSlides = this.props.visibleSlides || defaultProps.visibleSlides;

            // Total count children + cloning
            const slidesCount = this.props.children.length + 2 * visibleSlides;
            // Consider dimension by 100% * slidesCount.
            const containerMeasurement = (100 * slidesCount) / visibleSlides + "%";

            if (this.props.verticalSlideDirection) {
                wrapperStyle.height = containerMeasurement;
            } else {
                wrapperStyle.width = containerMeasurement;
            }
        }

        return wrapperStyle;
    }

    getSlideWidth(visibleSlides) {
        let slideWidth;

        if (!this.props.verticalSlideDirection && !this.props.fade) {
            // Total count children + cloning for proper animation
            const slidesCount = this.props.children.length + 2 * visibleSlides;
            slideWidth = 100 / slidesCount + "%";
        }

        return slideWidth;
    }

    render() {
        // console.log("Carousel rendering ...");
        const containerClass = this.buildContainerClass();
        const navIndicators = this.buildNavIndicators();
        const slidesContent = this.renderSlides();
        const { prevArrow, nextArrow } = this.buildSlideControls();

        return (
            <div className={containerClass} ref={this.carouselWrapper}>
                {navIndicators}
                <div className="tq-carousel-content" style={this.state.contentStyle}>
                    {slidesContent}
                </div>
                {prevArrow}
                {nextArrow}
            </div>
        );
    }
}

UneoCarousel.displayName = "UneoCarousel";

UneoCarousel.propTypes = {
    extraClasses: PropTypes.string,
    children: PropTypes.node,
    currentSlide: PropTypes.number,
    visibleSlides: PropTypes.number,
    slidesToScroll: PropTypes.number,
    showNavigationArrows: PropTypes.bool,
    hiddenNavigationArrows: PropTypes.bool,
    showPaginationIndicators: PropTypes.bool,
    paginationIndicatorsOutside: PropTypes.bool,
    navigationIndicatorsType: PropTypes.oneOf(["circle", "bullets"]),
    verticalSlideDirection: PropTypes.bool,
    autoPlay: PropTypes.bool,
    autoPlayTimeInterval: PropTypes.number,
    stopOnHover: PropTypes.bool,
    endlessRotation: PropTypes.bool,
    fade: PropTypes.bool,
    onChange: PropTypes.func
};

UneoCarousel.documentedProps = documentedProps;
