import Immutable from "immutable";

const reducers = {
    data: {
        ADD_ENTITY: (state, action) => {
            const payload = action.payload || {};
            const targetPath = payload.target || [];
            const data = payload.data || {};
            const targetEntity = state.getIn(targetPath);
            const currentEntities = targetEntity.get("entities");
            //add new entity
            const newEntity = targetEntity.set(
                "entities",
                currentEntities.push(Immutable.fromJS(data))
            );
            return state.setIn(targetPath, newEntity);
        }
    },
    config: {
        PRESET_CONFIG: (state, action) => {
            const path = Array.isArray(action.payload.path)
                ? action.payload.path
                : action.payload.path.split(".");
            if (action.payload.toSet) {
                return state.setIn(path, Immutable.fromJS(action.payload.toSet));
            } else {
                return state;
            }
        }
    }
};

export default reducers;
