import webui, { BaseComponent } from "@tinqin/tinqin-web-ui";

//container for displaying component based on data.properties.type instead of config.type
//to consider this component for default component when there is no config.type but there is cofnig.bindTo
class TypeContainer extends BaseComponent {
    constructor(props) {
        super(props);
        this.extractDataProps = this.extractDataProps.bind(this);
    }
    componentDidMount() {
        const data = this.extractDataProps();
        const hash = window.location.hash;
        //If hash in the url corresponds to element's id scroll to the element
        if (data && data.anchorId && data.anchorId === hash.substring(1)) {
            const element = document.querySelector(hash);
            if (element) {
                //Timeout is set to make sure all the elements are at the right position
                setTimeout(() => {
                    const rect = element.getBoundingClientRect();
                    const viewHeight = Math.max(
                        document.documentElement.clientHeight,
                        window.innerHeight || 0
                    );
                    window.scroll({
                        top: rect.top - viewHeight * 0.1,
                        behavior: "smooth"
                    });
                }, 500);
            }
        }
    }

    extractDataProps() {
        return this.props.data ? this.props.data.properties || {} : null;
    }

    render() {
        const data = this.extractDataProps();
        if (!data) {
            return this.getEmptyContent();
        }
        const dataProps = webui.getUtils().extractComponentProps(this.props, {
            additionalProps: ["type", "typeMap", "defaultType"],
            propsDefaults: {
                type: "container",
                typeMap: {}
            }
        });
        if (dataProps.type === "typeContainer") {
            webui.warn("cycling typeContainer");
            return null;
        }
        //it is possible to have mapping between data.type and fe component types
        let childType = dataProps.type;
        if (dataProps.typeMap[childType]) {
            childType = dataProps.typeMap[childType];
        } else {
            childType = dataProps.defaultType;
        }
        const childConfig = Object.assign({}, this.props.config, {
            type: childType,
            bindTo: this.props.computedBindTo
        });
        const options = {
            parentBindTo: this.props.parentBindTo
        };
        return this.resolveChild(childConfig, 0, options);
    }
}

export default webui.connectComponent()(TypeContainer);
