import React from "react";
import webui, { BaseComponent } from "@tinqin/tinqin-web-ui";
import { getAddressFromActions } from "../../actions/page/PageAddress";

const extractComponentProps = webui.getUtils().extractComponentProps;

class Links extends BaseComponent {
    onLinkClick = event => {
        event.preventDefault();
        this.dispatchSirenActions(["default", "click"]);
    };

    static extractDashboard(child) {
        const actions = child.actions || [];
        const switchDashboard = actions.find(a => !a || a.actionType === "switchDashboard") || {};
        const fields = switchDashboard.fields || [];
        const field = fields.find(field => field.name === "dashboard") || {};

        return field.value;
    }
    //We need childActions to track matomo events
    getItemClick(childEntity, childActions) {
        const dispatch = webui.getDispatchMethod();
        const dispatchAction = webui.getActionByName("siren.dispatchSirenActions");
        const data = childEntity.data;
        const actions = [...childActions, ...(data.actions || [])];
        dispatch(dispatchAction([...actions], this.props.widgetId, childEntity.computedBindTo));
        return event => {
            event && event.stopPropagation();
        };
    }

    buildItems() {
        const data = this.props.data || {};
        const children = data.entities || [];
        const none = { display: "none" };
        const props = this.getProps();

        return children.map((child, index) => {
            const childConfig = this.props?.config.childConfig || {};
            const computedBindTo = this.props?.computedBindTo + "." + child.class[0];
            const childProps = Links.getChildProps(child, childConfig);
            const dashboardToSwitchTo = Links.extractDashboard(child);
            const dashboardHref = `/?dashboard=${dashboardToSwitchTo}`;
            const target = childProps.target || props.target;
            const childActions = childProps.actions || [];
            if (this.props.config.mainMenu && childProps.hiddenInMainMenu) {
                return null;
            }
            return (
                <li
                    key={`link-${index}`}
                    onClick={() => this.getItemClick({ computedBindTo, data: child }, childActions)}
                >
                    {childProps.href && (
                        <a
                            href={childProps.href}
                            target={target}
                            rel={"noreferrer"}
                            className={childProps.extraClasses || ""}
                            onClick={this.onLinkClick}
                        >
                            {childProps.icon && <i className={childProps.icon} />}
                            {childProps.label && (
                                <span className="tq-text-node">{childProps.label}</span>
                            )}
                        </a>
                    )}
                    {!childProps.href && dashboardToSwitchTo && (
                        <a
                            href={dashboardHref}
                            className={childProps.extraClasses || ""}
                            target={target}
                            rel={"noreferrer"}
                            style={none}
                        />
                    )}
                    {!childProps.href && !dashboardToSwitchTo && childProps.label && (
                        <span className="tq-clickable tq-text-node" onClick={this.onLinkClick}>
                            {childProps.label}
                        </span>
                    )}
                </li>
            );
        });
    }

    getProps() {
        return extractComponentProps(this.props, {
            additionalProps: ["extraClasses", "target"],
            propsDefaults: {
                target: "_self"
            }
        });
    }

    static getChildProps(child, config = {}) {
        const childProps = { config, data: child };
        const result = extractComponentProps(childProps, {
            additionalProps: [
                "href",
                "label",
                "icon",
                "target",
                "hiddenInMainMenu",
                "extraClasses",
                "actions"
            ],
            aliases: {
                href: "link",
                label: "value",
                icon: "image"
            }
        });
        if (childProps.data && childProps.data.actions) {
            result.href = getAddressFromActions(childProps.data.actions);
        }
        return result;
    }

    render() {
        const props = this.getProps();
        const items = this.buildItems();
        return props.extraClasses ? (
            <ul onClick={this.onClick} className={`tq-list-new ${props.extraClasses}`}>
                {items}
            </ul>
        ) : (
            <>{items}</>
        );
    }
}

export default webui.connectComponent({
    actions: { dispatchSirenActions: "siren.dispatchSirenActions" }
})(Links);
