import React from "react";
import Button from "../button-tmp/Button";
import webui, { BaseComponent } from "@tinqin/tinqin-web-ui";

class StickyFooter extends BaseComponent {
    onClose = () => {
        this.props.setGlobalProperty({ name: "stickyFooterClosed", value: true });
        sessionStorage.setItem("stickyFooterClosed", true);
        //Dispatching action for eventual future use case - saving a cookie when user decide to close.
        this.dispatchSirenActions(["default", "click"]);
    };

    render() {
        if (
            !this.props.data ||
            this.props.globalProperties.stickyFooterClosed ||
            sessionStorage.getItem("stickyFooterClosed")
        ) {
            return this.getEmptyContent();
        }
        return (
            <div className={"tq-neutral-color-bg-6 tq-text-center un-contact-box"}>
                {this.resolveChildren()}
                <Button
                    background={"stripped"}
                    icon={"tq-icon-close"}
                    onClick={this.onClose}
                    ariaLabel={"close"}
                />
            </div>
        );
    }
}

export default webui.connectComponent({
    globalProperties: ["stickyFooterClosed"],
    actions: {
        dispatchSirenActions: "siren.dispatchSirenActions",
        setGlobalProperty: "global.setGlobalProperty"
    }
})(StickyFooter);
