import webui from "@tinqin/tinqin-web-ui";
const {
    setEntityProps,
} = webui.getActionByName("utils");

export function playRadio(_sirenAction, _widgetId, currentPath: string) {
    return dispatch => {
        dispatch(setEntityProps(currentPath, { remotePlay: true } ));
    };
}
