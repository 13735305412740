export const defaultProps = {
    currentSlide: 0, //first visible Item (0-based indexing)
    visibleSlides: 1,
    slidesToScroll: 1,
    showNavigationArrows: true,
    showPaginationIndicators: true,
    autoPlayTimeInterval: 3000,
    animationTimeInterval: 750,
    endlessRotation: true,
    transition: "0.75s ease-in-out",
    transitionNone: "0s",
    isSimpleCarousel: false
};
