const COLORS = {
    blue: "#164194",
    red: "#F01A1A",
    beige: "#DDD2C0",
    white: "#FFFFFF",
    palegreen: "#10C891",
    orange: "#EF7B53",
    lightblue: "#21C3F6",
    green: "#96C11F",
    palered: "#EA5161",
    coral: "#F08262",
    darkblue: "#053853",
    darkgray: "#939393",
    platinum: "#F5F5F5",
    lightgray: "#EEEEEE",
    gray: "#C4C4C4",
    silver: "#DBDBDB",
    rosepink: "#E6007E",
    textgray: "#383F4E",
    darkblue2: "#051D4B",
    solidblue: "#38A1DB",
    darkred: "#7E1C51",
    solidarm_white: "#FFFFFF",
    solidarm_red: "#E83A4A",
    solidarm_cyan: "#0BBBEF",
    solidarm_creme: "#FFF2D3",
    solidarm_green: "#96C11F",
    solidarm_magenta: "#E6007E",
    solidarm_brightorange: "#FFB443",
    solidarm_turquoise: "#18D3C8",
    solidarm_purple: "#9B66DF",
    solidarm_darkturquoise: "#009EAA",
    solidarm_yellow: "#ffdf43"
};

export default COLORS;
