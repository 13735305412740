import React from "react";
import webui from "@tinqin/tinqin-web-ui";
import DateUtils from "@tinqin/tinqin-utils/src/date";

//returns "DD.MM.YY" format
function formatSpecial(input, separator) {
    const date = new Date(input);
    const year = date.getFullYear().toString();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const yearShort = year.slice(-2);
    let monthLong = month;
    if (month < 10) {
        monthLong = "0" + month;
    }
    let dayLong = day;
    if (day < 10) {
        dayLong = "0" + day;
    }
    return dayLong + separator + monthLong + separator + yearShort;
}

//component for outputing Article publish dates, no additional markup
function OutputPublishDate(props) {
    const data = props.data ? props.data.properties || {} : null;
    let result;
    if (!data) {
        return "";
    }
    const dataProps = webui.getUtils().extractComponentProps(props, {
        additionalProps: ["value", "format", "separator"],
        propsDefaults: {
            separator: "."
        }
    });
    if (!dataProps.format) {
        result = formatSpecial(dataProps.value, dataProps.separator);
    } else {
        result = DateUtils.simpleFormat(dataProps.value, dataProps.format);
    }
    return <time dateTime={dataProps.value}>{result}</time>;
}

export default webui.connectComponent()(OutputPublishDate);
