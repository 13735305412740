const config = {
    //Note: The place for your project level components' configuration.
    //the format is -> componentType: { property: value }
    dropdown: {
        popoverClasses: "un-dropdown-three-options",
        skipLabel: true
    }
};

export default config;
