import { TAppThunk } from "../../utils/commonTypes";
import { triggerMatomoEvents } from "../front-end/triggerMatomoEvents";
//On location change, check if the location is changed without loading new widgets (silent=true)
//if so, trigger matomo page view actions
//UCP-541 - when filtering new pages should be logged in matomo
export const onLocationUpdated = ({ sirenAction }): TAppThunk => async (_dispatch: any) => {
    if (sirenAction?.properties?.silent) {
        triggerMatomoEvents(window.document.title);
    }
};
