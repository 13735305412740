import React from "react";
import webui, { BaseComponent } from "@tinqin/tinqin-web-ui";
import Button from "./Button";

const extractComponentProps = webui.getUtils().extractComponentProps;
//Regular Button with richText and lading state (disabled)
export class ReduxButton extends BaseComponent {
    onClick() {
        this.dispatchSirenActions(["default", "click"]);
    }

    render() {
        if (this.props.data) {
            const props = extractComponentProps(this.props, {
                componentProps: Button.propTypes,
                additionalProps: ["richText"]
            });
            if (
                this.props.uiState &&
                (this.props.uiState.loading || this.props.uiState.loadingMore)
            ) {
                props.disabled = true;
                if (props.loadingClass) {
                    props.extraClasses += ` ${props.loadingClass}`;
                }
            }
            props.onClick = this.onClick.bind(this);
            return <Button {...props} />;
        }

        return this.getEmptyContent();
    }
}

ReduxButton.displayName = "ReduxButton"; //minification concern

export default webui.connectComponent({
    uiState: true,
    actions: { dispatchSirenActions: "siren.dispatchSirenActions" }
})(ReduxButton);
