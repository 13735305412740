import React from "react";
import webui, { BaseComponent } from "@tinqin/tinqin-web-ui";
import ResponsiveHOC from "./index";

//rendering children in regions ["XS", "S", "M", "L", "XL"]
//for different screen sizes
//has no "default" region
//uses region.sizes: [ "XS", "S", "M", "L", "XL"] array for displaying children on various screen sizes
// empty [] for region.sizes will display the component on all screens
class ResponsiveContainer extends BaseComponent {
    componentDidMount() {
        setTimeout(() => {
            this.dispatchSirenActions();
        }, 0);
    }
    componentDidUpdate() {
        setTimeout(() => {
            this.dispatchSirenActions();
        }, 0);
    }
    //Similar to BaseComponane.resolveRegions
    //but returns info for each resolved component and its region config
    //returns array of
    // {
    //     region:{},
    //     component:{}
    // }
    //works only for "children" and "components" config
    resolveChildrenRegions(options = {}) {
        const children = this.props.config.children || this.props.config.components;
        return children.map((childConfig, index) => ({
            region: childConfig.region || {},
            child: this.resolveChild(childConfig, index, options)
        }));
    }
    //searches through each component info
    //checking whether region.sizes contains current screen size or region.sizes === []
    //if no region.sizes provided, child will be rendered for all sizes
    resolveScreenSize = (componentsInfo, size) =>
        componentsInfo.reduce((acc, info) => {
            if (
                !info.region.sizes ||
                !info.region.sizes.length ||
                info.region.sizes.includes(size)
            ) {
                acc.push(info.child);
            }
            return acc;
        }, []);

    render() {
        const children = this.resolveChildrenRegions();
        return <>{this.resolveScreenSize(children, this.props.size)}</>;
    }
}

//using additional wrapper in order to take configuration of responsiveContainer
//and apply it to the ResponsiveHOC
function ResponsiveHOCWrapper(props) {
    const dataProps = webui.getUtils().extractComponentProps(props, {
        additionalProps: ["sizes"]
    });
    const sizesOptions = dataProps.sizes ? { sizes: dataProps.sizes } : undefined;
    const HOCComponent = ResponsiveHOC(ResponsiveContainer, sizesOptions);
    return <HOCComponent {...props}></HOCComponent>;
}

export default webui.connectComponent({
    actions: { dispatchSirenActions: "siren.dispatchSirenActions" }
})(ResponsiveHOCWrapper);
