import React, { useMemo } from "react";
import webui from "@tinqin/tinqin-web-ui";
import { xssFilter } from "../../utils/xssFilter";

const numberTypesMap = {
    free: {
        imgSrc: "/images/phone-no-tariff.png",
        alt: "free number",
        className: "-free"
    },
    paid: {
        imgSrc: "",
        alt: "paid number",
        className: "-tariff"
    },
    standard: {
        imgSrc: "/images/phone-tariff-gray.png",
        alt: "standard number",
        className: "-gray"
    }
};
//depending on the tariff we have different images
const paidNumberImages = {
    "0.15": "/images/phone-tariff.png",
    "0.12": "/images/phone-tariff-2.png"
};
export interface ISpecialPhoneProps {
    description?: string;
    numberValue: string;
    numberType: string;
    numberTariff?: string;
    onClick: React.MouseEventHandler<HTMLDivElement>;
}

const SpecialPhone: React.FC<ISpecialPhoneProps> = props => {
    const xssDescription = useMemo(() => {
        return xssFilter.process(props?.description);
    }, [props.description]);

    if (!props.numberValue || !props.numberType) {
        return webui.getUtils().getEmptyContent();
    }

    const numberTypeProps: {
        imgSrc: string;
        imgAlt: string;
        className: string;
    } = { ...numberTypesMap[props.numberType] };

    if (props.numberTariff) {
        numberTypeProps.imgSrc = paidNumberImages[props.numberTariff];
    }

    return (
        <div className="un-services-right un-phone-service">
            {props.numberValue && (
                <div
                    className={`un-phone-special un-phone-special${numberTypeProps?.className ||
                        ""}`}
                    onClick={props.onClick}
                >
                    <a className="un-services-phone" href={`tel:${props.numberValue}`}>
                        <p className="un-phone-number">{props.numberValue}</p>
                    </a>
                    {numberTypeProps?.imgSrc && (
                        <img src={numberTypeProps.imgSrc} alt={numberTypeProps?.imgAlt} />
                    )}
                </div>
            )}
            {xssDescription && (
                <div
                    className="un-services-additional-text"
                    dangerouslySetInnerHTML={{
                        __html: xssDescription
                    }}
                    data-hyperlinks-container={true}
                />
            )}
        </div>
    );
};

export default SpecialPhone;
