import webui from "@tinqin/tinqin-web-ui";
import EntityBuilder from "@tinqin/tinqin-web-ui/src/utils/EntityBuilder";
const { addEntity, getEntity, setEntityChildren } = webui.getActionByName("utils");

const VIDEO_ENTITY = "video";

/**
 * This action extracts the data from "question info" and set it in a Modal
 */
export const buildVideoModal = (
    sirenAction,
    _widgetId,
    currentPath
) => async dispatch => {
    const target = sirenAction.targets[0];
    const actionProps = sirenAction?.properties || {};
    //if the action is called from the old Homepage
    let videoPath = `${currentPath}.${VIDEO_ENTITY}`;

     //if the action is called from the new Homepage
     //if "directPath" property exists, search the video on that path
    if(currentPath.endsWith(VIDEO_ENTITY) || actionProps?.directPath){
        videoPath = `${currentPath}`;
    }
    const videoData = dispatch(getEntity(`${videoPath}`));
    if(sirenAction?.properties?.matomoVideoSection) {
        videoData.properties.matomoVideoSection = sirenAction?.properties?.matomoVideoSection;
    }
    videoData.class = ["video"];
    if (!dispatch(getEntity(`${target}.content`))) {
        if (videoData) {
            const body =  EntityBuilder.of({ class: ["content"], entities: [videoData] });
            await dispatch(addEntity(target, body.toSIREN()));
        }
    } else {
        if (videoData) {
            await dispatch(setEntityChildren(`${target}.content`, [videoData]));
        }
    }
};
