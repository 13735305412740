import React from "react";
import webui from "@tinqin/tinqin-web-ui";
import Grid from "./Grid";
import Row from "./Row";
import CommonUtils from "@tinqin/tinqin-utils/src/common";
import SmartCol from "@tinqin/tinqin-web-ui/src/components/grid/SmartColumn";

export const GridLayout = props => {
    const buildGridChildren = ({ rowTag }) => {
        const childrenConfig = props.config.children;

        //Grid can build children separately (using config.children)
        //or like a collection, using config.item and data.entities
        if (childrenConfig) {
            return buildGridRowsFromConfig({ rowTag });
        } else {
            return buildGridRowsFromData({ rowTag });
        }
    };

    const buildGridRowsFromConfig = ({ rowTag }) => {
        let numberOfRows = 0;
        const JSX_rows = [];
        const parentBindTo = webui.getUtils().resolveBinding(props);
        const sortedChildren = CommonUtils.orderBy(
            //elements with missing region property will be last
            props.config.children,
            ["region.row", "region.col"],
            ["asc", "asc"]
        );
        //children/items may not have row defined, or even region! => we make sure they have!
        let previousRowIndex = 1;
        sortedChildren.forEach(child => {
            if (!child.region) {
                child.region = { row: previousRowIndex };
            } else if (!child.region.row) {
                child.region.row = previousRowIndex;
            } else {
                previousRowIndex = child.region.row;
            }
        });
        if (sortedChildren.length) {
            numberOfRows = previousRowIndex;
        }
        for (let currentRow = 1; currentRow <= numberOfRows; currentRow++) {
            const JSX_columns = [];
            const columns = sortedChildren.filter(child => {
                return Number(child.region.row) === currentRow;
            });
            columns.forEach((column, index) => {
                const smartColProps = {
                    config: column,
                    key: index,
                    index: index,
                    widgetId: props.widgetId,
                    parentBindTo: parentBindTo
                };

                JSX_columns.push(<SmartCol {...smartColProps} />);
            });
            JSX_rows.push(
                <Row key={currentRow} tag={rowTag}>
                    {JSX_columns}
                </Row>
            );
        }

        return JSX_rows;
    };

    const buildGridRowsFromData = ({ rowTag }) => {
        const itemConfig = props.config.item;
        const data = props.data;
        let JSX_columns = [];

        if (!itemConfig || !data || !data.entities) {
            return JSX_columns;
        }

        const parentBindTo = webui.getUtils().resolveBinding(props);

        //creating array of item configurations
        JSX_columns = data.entities.map((item, index) => {
            const itemClassifier = item.class ? item.class[0] : "";
            const smartColProps = {
                config: itemConfig,
                key: index,
                index: index,
                widgetId: props.widgetId,
                itemClassifier: itemClassifier,
                parentBindTo: parentBindTo
            };
            return <SmartCol {...smartColProps} />;
        });

        return (
            <Row key={0} tag={rowTag}>
                {JSX_columns}
            </Row>
        );
    };

    const gridProps = webui.getUtils().extractComponentProps(props, {
        additionalProps: ["extraClasses", "dataTqId", "tag", "rowTag"],
        propsDefaults: {
            extraClasses: "",
            skipDataTqId: true,
            tag: "div",
            rowTag: "div"
        }
    });

    return <Grid {...gridProps}>{buildGridChildren({ rowTag: gridProps.rowTag })}</Grid>;
};

export default webui.connectComponent()(GridLayout);
