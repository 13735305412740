import React, { useEffect, useMemo, useRef } from "react";
import webui from "@tinqin/tinqin-web-ui";
import { traverseAndApplyUnderlineColor } from "../../utils/InlineStyles";
import { IComponentProps } from "../../utils/commonTypes";
import { xssFilter } from "../../utils/xssFilter";

export const RichText: React.FC<IComponentProps> = props => {
    const content = useRef();
    useEffect(() => {
        const node = content.current;
        if (node) {
            traverseAndApplyUnderlineColor(node);
        }
    }, [content]);
    const {
        tag,
        value,
        label,
        extraClasses
    }: {
        tag?: string;
        extraClasses?: string;
        value?: string;
        label?: string;
    } = webui.getUtils().extractComponentProps(props, {
        additionalProps: ["tag", "value", "label", "extraClasses"],
        aliases: {
            value: "text"
        },
        propsDefaults: {
            tag: "div",
            extraClasses: ""
        }
    });
    const text = value ?? label;
    const innerHTML = useMemo(() => xssFilter.process(text), [text]);
    if (!props.data || !value) {
        return null;
    }
    return React.createElement(tag, {
        className: extraClasses,
        dangerouslySetInnerHTML: {
            __html: innerHTML
        },
        ref: content,
        "data-hyperlinks-container": true
    });
};

export default webui.connectComponent()(RichText);
