export default function request(
    { method, url = "", body = {}, headers = {}, jsonFlag = true } = {
        method: "GET",
        url: "/",
        jsonFlag: true,
        body: {}
    }
) {
    return new Promise((resolve, reject) => {
        // Set up our HTTP request
        const xhr = new XMLHttpRequest();
        let requestContents = body;
        // Setup our listener to process compeleted requests
        xhr.onreadystatechange = function() {
            // Only run if the request is complete
            if (xhr.readyState !== 4) return;
            // Process our return data
            if (xhr.status >= 200 && xhr.status < 300) {
                if (xhr.response) {
                    try {
                        let response = xhr.response;
                        if (jsonFlag) {
                            response = JSON.parse(xhr.response) || {};
                        }
                        resolve(response);
                    } catch (parsingJSONError) {
                        reject(parsingJSONError);
                    }
                }
                reject(xhr.response);
            } else {
                // What to do when the request has failed
                reject(xhr.response);
            }
        };
        // Create and send a GET request
        // The first argument is the post type (GET, POST, PUT, DELETE, etc.)
        // The second argument is the endpoint URL
        xhr.open(method, url);
        if (jsonFlag && !headers["Content-Type"]) {
            if (!headers["Content-Type"]) {
                xhr.setRequestHeader("Content-Type", "application/json");
            }
            requestContents = JSON.stringify(body);
        }
        //requestContents will be ignored for "GET" requests
        xhr.send(requestContents);
    });
}
