import { defaultProps } from "./defaultProps";

export default class Animation {
    constructor(props) {
        this.props = props;
    }

    computeAnimateTransition(slideIndex, contentStyle) {
        const style = this.computeTransition(slideIndex, contentStyle);
        // Add inline animation transition CSS to slides container
        style.transition = defaultProps.transition;

        return style;
    }

    computeTransition(slideIndex, contentStyle) {
        const { slideOffset, slideSizePercent } = this.calculateSlideOffset(slideIndex);

        const transition = slideIndex * slideSizePercent * -1 + slideOffset;
        const style = Object.assign({}, contentStyle);

        // Add inline transition CSS to slides container
        const slideDirection = this.props.verticalSlideDirection ? "translateY" : "translateX";
        style.transform = `${slideDirection}(${transition}%)`;
        style.transition = defaultProps.transitionNone;

        return style;
    }

    calculateSlideOffset(slideIndex) {
        let slideOffset = 0;

        const visibleSlides = this.props.visibleSlides || defaultProps.visibleSlides;
        const slidesToScroll = this.props.slidesToScroll || defaultProps.slidesToScroll;

        const slidesCount = this.props.children.length;
        const slideSizePercent = 100 / (slidesCount + 2 * visibleSlides);

        if (this.isEndlessRotation()) {
            if (slidesCount >= visibleSlides) {
                slideOffset = slideSizePercent * visibleSlides * -1;
            }
            if ((slideIndex < 0 || slideIndex >= slidesCount) && slidesToScroll > 1) {
                if (slideIndex >= slidesCount) {
                    slideOffset =
                        (visibleSlides - (slideIndex - slidesCount)) * slideSizePercent * -1;
                } else {
                    slideOffset = (visibleSlides - 1 + slideIndex * -1) * slideSizePercent * -1;
                }
            }
        } else {
            if (slideIndex >= slidesCount) {
                slideOffset = (visibleSlides - (slideIndex - slidesCount)) * slideSizePercent * -1;
            }
        }

        return { slideOffset, slideSizePercent };
    }

    isEndlessRotation() {
        if (typeof this.props.endlessRotation === "boolean") {
            return this.props.endlessRotation;
        }

        return defaultProps.endlessRotation;
    }
}
