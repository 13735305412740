/**
 * Created by m.igrachev on 2/10/2016.
 */
import React from "react";
import ReactDom from "react-dom";
import PropTypes from "prop-types";
import Validatable from "@tinqin/tinqin-ui-components/src/validatable/Validatable";
import Labeled from "@tinqin/tinqin-ui-components/src/label/Labeled";
import { deepPropsEqual } from "@tinqin/tinqin-ui-components/src/input/deepPropsEqual";
import ObjectUtils from "@tinqin/tinqin-utils/src/object";
import documentedProps from "@tinqin/tinqin-ui-components/src/input/documentation/InputDocumentedProps";
import fetchLabeledProps from "@tinqin/tinqin-ui-components/src/label/fetchLabeledProps";

export default class BasicInput extends Validatable {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value || "",
            valid: props.valid,
            failedValidations: props.failedValidations || {}
        };

        this.onChange = this.onChange.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onPaste = this.onPaste.bind(this);
        this.handleRef = this.handleRef.bind(this);
    }

    componentWillReceiveProps(newProps) {
        const toSet = {};
        //test case: the failed validation will come from the container on BE update
        //but if BE has changed the value, their responsibility is to make the component in invalid state
        if (newProps.value !== undefined) {
            if (newProps.value !== this.state.value) {
                toSet.value = newProps.value;
                toSet.failedValidations = {};
            }
        } else {
            toSet.value = "";
            if (newProps.valid !== false) {
                toSet.failedValidations = {};
            }
        }

        if (newProps.valid !== this.state.valid) {
            toSet.valid = newProps.valid;
        }

        if (toSet.value && newProps.valid === this.state.valid) {
            toSet.valid = undefined;
        }

        if (!this.validationsEqual(newProps.failedValidations, this.state.failedValidations)) {
            toSet.failedValidations = newProps.failedValidations;
        }

        if (!ObjectUtils.isEmpty(toSet)) {
            this.setState(toSet);
        }
    }

    componentWillUpdate() {
        if (this.props.saveCursorPos) {
            const inputContainer = ReactDom.findDOMNode(this);
            //TODO concern React's refs after we understand the correct way of using.
            const inputContent = inputContainer.querySelector(".tq-input-container .tq-input");
            if (inputContent && inputContent.value) {
                this.selectionLength = inputContent.value.length;
                this.selectionStart = inputContent.selectionStart;
            }
        }
    }

    componentDidUpdate() {
        if (this.props.saveCursorPos && !isNaN(this.selectionStart)) {
            const inputContainer = ReactDom.findDOMNode(this);
            //TODO concern React's refs after we understand the correct way of using.
            const inputContent = inputContainer.querySelector(".tq-input-container .tq-input");
            if (inputContent && inputContent.value) {
                const newIdx = Math.max(
                    0,
                    inputContent.value.length - this.selectionLength + this.selectionStart
                );
                inputContent.selectionStart = inputContent.selectionEnd = newIdx;
            }
        }
    }

    onChange(event) {
        const newValue = event.currentTarget.value;
        let stateToSet = { value: newValue };
        const validationOptions = { validationType: "soft" }; //Performing soft validation on change!
        if (this.props.locales) {
            validationOptions.locales = this.props.locales;
        }

        if (
            this.props.phoneNumberInputValidationSchema &&
            !this.props.phoneNumberInputValidationSchema.test(newValue)
        ) {
            event.preventDefault();
            return;
        }

        if (newValue !== "") {
            this.props.upperCase && (stateToSet["value"] = newValue.toUpperCase());
            //Perform front-end validation!
            const feValidationResult = this.performFrontEndValidation(newValue, validationOptions);
            if (this.props.validateOnChange) {
                Object.assign(stateToSet, feValidationResult);
            } else if (this.state.value === "") {
                //Manage the case of required failed field after new input!
                stateToSet.valid = undefined;
                stateToSet.failedValidations = {};
            }
            //Manage preventInvalidInput!
            if (this.props.preventInvalidInput && !feValidationResult.valid) {
                stateToSet = {};
            }
        } else {
            //Perform required validation!
            const requiredValidationResult = this.performRequiredValidation(
                newValue,
                validationOptions
            );
            Object.assign(stateToSet, requiredValidationResult); //Required always happens on change!
        }

        if (!ObjectUtils.isEmpty(stateToSet)) {
            //Respect preventInvalidInput
            this.setState(stateToSet, () => {
                //Inform consumer
                if (this.props.onChange) {
                    this.props.onChange(this.state);
                }
            });
        }
    }

    onKeyPress(event) {
        const newValue = event.currentTarget.value;
        const newState = {};
        newState.value = newValue;
        //!NB we do not set state here, because onChange will do that. onChange is always triggered when the value is changed!

        if (this.props.onKeyPress) {
            this.props.onKeyPress(event.key, Object.assign({}, this.state, newState), event);
        }
    }

    onBlur(event) {
        const value = this.state.value;
        const stateToSet = {};
        const validationOptions = {};
        if (this.props.locales) {
            validationOptions.locales = this.props.locales;
        }

        if (!this.props.validateOnChange && value !== "") {
            //Ignore "", since required validation always happens on change.
            const feValidationResult = this.performFrontEndValidation(value, validationOptions);
            Object.assign(stateToSet, feValidationResult);
        } else if (value === "") {
            //Catch the case where change have not been done and required validation as well.
            //We want to mark as required when blurring out of required input without value.
            const requiredValidationResult = this.performRequiredValidation(
                value,
                validationOptions
            );
            Object.assign(stateToSet, requiredValidationResult);
        }

        //Change the state only if needed
        let setStateNeeded = false;
        Object.keys(stateToSet).forEach(prop => {
            if (!deepPropsEqual(stateToSet[prop], this.state[prop])) {
                setStateNeeded = true;
            }
        });
        if (setStateNeeded) {
            this.setState(stateToSet, () => {
                //Inform consumer
                super.onBlur(event);
            });
        } else {
            super.onBlur(event);
        }
    }

    onPaste(event) {
        // We want to prevent the user for pasting in the input filed,
        // if the preventPaste property is set to true.
        // In addition we notify the user with a hint message,
        // that he is not allowed to use paste for this field.
        if (this.props.preventPaste) {
            event.preventDefault();
        }
    }

    handleRef(ref) {
        const refProp = this.props.inputRef;
        if (refProp) {
            if (typeof refProp === "function") {
                refProp(ref);
            } else {
                refProp.current = ref;
            }
        }
    }

    render() {
        //Deal with error message to be displayed and/or hint.
        let explicitHint;
        const errorToDisplay = this.retrieveValidationMessages();
        const isHintDiscrete = this.props.discreteHint !== false; //discrete by default.
        if (!isHintDiscrete && this.props.hint) {
            explicitHint = <div className="tq-help-text">{this.props.hint}</div>;
        }
        // We use the valueAlignment property to determine if the value of the input should be aligned
        // left(default), center or right.
        let inputClass = "tq-input";
        if (this.props.valueAlignment) {
            inputClass += " tq-text-" + this.props.valueAlignment;
        }
        //Form label props
        const labelProps = fetchLabeledProps(this.props, this.state, {
            valueContainerClass: "tq-text-input",
            hasFeedback: !!errorToDisplay
        });

        const inputType = this.props.password ? "password" : "text";
        const valueToDisplay = this.props.password ? undefined : this.state.value;
        return (
            <Labeled {...labelProps}>
                <input
                    type={inputType}
                    onFocus={this.onFocus}
                    onChange={this.onChange}
                    onPaste={this.onPaste}
                    maxLength={this.props.maxCharacters}
                    onBlur={this.onBlur}
                    onKeyDown={this.onKeyPress}
                    className={inputClass}
                    value={valueToDisplay}
                    placeholder={this.props.placeholder}
                    disabled={this.props.disabled}
                    ref={this.handleRef}
                />
                <div className="tq-feedback-container">{errorToDisplay}</div>
                {explicitHint}
            </Labeled>
        );
    }
}

BasicInput.displayName = "Input";

BasicInput.propTypes = Object.assign({}, Validatable.propTypes, Labeled.propTypes, {
    extraClasses: PropTypes.string,
    password: PropTypes.bool,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    valueAlignment: PropTypes.oneOf(["left", "center", "right"]),
    maxCharacters: PropTypes.number,
    onChange: PropTypes.func,
    onKeyPress: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    validateOnChange: PropTypes.bool,
    preventInvalidInput: PropTypes.bool,
    locales: PropTypes.array,
    saveCursorPos: PropTypes.bool,
    preventPaste: PropTypes.bool,
    upperCase: PropTypes.bool,
    inputRef: PropTypes.oneOf([PropTypes.func, PropTypes.object])
});

BasicInput.documentedProps = documentedProps;
