import React from "react";
import webui, { BaseComponent } from "@tinqin/tinqin-web-ui";
import EntityBuilder from "@tinqin/tinqin-web-ui/src/utils/EntityBuilder";
import { xssFilter } from "../../utils/xssFilter";

export class ArticleAidBox extends BaseComponent {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    static parseDescription(description) {
        if (description.includes("</p>")) {
            return description.substr(0, description.indexOf("</p>") + 4);
        }
        return description;
    }

    extractProps() {
        const data = this.props.data || {};
        const siren = EntityBuilder.of(data);
        const selected = !!siren.getProperty("selected");
        let title = siren.getChild("title");
        title = title.getValue();
        let description = siren.getChild("description");
        description = ArticleAidBox.parseDescription(description.getValue());
        let image = siren.getChild("image");
        const src = image.getProperty("src");
        const alt = image.getProperty("alt");
        image = {
            src,
            alt
        };

        return { title, description, selected, image };
    }

    onClick() {
        this.dispatchSirenActions(["default", "click"]);
    }

    isClickable() {
        const data = this.props.data || {};
        const actions = data.actions || [];
        return !!actions.length;
    }

    render() {
        if (!this.props.data) {
            return this.getEmptyContent();
        }
        const props = webui.getUtils().extractComponentProps(this.props, {
            additionalProps: ["boxClass"],
            propsDefaults: {
                boxClass: true
            }
        });
        const { title, description, selected, image } = this.extractProps();
        const clickableClass = this.isClickable() ? " tq-clickable" : "";
        const themeClass = this.props.setBackground ? " un-theme-box-img" : " un-theme-box-color";
        let containerClass = themeClass + clickableClass;
        if (selected) {
            containerClass = `${containerClass} un-theme-box-current tq-clickable`;
        }
        if (props.boxClass) {
            containerClass += " un-theme-box";
        }
        const bgStyle = {
            backgroundImage: `url("${image.src}")`
        };
        return (
            <div className={containerClass} onClick={this.onClick}>
                {this.props.setBackground && (
                    <div
                        className={this.props.bgExtraClasses}
                        style={bgStyle}
                        onClick={this.onClick}
                    />
                )}
                <div className={"un-flex-container-content"}>
                    <h3 className="tq-title tq-neutral-color-6">
                        <span
                            className="tq-text-node"
                            dangerouslySetInnerHTML={{
                                __html: xssFilter.process(title)
                            }}
                            data-hyperlinks-container={true}
                        />
                    </h3>
                    <div
                        className="tq-neutral-color-6"
                        dangerouslySetInnerHTML={{
                            __html: xssFilter.process(description)
                        }}
                        data-hyperlinks-container={true}
                    />
                </div>
            </div>
        );
    }
}

export default webui.connectComponent({
    actions: {
        dispatchSirenActions: "siren.dispatchSirenActions"
    }
})(ArticleAidBox);
