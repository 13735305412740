import React from "react";
import ReactDOM from "react-dom";
import WebUI, { WebUIRoot } from "@tinqin/tinqin-web-ui";
//Project level code (React components, Redux actions/reducers, etc).
import commonModule from "./modules/common";
import webConfig from "../web.config";
import staticConfigLoader from "./api/staticConfigLoader";
//Temporary script for version CP 1.2.0
import clearCookie from "./modules/common/utils/clearCookie";
import TarteaucitronLoaderComponent from "./tarteaucitron/TarteaucitronLoaderComponent";
import LinkedInInsight from "./modules/common/components/LinkedIn/LinkedInInsight.tsx";
import MetaPixel from "./modules/common/components/MetaPixel/MetaPixel.tsx";
import FacilitiLoader from "./modules/faciliti/components/FacilitiLoader";
//conditional logging
if (process.env.BUILD_MODE !== "local") {
    WebUI.stopLogging();
}
//Manage scroll position on switchDashboard manually
const skipDefaultScrollPosition = true;
if (skipDefaultScrollPosition) {
    window.history.scrollRestoration = "manual";
}
//workaround for OAV1 support
const transformWebuiMethod = name => {
    const webuiCurrentMethod = WebUI[name];
    WebUI[name] = (item, contextInfo) => {
        if (typeof contextInfo === "string") {
            webuiCurrentMethod(item, { contextId: contextInfo });
        } else {
            webuiCurrentMethod(item, contextInfo);
        }
    };
};
//Temporary script added to clear Google Analytics cookies
//Added in version 1.2.0 - to remove in some next prod version
const cookiesToClear = ["__utma", "__utmb", "__utmc", "__utmt", "__utmz", "_ga", "_gid"];
cookiesToClear.forEach(cookie => {
    clearCookie(cookie, window.location.hostname, "/");
});

transformWebuiMethod("setStaticConfigLoader");
transformWebuiMethod("setSerializeMethod");
transformWebuiMethod("addComponents");

//Set WebUI initial locale. Let WebUI know what is your default app locale.
WebUI.changeLocale("fr-FR");
//Import custom project level components.
WebUI.addComponents(commonModule.components);
//Import custom project level actions.
WebUI.addActions(commonModule.actions);
WebUI.addSirenActionsMapping(commonModule.sirenActionsMapping);
//Import custom project level reducers.
WebUI.addReducers(commonModule.reducers);
//Import custom project level Dashboard templates.
WebUI.addDashboardTemplates(commonModule.templates);
//Set default project level component configurations
WebUI.setComponentsDefaultConfig(commonModule.componentsConfig);
//Import custom project level web configuration.
WebUI.setWebConfig(webConfig);
//Some global properties altering application behaviour
WebUI.setGlobalProperties({
    dashboardPathname: "pathParams",
    skipAppBoot: true,
    initialDashboard: "home",
    applicationLoadingAnimation: false,
    skipDefaultScrollPosition: skipDefaultScrollPosition
});
WebUI.setStaticConfigLoader(staticConfigLoader);

const RootComponent = WebUIRoot;
const start = () => {
    //wait for global settings to be loaded before rendering
    if (window.globalSettings) {
        WebUI.setGlobalProperties({
            applicationContext: window.globalSettings,
            widgetLoadingAnimation: true,
            preMadeRequests: window.preMadeRequests
        });
        const contexts = window.globalSettings.contexts || {};
        Object.keys(contexts).forEach(key => {
            WebUI.createContext(key, contexts[key]);
        });
        //global properties to be saved at initial store data
        WebUI.setInitialStoreGlobalProperties({
            stickyFooterClosed: true
        });
        //Rendering WebUIRoot
        ReactDOM.render(
            <>
                <RootComponent />
                <TarteaucitronLoaderComponent />
                <LinkedInInsight />
                <MetaPixel />
                <FacilitiLoader />
            </>,
            document.getElementById("root")
        );
    } else {
        WebUI.log("waiting for globalSettings...");
        setTimeout(start, 200);
    }
};
if (/Trident\/|MSIE/.test(window.navigator.userAgent) === false) {
    start();
}
