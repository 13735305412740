import React from "react";
import webui, { BaseComponent } from "@tinqin/tinqin-web-ui";

//dropdown with fullscreen menu
//hiding dropdown arrow when an option that is not "0" is selected
class FullScreenDropdown extends BaseComponent {
    state = { expanded: false, currentSelectionId: [] };
    expand = () => {
        const body = document.querySelector("body");
        if (!body.classList.contains("un-burger-open")) {
            body.classList.add("un-burger-open");
            this.setState({ expanded: true, insideBurger: false });
        } else {
            this.setState({ expanded: true, insideBurger: true });
        }
    };
    collapse = () => {
        this.setState({ expanded: false });
        const body = document.querySelector("body");
        if (!this.state.insideBurger) {
            if (body.classList.contains("un-burger-open")) {
                body.classList.value = body.classList.value.replace("un-burger-open", "");
            }
        }
    };
    getSelectedLabel = (id, entities = []) => {
        const found = entities.find(entity => {
            return entity.properties ? entity.properties.id === id : false;
        });
        return found ? (found.properties.label ? found.properties.label : []) : [];
    };
    selectValue = id => {
        //Propagate the news to the store.
        if (this.state.currentSelectionId !== id) {
            this.saveState(id);
            this.setState({ currentSelectionId: id });
        } else {
            this.saveState([]);
            this.setState({ currentSelectionId: [] });
        }
        this.dispatchSirenActions(["change"]);
        this.collapse();
    };
    buildItems = entities =>
        entities.map((entity, index) => {
            return (
                <div
                    id={entity.properties.id}
                    key={entity.properties.id || index}
                    className={`un-dropdown-fullscreen-item ${
                        entity.properties.id === this.state.currentSelectionId ? "tq-selected" : ""
                    }`}
                    onClick={() => this.selectValue(entity.properties.id)}
                >
                    {entity.properties.label}
                </div>
            );
        });

    render() {
        if (this.props.config.bindTo) {
            const data = this.props.data ? this.props.data.properties || {} : null;
            if (!data) {
                return this.getEmptyContent();
            }
        }

        const dataProps = webui.getUtils().extractComponentProps(this.props, {
            additionalProps: [
                "value",
                "extraClasses",
                "dropdownExtraClasses",
                "selectionPrefix",
                "placeholder"
            ],
            propsDefaults: {
                extraClasses: "",
                dropdownExtraClasses: "",
                placeholder: ""
            }
        });
        const entities = this.props.data.entities || [];
        const currentLabel = this.getSelectedLabel(dataProps.value, entities);
        const hiddenStyle = {
            display: "none"
        };
        let selectedOptionClass = " un-panel-sticky-selected";
        if (!dataProps.value || dataProps.value === "0") {
            selectedOptionClass = "";
        }
        let currentLabelWithPrefix = "";
        if (dataProps.selectionPrefix) {
            currentLabelWithPrefix += dataProps.selectionPrefix;
        }
        if (currentLabel && dataProps.selectionPrefix) {
            currentLabelWithPrefix += " " + currentLabel;
        } else if (currentLabel) {
            currentLabelWithPrefix += currentLabel;
        }

        if (currentLabel && currentLabel.length === 0) {
            currentLabelWithPrefix = dataProps.placeholder;
        }
        return (
            <div
                className={
                    "tq-form-element tq-vertical tq-no-label " +
                    dataProps.extraClasses +
                    selectedOptionClass
                }
                data-tq-id="header.businessLineSelection"
            >
                <div className="tq-input-container tq-select" onClick={this.expand}>
                    <div className="tq-drop-down-selected tq-active">
                        <span className="tq-selected-item">{currentLabelWithPrefix}</span>
                        <div className="tq-feedback-container">
                            <div className="tq-input-feedback tq-select-trigger">
                                <i className="tq-icon-arrow-down-big" />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    style={this.state.expanded ? {} : hiddenStyle}
                    className={"un-dropdown-fullscreen " + dataProps.dropdownExtraClasses}
                >
                    <div className="un-dropdown-close" onClick={this.collapse}>
                        <span className="tq-text-node">{currentLabelWithPrefix}</span>
                        <img src="/fonts/dropdown-arrow.svg" alt="dropdown arrow icon" />
                    </div>
                    <div className="un-dropdown-fullscreen-elements">
                        {this.buildItems(entities)}
                    </div>
                </div>
            </div>
        );
    }
}

export default webui.connectComponent({
    actions: {
        dispatchSirenActions: "siren.dispatchSirenActions",
        saveState: "component.saveState"
    }
})(FullScreenDropdown);
