// import DefaultTemplate from "./Default";
// import CorporateTemplate from "./Corporate";
// import HomeGeneralPublic from "./HomeGeneralPublic";
// import NoFooterMarginCorp from "./NoFooterMarginCorp";
import OneTemplate from "./OneTemplate";

export default {
    DefaultTemplate: OneTemplate,
    CorporateTemplate: OneTemplate,
    HomeGeneralPublic: OneTemplate,
    HomeCommunityPlatform: OneTemplate,
    NoFooterMarginCorp: OneTemplate,
    NoFooterMargin: OneTemplate
};
