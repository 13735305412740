import webui from "@tinqin/tinqin-web-ui";

const getWidgetConfig = async ({ id = "", defaultRequestOptions = {} } = {}) => {
    let module;
    switch (id) {
        case "widget-header":
            module = await import(
                /* webpackChunkName: "widget.widget-header" */ `./widget-header/index.js`
            );
            return module.getConfig();
        case "widget-header-pf":
            module = await import(
                /* webpackChunkName: "widget.widget-header-pf" */ `./widget-header-pf/index.js`
            );
            return module.getConfig();
        case "widget-404":
            module = await import(
                /* webpackChunkName: "widget.widget-404" */ `./widget-404/index.js`
            );
            return module.getConfig();
        case "widget-article-event":
            module = await import(
                /* webpackChunkName: "widget.widget-article-event" */ `./widget-article-event/index.js`
            );
            return module.getConfig();
        case "widget-article-folder":
            module = await import(
                /* webpackChunkName: "widget.widget-article-folder" */ `./widget-article-folder/index.js`
            );
            return module.getConfig();
        case "widget-article-promotion":
            module = await import(
                /* webpackChunkName: "widget.widget-article-promotion" */ `./widget-article-promotion/index.js`
            );
            return module.getConfig();
        case "widget-contact":
            module = await import(
                /* webpackChunkName: "widget.widget-contacts" */ `./widget-contacts/index.js`
            );
            return module.getConfig();
        case "widget-documents":
            module = await import(
                /* webpackChunkName: "widget.widget-documents" */ `./widget-documents/index.js`
            );
            return module.getConfig();
        case "widget-example-reimbursement":
            module = await import(
                /* webpackChunkName: "widget.widget-example-reimbursement" */ `./widget-example-reimbursement/index.js`
            );
            return module.getConfig();
        case "widget-faq":
            module = await import(
                /* webpackChunkName: "widget.widget-faq" */ `./widget-faq/index.js`
            );
            return module.getConfig();
        case "widget-header-corp":
            module = await import(
                /* webpackChunkName: "widget.widget-header-corp" */ `./widget-header-corp/index.js`
            );
            return module.getConfig();
        case "widget-homepage":
            module = await import(
                /* webpackChunkName: "widget.widget-homepage" */ `./widget-homepage/index.js`
            );
            return module.getConfig();
        case "widget-home-corporate":
            module = await import(
                /* webpackChunkName: "widget.widget-home-corporate" */ `./widget-home-corporate/index.js`
            );
            return module.getConfig();
        case "widget-info-article":
            module = await import(
                /* webpackChunkName: "widget.widget-info-article" */ `./widget-info-article/index.js`
            );
            return module.getConfig();
        case "widget-landing-experts":
            module = await import(
                /* webpackChunkName: "widget.widget-landing-experts" */ `./widget-landing-experts/index.js`
            );
            return module.getConfig();
        case "widget-landing-gallery-corp":
            module = await import(
                /* webpackChunkName: "widget.widget-landing-gallery-corp" */ `./widget-landing-gallery-corp/index.js`
            );
            return module.getConfig();
        case "widget-landing-gallery-bottom":
            module = await import(
                /* webpackChunkName: "widget.widget-landing-gallery-bottom" */ `./widget-landing-gallery-bottom/index.js`
            );
            return module.getConfig();
        case "widget-landing-offer":
            module = await import(
                /* webpackChunkName: "widget.widget-landing-offer" */ `./widget-landing-offer/index.js`
            );
            return module.getConfig();
        case "widget-landing-profile":
            module = await import(
                /* webpackChunkName: "widget.widget-landing-profile" */ `./widget-landing-profile/index.js`
            );
            return module.getConfig();
        case "widget-landing-theme":
            module = await import(
                /* webpackChunkName: "widget.widget-landing-theme" */ `./widget-landing-theme/index.js`
            );
            return module.getConfig();
        case "widget-legal":
            module = await import(
                /* webpackChunkName: "widget.widget-legal" */ `./widget-legal/index.js`
            );
            return module.getConfig();
        case "widget-mutual-aid":
            module = await import(
                /* webpackChunkName: "widget.widget-mutual-aid" */ `./widget-mutual-aid/index.js`
            );
            return module.getConfig();
        case "widget-mutual-aid-corp":
            module = await import(
                /* webpackChunkName: "widget.widget-mutual-aid-corp" */ `./widget-mutual-aid-corp/index.js`
            );
            return module.getConfig();
        case "widget-offer-article":
            module = await import(
                /* webpackChunkName: "widget.widget-offer-article" */ `./widget-offer-article/index.js`
            );
            return module.getConfig();
        case "widget-article-marketplace-offer":
            module = await import(
                /* webpackChunkName: "widget.widget-article-marketplace-offer" */ `./widget-article-marketplace-offer/index.js`
            );
            return module.getConfig();
        case "widget-folder-moments-of-life":
            module = await import(
                /* webpackChunkName: "widget.widget-folder-moments-of-life" */ `./widget-folder-moments-of-life/index.js`
            );
            return module.getConfig();
        case "widget-catalog-moments-of-life":
            module = await import(
                /* webpackChunkName: "widget.widget-catalog-moments-of-life" */ `./widget-catalog-moments-of-life/index.js`
            );
            return module.getConfig();
        case "widget-quote":
            module = await import(
                /* webpackChunkName: "widget.widget-quote" */ `./widget-quote/index.js`
            );
            return module.getConfig();
        case "widget-reservist-course":
            module = await import(
                /* webpackChunkName: "widget.widget-reservist-course" */ `./widget-reservist-course/index.js`
            );
            return module.getConfig();
        case "widget-search":
            module = await import(
                /* webpackChunkName: "widget.widget-search" */ `./widget-search/index.js`
            );
            return module.getConfig();
        case "widget-refund":
            module = await import(
                /* webpackChunkName: "widget.widget-search" */ `./widget-refund/index.js`
            );
            return module.getConfig();
        case "widget-oav-container":
            module = await import(
                /* webpackChunkName: "widget.widget-oav-container" */ `./widget-oav-container/index.js`
            );
            return module.getConfig();
        case "widget-landing-marketplace-offers":
            module = await import(
                /* webpackChunkName: "widget.widget-landing-marketplace-offers" */ `./widget-landing-marketplace-offers/index.js`
            );
            return module.getConfig();
        case "widget-diagnostics":
            module = await import(
                /* webpackChunkName: "widget.widget-diagnostics" */ `./widget-diagnostics/index.js`
            );
            return module.getConfig();
        case "widget-my-projects":
            module = await import(
                /* webpackChunkName: "widget.widget-my-projects" */ `./widget-my-projects/index.js`
            );
            return module.getConfig();
        case "widget-subscribe":
            module = await import(
                /* webpackChunkName: "widget.widget-subscribe" */ `./widget-subscribe/index.js`
            );
            return module.getConfig();
        case "widget-my-profile":
            module = await import(
                /* webpackChunkName: "widget.widget-my-profile" */ `./widget-my-profile/index.js`
            );
            return module.getConfig();
        case "widget-modify-my-contact-details":
            module = await import(
                /* webpackChunkName: "widget.widget-modify-my-contact-details" */ `./widget-modify-my-contact-details/index.js`
            );
            return module.getConfig();
        case "widget-landing-community":
            module = await import(
                /* webpackChunkName: "widget.widget-landing-community" */ `./widget-landing-community/index.js`
            );
            return module.getConfig();
        case "widget-gmf-form-submit":
            module = await import(
                /* webpackChunkName: "widget.widget-gmf-form-submit" */ `./widget-gmf-form-submit/index.js`
            );
            return module.getConfig();
        case "widget-job-board":
            module = await import(
                /* webpackChunkName: "widget.widget-job-board" */ `./widget-job-board/index.js`
            );
            return module.getConfig();
        case "widget-job-board-offer":
            module = await import(
                /* webpackChunkName: "widget.widget-job-board-offer" */ `./widget-job-board-offer/index.js`
            );
            return module.getConfig();
        case "widget-experts-gallery":
            module = await import(
                /* webpackChunkName: "widget.widget-experts-gallery" */ `./widget-experts-gallery/index.js`
            );
            return module.getConfig();
        default:
            webui.log("Uknown static item, making default request");
            const dispatch = webui.getDispatchMethod();
            return dispatch(
                webui.getActionByName("request.request")(defaultRequestOptions, { widgetId: id })
            );
    }
};

export { getWidgetConfig };
