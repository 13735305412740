import React from "react";
import webui, { BaseComponent } from "@tinqin/tinqin-web-ui";
import EntityBuilder from "@tinqin/tinqin-web-ui/src/utils/EntityBuilder";
import { xssFilter } from "../../utils/xssFilter";
const { addEntity, getEntity, getParentPath, setEntityProps } = webui.getActionByName("utils");

function loadGoogleRecaptcha(key) {
    return new Promise((resolve, reject) => {
        let mapScript = document.getElementById("g-recaptcha");
        if (mapScript) {
            resolve();
        } else {
            mapScript = dynamicallyIncludeScript(
                "g-recaptcha",
                `https://www.google.com/recaptcha/api.js?render=${key}`
            );
        }
    });
}

function dynamicallyIncludeScript(id, address) {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.id = id;
    script.src = address;
    document.body.appendChild(script);

    return script;
}

class Recaptcha extends BaseComponent {
    componentDidMount() {
        const dataEntity = EntityBuilder.of(this.props.data, { inplace: true });
        const key = dataEntity.getProperty("value");
        loadGoogleRecaptcha(key);
    }

    render() {
        const invisible = this.props.config ? this.props.config.invisible : false;
        const dataEntity = EntityBuilder.of(this.props.data, { inplace: true });
        const text = dataEntity.getProperty("text");
        if (invisible) {
            return null;
        }
        return (
            <div
                className={"un-captcha"}
                dangerouslySetInnerHTML={{ __html: xssFilter.process(text) }}
                data-hyperlinks-container={true}
            />
        );
    }
}

export default webui.connectComponent()(Recaptcha);

export function triggerRecaptcha(sirenAction, widgetId, contextPath) {
    return async dispatch => {
        const grecaptcha = window.grecaptcha;
        const targetPath = getParentPath(contextPath);
        const recaptchaData = dispatch(getEntity(targetPath + ".recaptcha"));
        const recaptchaTokenData = dispatch(getEntity(targetPath + ".recaptchaToken"));
        const recaptchaEntity = EntityBuilder.of(recaptchaData, { inplace: true });
        const key = recaptchaEntity.getProperty("value");
        const result = new Promise((resolve, reject) => {
            grecaptcha.ready(function() {
                try {
                    grecaptcha
                        .execute(key, { action: "submit" })
                        .then(function(token) {
                            if (recaptchaTokenData) {
                                dispatch(setEntityProps("recaptchaToken", { value: token }));
                            } else {
                                dispatch(
                                    addEntity(targetPath, {
                                        class: ["recaptchaToken"],
                                        properties: {
                                            value: token
                                        }
                                    })
                                );
                            }
                            resolve();
                        })
                        .catch(function() {
                            if (recaptchaTokenData) {
                                dispatch(
                                    setEntityProps("recaptchaToken", { value: "INVALID_KEY" })
                                );
                            } else {
                                dispatch(
                                    addEntity(targetPath, {
                                        class: ["recaptchaToken"],
                                        properties: {
                                            value: "INVALID_KEY"
                                        }
                                    })
                                );
                            }
                            resolve();
                        });
                } catch (e) {
                    if (recaptchaTokenData) {
                        dispatch(setEntityProps("recaptchaToken", { value: "INVALID_KEY" }));
                    } else {
                        dispatch(
                            addEntity(targetPath, {
                                class: ["recaptchaToken"],
                                properties: {
                                    value: "INVALID_KEY"
                                }
                            })
                        );
                    }
                    resolve();
                }
            });
        });
        return await result;
    };
}

export function executeRecaptcha(sirenAction, widgetId, contextPath) {
    return async dispatch => {
        const grecaptcha = window.grecaptcha;
        const recaptchaData = dispatch(getEntity(contextPath));
        const recaptchaEntity = EntityBuilder.of(recaptchaData, { inplace: true });
        const key = recaptchaEntity.getProperty("value");
        const tokenResult = await new Promise((resolve, reject) => {
            grecaptcha.ready(function() {
                try {
                    grecaptcha
                        .execute(key, { action: "submit" })
                        .then(token => {
                            resolve(token);
                        })
                        .catch(() => {
                            resolve("INVALID_KEY");
                        });
                } catch (e) {
                    resolve("INVALID_KEY");
                }
            });
        });
        if (tokenResult === null || tokenResult === "INVALID_KEY") {
            return tokenResult;
        }
        const requestOptions = {
            method: "POST",
            // endpoint: "/reCaptcha",
            endpoint: sirenAction.href,
            payload: {
                reCaptchaToken: tokenResult
            },
            headers: {
                "Content-Type": "application/json"
            }
        };
        const request = webui.getActionByName("request.request");
        const recaptchaResult = await dispatch(request(requestOptions));
        // let recaptchaResult = await dispatch(webui.getActionByName("update")(requestOptions));
        return recaptchaResult;
    };
}
