import React from "react";
import webui from "@tinqin/tinqin-web-ui";
import ResponsiveHOC from "./../../../HOC/components/responsive";
import { getAddressFromActions } from "./../../actions/page/PageAddress";

const Logo = props => {
    if (!props.data) {
        return webui.getUtils().getEmptyContent();
    }
    const onClick = e => {
        e.preventDefault();
        webui.getUtils().dispatchSirenActions(props, ["default", "click"]);
    };
    const computeLogoSizeClass = componentProps => {
        let logoClass = " tq-logo-m";

        switch (props.size) {
            case "XS":
                if (componentProps.sizeWhenVerySmall) {
                    logoClass = " tq-logo-" + componentProps.sizeWhenVerySmall;
                }
                break;
            case "S":
                if (componentProps.sizeWhenSmall) {
                    logoClass = " tq-logo-" + componentProps.sizeWhenSmall;
                }
                break;
            case "M":
                if (componentProps.sizeWhenMedium) {
                    logoClass = " tq-logo-" + componentProps.sizeWhenMedium;
                }
                break;
            case "L":
                if (componentProps.sizeWhenLarge) {
                    logoClass = " tq-logo-" + componentProps.sizeWhenLarge;
                }
                break;
            case "XL":
                if (componentProps.sizeWhenVeryLarge) {
                    logoClass = " tq-logo-" + componentProps.sizeWhenVeryLarge;
                }
                break;
            default:
                break;
        }

        return logoClass;
    };
    const dataProps = webui.getUtils().extractComponentProps(props, {
        additionalProps: [
            "image",
            "alt",
            "tag",
            "href",
            "title",
            "extraClasses",
            "sizeWhenLarge",
            "sizeWhenMedium",
            "sizeWhenSmall",
            "sizeWhenVeryLarge",
            "sizeWhenVerySmall"
        ],
        propsDefaults: { tag: "div", extraClasses: "", title: "" }
    });

    const logoClass = "tq-logo " + dataProps.extraClasses + computeLogoSizeClass(dataProps);

    const actions = props.data.actions || [];
    const href = getAddressFromActions(actions);

    const properties = {
        className: logoClass,
        onClick,
        "data-tq-id": props.dataTqId
    };
    if (dataProps.href || href) {
        dataProps.tag = "a";
        properties.href = dataProps.href || href;
    }

    return React.createElement(
        dataProps.tag,
        properties,
        <img src={dataProps.image} alt={dataProps.alt} title={dataProps.title} />
    );
};

export default webui.connectComponent({
    actions: { dispatchSirenActions: "siren.dispatchSirenActions" }
})(ResponsiveHOC(Logo));
