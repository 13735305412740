import React, { useCallback } from "react";
import webui from "@tinqin/tinqin-web-ui";
import { IComponentProps } from "../../utils/commonTypes";

export const Image: React.FC<IComponentProps> = props => {
    const onClick = useCallback(() => {
        webui.getUtils().dispatchSirenActions(props, ["default", "click"]);
    }, [props]);

    if (!props.data) {
        return webui.getUtils().getEmptyContent();
    }
    const imageProps = webui.getUtils().extractComponentProps(props, {
        additionalProps: ["image", "extraClasses", "alt", "blockElement", "simple"],
        propsDefaults: { alt: "Unéo logos", blockElement: true, simple: false },
            aliases: { image: "src" }
    });
    if (!imageProps.image){
        return webui.getUtils().getEmptyContent();
    }
    const extraClasses: string = imageProps.extraClasses || "";
    const blockClass: string = imageProps.blockElement ? "tq-block-image" : "";
    const className = `tq-image ${extraClasses} ${blockClass}`;

    if (imageProps.simple) {
        return <img className={extraClasses} src={imageProps.image} alt={imageProps.alt} />;
    }

    return (
        <span className={className} onClick={onClick}>
            <img src={imageProps.image} alt={imageProps.alt} />
        </span>
    );
};

Image.displayName = "Image";

export default webui.connectComponent()(Image);
