import React from "react";
import webui, { BaseComponent } from "@tinqin/tinqin-web-ui";

//Container rendering children only if there is some value in it
class HasValueContainer extends BaseComponent {
    render() {
        if (this.props.data) {
            const props = this.props.data.properties || {};
            if (!!props.value) {
                const children = this.resolveChildren();
                return <>{children}</>;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }
}

export default webui.connectComponent()(HasValueContainer);
