import React from "react";
import webui, { BaseComponent } from "@tinqin/tinqin-web-ui";
import { xssFilter } from "../../utils/xssFilter";
import "@tinqin/design-uneo-site-cp/less/terms-checkbox.less";

//temporary component for cases where background image is used instead of <img>
class FormConsent extends BaseComponent {
    onClick = () => {
        this.dispatchSirenActions();
    };
    toggle = () => {
        const dataProps = webui.getUtils().extractComponentProps(this.props, {
            additionalProps: ["value"]
        });
        if (dataProps.value) {
            this.saveState(false);
            this.dispatchSirenActions(["default", "change"]);
        } else {
            this.saveState(true);
            this.dispatchSirenActions(["default", "change"]);
        }
    };
    render() {
        const data = this.props.data ? this.props.data.properties || {} : null;
        if (!data) {
            return this.getEmptyContent();
        }
        const dataProps = webui.getUtils().extractComponentProps(this.props, {
            additionalProps: ["label", "value", "valid", "extraClasses"],
            propsDefaults: {
                extraClasses: ""
            }
        });
        let validClass = "";
        if (
            dataProps.valid === false ||
            dataProps.value === false ||
            this.props.uiState.valid === false
        ) {
            validClass = "tq-has-error";
        }
        let selectedClass = "";
        if (dataProps.value === true) {
            selectedClass = "tq-selected";
        }
        return (
            <div className={`tq-form-element ${dataProps.extraClasses} ${validClass}`}>
                <div className="tq-input-container tq-checkbox-group un-terms-checkbox">
                    <div
                        className={"tq-checkbox-option tq-no-margin " + validClass + selectedClass}
                        tabIndex={0}
                        data-for="conditions"
                    >
                        <div className="tq-checkbox" onClick={this.toggle}>
                            <input
                                className="tq-checkbox"
                                type="checkbox"
                                tabIndex={-1}
                                defaultValue="conditions"
                            />
                        </div>
                        <span
                            className="tq-text-node"
                            dangerouslySetInnerHTML={{
                                __html: xssFilter.process(dataProps.label)
                            }}
                            data-hyperlinks-container={true}
                        ></span>
                    </div>
                </div>
            </div>
        );
    }
}

export default webui.connectComponent({
    actions: {
        dispatchSirenActions: "siren.dispatchSirenActions",
        triggerSet: "component.triggerSet",
        saveState: "component.saveState"
    },
    uiState: true
})(FormConsent);
