import React, { useCallback, useMemo } from "react";
import webui from "@tinqin/tinqin-web-ui";
import { IComponentProps } from "../../utils/commonTypes";
import { xssFilter } from "../../utils/xssFilter";

//title component ("bannerTitle" and "title" combined, uses "value" for content)
//uses dangerouslySetInnerHTML for rich titles
//tag, extraClasses, value
//overwriteClasses to overwrite default tq-title class
export const RichTitle: React.FC<IComponentProps> = props => {
    const onClick = useCallback(() => {
        webui.getUtils().dispatchSirenActions(props);
    }, [props]);
    const {
        tag,
        value,
        extraClasses,
        overwriteClasses
    }: {
        tag: string;
        extraClasses?: string;
        value: string;
        overwriteClasses?: string;
    } = webui.getUtils().extractComponentProps(props, {
        additionalProps: ["value", "extraClasses", "tag", "overwriteClasses"],
        propsDefaults: {
            extraClasses: "",
            tag: "h2",
            value: ""
        },
        aliases: {
            value: "label"
        }
    });
    let className = `tq-title ${extraClasses}`;
    if (overwriteClasses) {
        className = overwriteClasses;
    }
    //removing any paragraphs
    const innerHTML = useMemo(() => xssFilter.process(value.replace("<p>", "").replace("</p>", "")), [value]);
    if (!props.data) {
        return null;
    }
    const result = React.createElement(
        tag,
        { className, onClick },
        <span
            className="tq-text-node"
            dangerouslySetInnerHTML={{
                __html: innerHTML
            }}
        ></span>
    );
    return result;
};

export default webui.connectComponent()(RichTitle);
