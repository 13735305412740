import webui from "@tinqin/tinqin-web-ui";
import { getAllEntityOccurrences } from "./utils";
import { updateContactMessageOnSelection } from "./contactForm";
const {
    getEntityActions,
    getEntityName,
    getEntityValue,
    getParentPath,
    setEntityValue,
    getStorePath
} = webui.getActionByName("utils");

const defineGlobPropName = (action, path) => {
    return action.globalPropertyName || getEntityName(path);
};

export function getDashboardsWidgetsData() {
    return (dispatch, getState) => {
        return getState()
            .getIn(["data"])
            .toJS();
    };
}

export const changeEntityValueEverywhereOnDashboard = (value, path) => {
    return dispatch => {
        let placesToBeSet = [];
        //get all widgeths on the dashboard
        const dashboardWidgets = dispatch(getDashboardsWidgetsData());

        //find where this entity exists on the dashboard
        Object.keys(dashboardWidgets).forEach(widget => {
            const results = dispatch(
                getAllEntityOccurrences(path, dashboardWidgets[widget].class[0])
            );
            placesToBeSet = placesToBeSet.concat(results);
        });

        //set the value everywhere else in the dashboard
        const otherPlacesToBeSet = placesToBeSet.filter(places => places !== path);

        otherPlacesToBeSet.forEach(el => {
            dispatch(setEntityValue(el, value));
        });
        // http://jira.tinqin.com/browse/UGP-2040
        if (getEntityName(path) === "profileSelection") {
            otherPlacesToBeSet.forEach(el => {
                dispatch(changeContactMessageEverywhereOnDashboard(el));
            });
            // http://jira.tinqin.com/browse/UGP-2063
            if (dashboardWidgets.hasOwnProperty("widget-quote")) {
                const widgetId = "widget-quote";
                const actionPath = "quote.message.profileSelection";
                const profileSelectionAction = dispatch(getEntityActions(actionPath)).find(
                    action => action.id === "profileSelectAction"
                );
                delete profileSelectionAction.events;
                const dispatchAction = webui.getActionByName("siren.dispatchSirenActions");
                dispatch(dispatchAction([profileSelectionAction], widgetId, actionPath));
            }
        }
    };
};

export const changeContactMessageEverywhereOnDashboard = currentPath => {
    return (dispatch, getState) => {
        const parentPath = getParentPath(currentPath);
        if (getStorePath({ query: `${parentPath}.form.message`, state: getState() })) {
            dispatch(updateContactMessageOnSelection({}, "", currentPath));
        }
    };
};

//sets the new value in sessionStorage and everywhere in the dashboard where it exists as a entity "class" identifier
export const setGlobalProperty = (sirenAction, widgetId, currentPath) => {
    return dispatch => {
        // let placesToBeSet = [];

        //set the global prop
        const newValue = dispatch(getEntityValue(currentPath));
        const globPropName = defineGlobPropName(sirenAction, currentPath);
        if (newValue) {
            webui.setGlobalProperties({ [globPropName]: newValue });
            window.sessionStorage.setItem(globPropName, newValue);
        } else {
            window.sessionStorage.removeItem(globPropName);
        }
        dispatch(changeEntityValueEverywhereOnDashboard(newValue, currentPath));
    };
};

export const getGlobalProperty = (sirenAction, widgetId, currentPath) => {
    return dispatch => {
        const globPropName = defineGlobPropName(sirenAction, currentPath);
        const selectedValue = window.sessionStorage.getItem(globPropName);
        if (selectedValue) {
            dispatch(setEntityValue(currentPath, selectedValue));
        }
    };
};
