import React, { useState, useRef, useEffect } from "react";
import webui from "@tinqin/tinqin-web-ui";
import debounce from "@tinqin/tinqin-utils/src/dom/debounce";

//Container rendering the main header and manipulating its state
const HeaderContainer = props => {
    const data = props.data;
    const [navClassName, setNavClassName] = useState("nav-top");
    const headerNavRef = useRef(null);
    const callbackRef = useRef(null);

    callbackRef.current = debounce(() => {
        const pageTop = document.body.getBoundingClientRect().top;
        if (pageTop + headerNavRef.current.offsetHeight >= 0) {
            setNavClassName("nav-top");
            return;
        }
        setNavClassName("nav-down");
    }, 100);

    useEffect(() => {
        const debouncedScrollListener = e => {
            if (e.target === document) {
                callbackRef.current();
            }
        };
        window.addEventListener("scroll", debouncedScrollListener, true);
        return () => {
            window.removeEventListener("scroll", debouncedScrollListener, true);
        };
    }, [callbackRef]);

    if (!data) {
        return webui.getUtils().getEmptyContent();
    }
    const propsData = webui.getUtils().extractComponentProps(props, {
        additionalProps: ["extraClasses", "tag"],
        propsDefaults: {
            extraClasses: "",
            tag: "div"
        }
    });
    const className = `${propsData.extraClasses} ${navClassName}`;
    const children = webui.getUtils().resolveChildren(props);
    return React.createElement(propsData.tag, {
        className,
        children,
        ref: headerNavRef
    });
};

export default webui.connectComponent()(HeaderContainer);
