import React from "react";
import webui from "@tinqin/tinqin-web-ui";

export default class Link extends React.Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }
    onLinkClick = event => {
        event.preventDefault();
    };
    onClick() {
        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    static extractDashboard(props) {
        const actions = props.actions || [];
        const switchDashboard = actions.find(a => a.actionType === "switchDashboard") || {};
        const fields = switchDashboard.fields || [];
        const field = fields.find(field => field.name === "dashboard") || {};

        return field.value;
    }

    render() {
        const props = this.props;
        const extractedProps = webui.getUtils().extractComponentProps(props, {
            additionalProps: ["extraClasses"]
        });
        const none = { display: "none" };
        const dashboardToSwitchTo = Link.extractDashboard(props);
        const dashboardHref = `/?dashboard=${dashboardToSwitchTo}`;
        const extraClasses = props.extraClasses || extractedProps.extraClasses || "";

        return (
            <li onClick={this.onClick}>
                <a
                    style={props.style}
                    href={props.href}
                    target={props.target}
                    className={extraClasses}
                    onClick={this.onLinkClick}
                >
                    {props.icon && <i className={props.icon} />}
                    {props.label && <span className="tq-text-node">{props.label}</span>}
                </a>
                {!props.href && dashboardToSwitchTo && (
                    <a href={dashboardHref} target="_self" style={none} />
                )}
            </li>
        );
    }
}
