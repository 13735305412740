import webui from "@tinqin/tinqin-web-ui";

export function changeVisibilityState(sirenAction, widgetId, currentPath) {
    return dispatch => {
        if (sirenAction.target) {
            if (sirenAction.fields && sirenAction.fields[0].name) {
                const setUiState = webui.getActionByName("component.setUiState");
                const data = {};
                const propName = sirenAction.fields[0].name;
                data[propName] = sirenAction.fields[0].value;
                dispatch(setUiState(widgetId, sirenAction.target, data));
            }
        }
    };
}
