import React from "react";
import webui, { BaseComponent } from "@tinqin/tinqin-web-ui";
import { getAddressFromActions } from "../../actions/page/PageAddress";

class HyperLinkWrapper extends BaseComponent {
    onClick = event => {
        event.preventDefault();
    };
    render() {
        const data = this.props.data ? this.props.data.properties || {} : null;
        if (!data) {
            return "";
        }
        const dataProps = webui.getUtils().extractComponentProps(this.props, {
            additionalProps: ["extraClasses", "noAdditionalClasses"],
            propsDefaults: {
                extraClasses: ""
            }
        });
        const classes =
            (!dataProps.noAdditionalClasses ? "un-anchor-custom " : "") + dataProps.extraClasses;
        return (
            <a
                href={getAddressFromActions(this.props.data.actions)}
                className={classes}
                onClick={this.onClick}
            >
                {this.resolveChildren()}
            </a>
        );
    }
}

export default webui.connectComponent()(HyperLinkWrapper);
