"use client";
import React from "react";
import { useEffect, useState } from "react";
import { load } from "./tarteaucitron";
export default function TarteaucitronLoaderComponent() {
    const [loaded, setLoaded] = useState(0);

    useEffect(() => {
        if (window.globalSettings) {
            if (loaded !== true) {
                const pfHeader = window.globalSettings.pfHeader;
                if (pfHeader) {
                    import("@tinqin/design-uneo-site-cp/less/common/cookies.less").then(() => {
                        const cookieConsentEndpoint =
                            window.globalSettings.featureEndpoint + "/tarteaucitron";
                        fetch(cookieConsentEndpoint, {
                            method: "GET",
                            headers: {
                                Accept: "application/json"
                            }
                        })
                            .then(res => res.json())
                            .then(data => {
                                const isWebCrawler = window.navigator.userAgent.includes(
                                    "Prerender"
                                );
                                if (!loaded && data && data.enabled && !isWebCrawler) {
                                    load(data.settings);
                                    setLoaded(true);
                                }
                            });
                    });
                } else {
                    import("@tinqin/design-uneo-site-cp/less/common/cookies-deprecated.less").then(
                        () => {
                            let matomoConfig;
                            const isWebCrawler = window.navigator.userAgent.includes("Prerender");
                            if (window.globalSettings.matomo && !isWebCrawler) {
                                matomoConfig = window.globalSettings.matomo;
                                if (matomoConfig.selfHosted) {
                                    let matomoUrl = matomoConfig.url || "";
                                    if (
                                        matomoUrl.length &&
                                        matomoUrl[matomoUrl.length - 1] !== "/"
                                    ) {
                                        matomoUrl += "/";
                                    }
                                    matomoConfig.url = matomoUrl;
                                }
                            }
                            const scriptPromise = new Promise(function(resolve, reject) {
                                const script = document.createElement("script");
                                script.onload = resolve;
                                script.onerror = reject;
                                script.async = true;
                                script.src = "/tarteaucitron/tarteaucitron.js";
                                script.id = "lemoncake";
                                document.body.appendChild(script);
                            });
                            //create initially _paq for matomo gathering data before accepting cookie consent
                            window._paq = window._paq || [];
                            scriptPromise.then(function() {
                                setTimeout(function() {
                                    const checkStatus = (response, rejectMessage) => {
                                        if (response.status >= 200 && response.status < 300) {
                                            return Promise.resolve(response);
                                        } else {
                                            return Promise.reject(new Error(rejectMessage));
                                        }
                                    };
                                    fetch(window.location.origin + "/api/lemoncake")
                                        .then(res =>
                                            checkStatus(
                                                res,
                                                "Unsuccessful loading of settings for tacJS."
                                            )
                                        )
                                        .then(res => res.json())
                                        .then(data => {
                                            let settings = {};
                                            if (data) {
                                                const contextSettings = "tacjs.settings";
                                                settings = data[contextSettings] || {};
                                            }
                                            window.tarteaucitronForceLanguage =
                                                settings.langcode || "fr";
                                            window.tarteaucitronCustomText = settings.texts || {};
                                            //temporary hardcoded
                                            settings.dialog = settings.dialog || {};
                                            settings.dialog.disclamerUrl =
                                                "/legal/politique-de-confidentialite";
                                            const tarteaucitron = window.tarteaucitron;
                                            tarteaucitron.init(settings.dialog);
                                            tarteaucitron.job = tarteaucitron.job || [];
                                            tarteaucitron.user.googlemapsKey =
                                                window.globalSettings?.GMapsKey;
                                            tarteaucitron.user.matomoId = matomoConfig.siteId || 1;
                                            tarteaucitron.user.matomoContainer =
                                                matomoConfig.container;

                                            tarteaucitron.user.matomoHost = matomoConfig.url;
                                            tarteaucitron.user.matomoTagManager = true;
                                            tarteaucitron.job.push("matomo");
                                            tarteaucitron.job.push("googlemaps");
                                            tarteaucitron.job.push("youtube");

                                            //managing update of consent to be saved in SSO, on each user click
                                            //handling buttons for each service
                                            const savedRespond =
                                                tarteaucitron.userInterface.respond;
                                            let savedElement;
                                            tarteaucitron.userInterface.respond = function() {
                                                savedRespond.apply(this, arguments);
                                                if (savedElement !== arguments[0]) {
                                                    //saving changes in tarteaucitron cookie directly
                                                    fetch(
                                                        window.globalSettings
                                                            .cookieConsentUpdateURL,
                                                        {
                                                            method: "post",
                                                            mode: "no-cors",
                                                            credentials: "include"
                                                        }
                                                    ).then();
                                                }
                                            };
                                            //handling "agree to all" and "reject all" buttons
                                            const savedRespondAll =
                                                tarteaucitron.userInterface.respondAll;
                                            let savedAllElement;
                                            tarteaucitron.userInterface.respondAll = function() {
                                                savedRespondAll.apply(this, arguments);
                                                if (savedAllElement !== arguments[0]) {
                                                    //saving changes in tarteaucitron cookie directly
                                                    fetch(
                                                        window.globalSettings
                                                            .cookieConsentUpdateURL,
                                                        {
                                                            method: "post",
                                                            mode: "no-cors",
                                                            credentials: "include"
                                                        }
                                                    ).then();
                                                }
                                            };
                                            setLoaded(true);
                                        })
                                        .catch(message => {
                                            console.log(message);
                                        });
                                }, 2000);
                            });
                        }
                    );
                }
            }
        } else {
            setTimeout(() => {
                setLoaded(loaded + 1);
            }, 1000);
        }
    }, [loaded]);

    return <></>;
}
