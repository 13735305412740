import React from "react";
import webui, { BaseComponent } from "@tinqin/tinqin-web-ui";

//Container rendering children only if data is missing
class NotBoundContainer extends BaseComponent {
    render() {
        if (this.props.data) {
            if (this.props.config.checkValue) {
                const props = this.props.data.properties || {};
                if (!!props.value) {
                    return null;
                }
            } else {
                return null;
            }
        }
        const children = this.resolveChildren();
        return <React.Fragment>{children}</React.Fragment>;
    }
}

export default webui.connectComponent()(NotBoundContainer);
