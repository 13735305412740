import React from "react";
import webui, { BaseComponent } from "@tinqin/tinqin-web-ui";

//Component for visualizing constructed message in Contacts page Contact Form
//message is created based on selection of dropdowns
export class ContactMessage extends BaseComponent {
    render() {
        if (this.props.data) {
            const props = webui.getUtils().extractComponentProps(this.props, {
                additionalProps: ["label", "constructedMessage"],
                propsDefaults: {
                    label: "",
                    constructedMessage: ""
                }
            });

            return (
                <div className="tq-form-element tq-vertical form-custom un-constructed-message">
                    <div className="tq-label-container">
                        <label className="tq-label" htmlFor="message">
                            <span className="tq-text-node">
                                <span className="tq-hint-container">
                                    <span className="tq-text-node">{props.label}</span>
                                    <span className="tq-required" />
                                </span>
                            </span>
                        </label>
                    </div>
                    {props.constructedMessage && (
                        <div className="tq-output-container">
                            <span className="tq-text-node">{props.constructedMessage}</span>
                        </div>
                    )}
                </div>
            );
        }
        return this.getEmptyContent();
    }
}

export default webui.connectComponent()(ContactMessage);
