import React from "react";
import { debounceOngoing } from "@tinqin/tinqin-utils/src/dom/debounce";

//keeps state if the container element is visible on the screen
//listens for "scroll" and "resize" DOM events
//Properties, to be passes as HOCProps object
//Does not cover some edge cases
/**
 * Define if the visibility need to be tracked once
 * once: PropTypes.bool,
 */
/**
 * Additional className to apply
 * extraClasses: PropTypes.string,
 */
/**
 * Define an offset. Can be useful for lazy loading
 * offset: PropTypes.number
 */
/**
 * Update the visibility state as soon as a part of the tracked component is visible
 * default: true
 * partialVisibility: PropTypes.bool,
 */

/**
 * Define a custom tag for container element
 * default: "div"
 * tag: PropTypes.string
 */
export default function wrapComponent(InnerComponent, options) {
    class isVisibleHOC extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                isVisible: false,
                alreadyShownOnce: false
            };
            this.isComponentVisible = debounceOngoing(this.isComponentVisible.bind(this), 200);
        }

        componentDidMount() {
            this.attachListener();
            this.isComponentVisible();
        }

        componentDidUpdate(prevProps) {
            const { once = false } = this.props.HOCProps || options || {};
            if (this.state.alreadyShownOnce && once) {
                return;
            } else {
                this.isComponentVisible();
            }
        }

        componentWillUnmount() {
            this.removeListener();
        }

        attachListener() {
            window.addEventListener("scroll", this.isComponentVisible);
            window.addEventListener("resize", this.isComponentVisible);
        }

        removeListener() {
            window.removeEventListener("scroll", this.isComponentVisible);
            window.removeEventListener("resize", this.isComponentVisible);
        }

        isVisible = ({ top, left, bottom, right, width, height }, windowWidth, windowHeight) => {
            const { offset = 0, partialVisibility = true } = this.props.HOCProps || options || {};

            if (top + right + bottom + left === 0) {
                return false;
            }

            const topThreshold = 0 - offset;
            const leftThreshold = 0 - offset;
            const widthCheck = windowWidth + offset;
            const heightCheck = windowHeight + offset;

            return partialVisibility
                ? top + height >= topThreshold &&
                      left + width >= leftThreshold &&
                      bottom - height <= heightCheck &&
                      right - width <= widthCheck
                : top >= topThreshold &&
                      left >= leftThreshold &&
                      bottom <= heightCheck &&
                      right <= widthCheck;
        };

        isComponentVisible = () => {
            setTimeout(() => {
                // isComponentVisible might be called from componentDidMount, before component ref is assigned
                if (!this.nodeRef || !this.nodeRef.getBoundingClientRect) return;

                const html = document.documentElement;
                const { once = false } = this.props.HOCProps || options || {};
                const boundingClientRect = this.nodeRef.getBoundingClientRect();
                const windowWidth = window.innerWidth || html.clientWidth;
                const windowHeight = window.innerHeight || html.clientHeight;

                const isVisible = this.isVisible(boundingClientRect, windowWidth, windowHeight);

                if (isVisible && once) {
                    this.setState({
                        alreadyShownOnce: true
                    });
                    this.removeListener();
                }
                if (this.state.isVisible !== isVisible) {
                    this.setState({ isVisible });
                }
            }, 0);
        };

        setNodeRef = ref => (this.nodeRef = ref);

        render() {
            const { extraClasses, tag: Tag = "div", minHeight } =
                this.props.HOCProps || options || {};
            const styles = {};
            if (minHeight) {
                styles.minHeight = minHeight + "px";
            }
            //passes isVisible property to wrapped component
            return (
                <Tag ref={this.setNodeRef} className={extraClasses} style={styles}>
                    <InnerComponent
                        isVisible={this.state.isVisible}
                        {...this.props}
                    ></InnerComponent>
                </Tag>
            );
        }
    }

    return isVisibleHOC;
}
