import webui from "@tinqin/tinqin-web-ui";
const { getEntityValue, setEntityProps } = webui.getActionByName("utils");


export function search() {
    return dispatch => {
        const searchString = dispatch(getEntityValue("contacts.mapSection")) || "";
        if (searchString.length) {
            dispatch(
                setEntityProps("contacts.mapSection.googleMap", {
                    targetLocation: searchString
                })
            );
        }
    };
}
