import React, { useRef } from "react";
import webui from "@tinqin/tinqin-web-ui";
import EntityBuilder from "@tinqin/tinqin-web-ui/src/utils/EntityBuilder";
import ResponsiveHoc from "./../../../HOC/components/responsive";
import VideoPlayer, { getCurrentPlayer } from "../video-player/VideoPlayer";

const VideoCard = props => {
    const thumbnailVideo = useRef();
    const dataProps = webui.getUtils().extractComponentProps(props, {
        additionalProps: [
            "mobileImage",
            "desktopImage",
            "extraClasses",
            "customPlayButton",
            "thumbnailPreview",
            "videoId"
        ],
        propsDefaults: { extraClasses: "", customPlayButton: true, thumbnailPreview: false }
    });

    const extractData = () => {
        const siren = EntityBuilder.of(props.data);
        const badge = siren.getChild("badge").getValue() || null;

        return {
            badge
        };
    };

    const clickHandler = () => {
        webui.getUtils().dispatchSirenActions(props, ["click", "default"]);
    };

    const enterFullscreen = e => {
        e.stopPropagation();
        thumbnailVideo.current.classList.add("un-banner-story-wrapper--fullscreen");
        const player = getCurrentPlayer(dataProps.videoId);
        player.unMute();
        if (player.getPlayerState() !== 1) {
            player.playVideo();
        }
    };
    const exitFullscreen = () => {
        thumbnailVideo.current.classList.remove("un-banner-story-wrapper--fullscreen");
        const player = getCurrentPlayer(dataProps.videoId);
        player.mute();
    };

    //if only mobileImage is set
    const desktopImage = dataProps.desktopImage || dataProps.mobileImage;
    //in only desktopImage is set
    const mobileImage = dataProps.mobileImage || dataProps.desktopImage;
    const imgBackground = ["XL", "L"].includes(props.size) ? desktopImage : mobileImage;

    const { badge } = extractData();
    const backgroundImageStyle = { backgroundImage: `url("${imgBackground}")` };
    if (dataProps.thumbnailPreview) {
        return (
            <div
                className="un-banner-story-wrapper un-banner-story-wrapper--video"
                ref={thumbnailVideo}
            >
                <div className="tq-clickable un-banner-story un-banner-story--video">
                    {badge && <div className="un-banner-story--video-badge">{badge}</div>}
                    <button className="un-banner-story-close" onClick={exitFullscreen}></button>

                    <div className="un-banner-story-video-overlay" onClick={enterFullscreen}></div>
                    <VideoPlayer {...props} />
                </div>
            </div>
        );
    }
    return (
        <div
            className={`${dataProps.extraClasses}${
                dataProps.customPlayButton ? " un-banner-story-wrapper--video-custom" : ""
            }`}
            onClick={clickHandler}
        >
            <div className="tq-clickable un-banner-story un-banner-story--video">
                {badge && <div className="un-banner-story--video-badge">{badge}</div>}
                <div className="un-video-story-img" style={backgroundImageStyle}></div>
            </div>
        </div>
    );
};
export default webui.connectComponent({
    actions: { dispatchSirenActions: "siren.dispatchSirenActions" }
})(ResponsiveHoc(VideoCard));
