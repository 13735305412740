import webui from "@tinqin/tinqin-web-ui";
const {
    getEntityProps,
    setEntityProps
} = webui.getActionByName("utils");

//example actions, not used at the moment
export function increaseFontSize(sirenAction, widgetId, currentPath) {
    return (dispatch, getState) => {
        let currentFontSize = dispatch(getEntityProps("article", "fontSize"));
        currentFontSize = currentFontSize || 0;
        let newFontSize = currentFontSize;
        if (currentFontSize < 5) {
            ++newFontSize;
            dispatch(
                setEntityProps("article", {
                    fontSize: newFontSize
                })
            );
        }
    };
}

export function decreaseFontSize(sirenAction, widgetId, currentPath) {
    return (dispatch, getState) => {
        let currentFontSize = dispatch(getEntityProps("article", "fontSize"));
        currentFontSize = currentFontSize || 0;
        let newFontSize = currentFontSize;
        if (currentFontSize > -5) {
            --newFontSize;
            dispatch(
                setEntityProps("article", {
                    fontSize: newFontSize
                })
            );
        }
    };
}
