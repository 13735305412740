import React from "react";
import webui, { BaseComponent } from "@tinqin/tinqin-web-ui";

const extractComponentProps = webui.getUtils().extractComponentProps;

class FlexItem extends BaseComponent {
    render() {
        const props = extractComponentProps(this.props, {
            additionalProps: ["extraClasses"],
            propsDefaults: {
                extraClasses: ""
            }
        });
        const className = `tq-flex-item ${props.extraClasses}`;
        const children = this.resolveChildren();

        return <div className={className}>{children}</div>;
    }
}

export default webui.connectComponent()(FlexItem);
