//Applies color to DOM nodes (EM) which are preceded by span with an inline color
//Usecase: in article content by default inner nodes receive color from CSS
//but should use color from preceding colored parent, if any
export function traverseAndApplyColor(node: HTMLElement, color?: string) {
    let newColor: string = color;
    if (color) {
        if (node.tagName === "EM") {
            node.style["color"] = color;
        }
    }

    if (node.tagName === "SPAN") {
        if (node.style.color) {
            newColor = node.style.color;
        }
    }
    //forEach not supported
    for (let i = 0; i < node.children.length; ++i) {
        traverseAndApplyColor(node.children[i] as HTMLElement, newColor);
    }
}

//Applies border color to DOM nodes (U) which are preceded by span with an inline color
//Usecase: in story cards by default inner nodes receive color from CSS
//but should use color from preceding colored parent, if any
export function traverseAndApplyUnderlineColor(node: HTMLElement , color?: string) {
    let newColor: string = color;
    const parent: HTMLElement = node.parentNode as HTMLElement;
    if (node.tagName === "U" && color) {
        node.style.borderColor = color;
    }
    if (node.tagName === "SPAN") {
        if (node.style.color) {
            newColor = node.style.color;
        }
        //In case the parent is of a Span is U again propagate the color to the U
        if (parent.tagName === "U" && !parent.style.borderColor) {
            parent.style.borderColor = newColor;
        }
    }
    //forEach not supported
    for (let i = 0; i < node.children.length; ++i) {
        traverseAndApplyUnderlineColor(node.children[i] as HTMLElement, newColor);
    }
}

//checking if there is nested child and if it has line-height
//if there is child and it has line-height the restriction is set to the child with it's line-height * allowedLines
//if there is child but only the parent has line-height the restriction is set to the child with parent's line-height * allowedLines
//if there is no child and the node has line-height the restriction is set to the node with it's line-height * allowedLines
//if there is no child and no line-height on the node the restriction is set to the node with 1.5em line-height * allowedLines
export function applyLinesRestriction(node: HTMLElement, allowedLines: number) {
    const element: HTMLElement = node.children[0] as HTMLElement || node;
    const lineHeight: number = getLineHeight(element);
    if (isNaN(lineHeight)) {
        element.style.lineHeight = "1.5em";
        element.style.height = `${1.5 * allowedLines}em`;
        element.style.overflow = "hidden";
    } else {
        element.style.height = `${lineHeight * allowedLines}px`;
        element.style.overflow = "hidden";
    }
}

function getLineHeight(element: HTMLElement) {
    const elementLineHeightStyle: string = window
        .getComputedStyle(element, null)
        .getPropertyValue("line-height");
    return parseFloat(elementLineHeightStyle);
}
