import webui from "@tinqin/tinqin-web-ui";
const {
    getEntityChildren,
    getEntityProps,
    addEntity,
    getEntity,
    setEntityChildren
} = webui.getActionByName("utils");

//http://jira.tinqin.com/browse/UGP-2055
export function changeCalendarItems(sirenAction, widgetId, currentPath) {
    return dispatch => {
        const source = sirenAction.source;
        const paths = [`${source}.event-calendar.sorted-events`, `${source}.event-calendar.events`];
        const selectedDate = convertToDateObject(dispatch(getEntityProps(paths[1])).value);
        const events = dispatch(getEntityChildren(paths[1]));
        const sortedEvents = dispatch(getEntity(paths[0]));
        const eventsToDisplay = [...events];
        let indexToScrollTo;
        //sort events depending on selected date and event period
        for (let i = 0; i < eventsToDisplay.length - 1; i++) {
            const a_dates = eventsToDisplay[i].entities[2].properties;
            const b_dates = eventsToDisplay[i + 1].entities[2].properties;
            const a_start = convertToDateObject(a_dates.start);
            const a_end = convertToDateObject(a_dates.end);
            const b_start = convertToDateObject(b_dates.start);
            const b_end = convertToDateObject(b_dates.end);
            if (b_end < selectedDate && a_end >= selectedDate && a_start <= b_start) {
                const tmp = eventsToDisplay[i];
                eventsToDisplay[i] = eventsToDisplay[i + 1];
                eventsToDisplay[i + 1] = tmp;
            }
        }

        for (let i = 0; i < eventsToDisplay.length; i++) {
            const eventDates = eventsToDisplay[i].entities[2].properties;
            const startDate = convertToDateObject(eventDates.start);
            const endDate = convertToDateObject(eventDates.end);
            //lastest event for current date
            if (startDate <= selectedDate && endDate >= selectedDate) {
                indexToScrollTo = i;
                break;
            }
        }

        new Promise((resolve, reject) => {
            if (!sortedEvents) {
                dispatch(
                    addEntity(`${source}.event-calendar`, {
                        class: ["sorted-events"],
                        entities: eventsToDisplay
                    })
                );
            } else {
                dispatch(setEntityChildren(paths[0], eventsToDisplay));
            }
            resolve();
        }).then(() => {
            if (indexToScrollTo !== -1) {
                dispatch(scrollToSelectedElement(indexToScrollTo, eventsToDisplay));
            }
        });
    };
}

function convertToDateObject(dateStr) {
    const date = new Date(dateStr);
    date.setHours(0, 0, 0, 0);
    return date;
}
//http://jira.tinqin.com/browse/UGP-2055
function scrollToSelectedElement(indexScrollTo, items) {
    return dispatch => {
        let target, itemMargin, childWidth;
        const targets = document.getElementsByClassName("event-cards-scroll");
        if (targets.length) {
            target = targets[0];
            const targetChildren = target.children[0].children[0].children;
            const scrollXelement = target.children[0];
            if (targetChildren.length > 2) {
                const itemComputedStyles = window.getComputedStyle(
                    targetChildren[targetChildren.length - 1]
                );
                itemMargin = parseInt(itemComputedStyles.getPropertyValue("margin-left"), 10);
                scrollXelement.children[0].style.paddingLeft = 0 + "px";
                childWidth = targetChildren[0].offsetWidth;
                const childX = (window.innerWidth - childWidth) / 2;
                setTimeout(() => {
                    target.scrollLeft = (itemMargin + childWidth) * indexScrollTo;
                    scrollXelement.style.marginLeft = childX + "px";
                }, 10);
            } else {
                setTimeout(() => {
                    scrollXelement.children[0].style.paddingLeft = "";
                    scrollXelement.style.marginLeft = "";
                }, 10);
            }
        }
    };
}
