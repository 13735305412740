import React from "react";
import webui from "@tinqin/tinqin-web-ui";
import { IComponentProps, IExtractedProps } from "Common/utils/commonTypes";

//temporary solution
//special logo for article boxes
export const ArticleBoxLogo: React.FC<IComponentProps> = props => {
    const data: Record<string, any> = props.data ? props.data.properties || {} : null;
    if (!data) {
        return null;
    }
    const dataProps: IExtractedProps = webui.getUtils().extractComponentProps(props, {
        additionalProps: ["src", "alt"]
    });

    return <img src={dataProps.src} alt={dataProps.alt} />;
};

export default webui.connectComponent()(ArticleBoxLogo);
