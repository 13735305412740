import webui from "@tinqin/tinqin-web-ui";

const getMixinConfig = async ({ id = "", defaultRequestOptions = {} } = {}) => {
    let module;
    switch (id) {
        case "solution-cards":
            module = await import(
                /* webpackChunkName: "mixin.solution-cards" */ `./solution-cards/index.js`
            );
            return module.getMixin();
        case "indicators":
            module = await import(
                /* webpackChunkName: "mixin.indicators" */ `./indicators/index.js`
            );
            return module.getMixin();
        case "related-articles":
            module = await import(
                /* webpackChunkName: "mixin.related-articles" */ `./related-articles/related-articles/index.js`
            );
            return module.getMixin();
        case "related-articles-flex":
            module = await import(
                /* webpackChunkName: "mixin.related-articles-flex" */ `./related-articles/related-articles-flex/index.js`
            );
            return module.getMixin();
        case "related-articles-responsive":
            module = await import(
                /* webpackChunkName: "mixin.related-articles-responsive" */ `./related-articles/related-articles-responsive/index.js`
            );
            return module.getMixin();
        case "related-articles-corporate":
            module = await import(
                /* webpackChunkName: "mixin.related-articles-corporate" */ `./related-articles/related-articles-corporate/index.js`
            );
            return module.getMixin();
        case "additional-items":
            module = await import(
                /* webpackChunkName: "mixin.additional-items" */ `./additional-items/index.js`
            );
            return module.getMixin();
        case "story-card":
            module = await import(
                /* webpackChunkName: "mixin.story-card" */ `./story-card/index.js`
            );
            return module.getMixin();
        case "story-lines":
            module = await import(
                /* webpackChunkName: "mixin.story-lines" */ `./story-lines/index.js`
            );
            return module.getMixin();
        case "contact-form":
            module = await import(
                /* webpackChunkName: "mixin.contact-form" */ `./contact-forms/contact-form/index.js`
            );
            return module.getMixin();
        case "contact-form-extended":
            module = await import(
                /* webpackChunkName: "mixin.contact-form-extended" */ `./contact-forms/contact-form-extended/index.js`
            );
            return module.getMixin();
        case "article-box-small":
            module = await import(
                /* webpackChunkName: "mixin.article-box-small" */ `./article-boxes/article-box-small/index.js`
            );
            return module.getMixin();
        case "article-box-small-short":
            module = await import(
                /* webpackChunkName: "mixin.article-box-small-short" */ `./article-boxes/article-box-small-short/index.js`
            );
            return module.getMixin();
        case "article-box-hero":
            module = await import(
                /* webpackChunkName: "mixin.article-box-hero" */ `./article-boxes/article-box-hero/index.js`
            );
            return module.getMixin();
        case "article-box-border":
            module = await import(
                /* webpackChunkName: "mixin.article-box-border" */ `./article-boxes/article-box-border/index.js`
            );
            return module.getMixin();
        case "article-box-cp":
            module = await import(
                /* webpackChunkName: "mixin.article-box-cp" */ `./article-boxes/article-box-cp/index.js`
            );
            return module.getMixin();
        case "article-box-medium":
            module = await import(
                /* webpackChunkName: "mixin.article-box-medium" */ `./article-boxes/article-box-medium/index.js`
            );
            return module.getMixin();
        case "article-box-long-title":
            module = await import(
                /* webpackChunkName: "mixin.article-box-long-title" */ `./article-boxes/article-box-long-title/index.js`
            );
            return module.getMixin();
        case "article-box-search-result":
            module = await import(
                /* webpackChunkName: "mixin.article-box-search-result" */ `./article-boxes/article-box-search-result/index.js`
            );
            return module.getMixin();
        case "solidarity-section":
            module = await import(
                /* webpackChunkName: "mixin.solidarity-section" */ `./solidarity-section/index.js`
            );
            return module.getMixin();
        case "service-button":
            module = await import(
                /* webpackChunkName: "mixin.service-button" */ `./service-button/index.js`
            );
            return module.getMixin();
        case "grand-public-burger":
            module = await import(
                /* webpackChunkName: "mixin.grand-public-burger" */ `./burger/grand-public-burger/index.js`
            );
            return module.getMixin();
        case "pf-burger":
            module = await import(
                /* webpackChunkName: "mixin.pf-burger" */ `./burger/pf-burger/index.js`
            );
            return module.getMixin();
        case "corporate-burger":
            module = await import(
                /* webpackChunkName: "mixin.corporate-burger" */ `./burger/corporate-burger/index.js`
            );
            return module.getMixin();
        case "reimbursements-section":
            module = await import(
                /* webpackChunkName: "mixin.reimbursements-section" */ `./reimbursements-section/index.js`
            );
            return module.getMixin();
        case "quote-modal":
            module = await import(
                /* webpackChunkName: "mixin.quote-modal" */ `./quote-modal/index.js`
            );
            return module.getMixin();
        default:
            webui.log("Uknown static item, making default request");
            const dispatch = webui.getDispatchMethod();
            return dispatch(webui.getActionByName("request.request")(defaultRequestOptions));
    }
};

export { getMixinConfig };
