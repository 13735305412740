import React from "react";
import webui from "@tinqin/tinqin-web-ui";

//standard WEBUI size HOC
//options: sizes: { L:0, XL:1200 } - configures sizes names and thresholds
// for each size its lower treshold is defined
// thresholds are inclusive, XL:1200 means size will be "XL" on 1200px wide screen
export default function wrapComponent(InnerComponent, options = { sizes: {} }) {
    class ResponsiveHOC extends React.Component {
        constructor(props) {
            super(props);
            this.onResize = this.onResize.bind(this);
            this.state = {
                size:
                    Object.keys(options.sizes).length > 0
                        ? this.getSize(options.sizes)
                        : webui.getUtils().getWindowSize().size
            };
        }

        componentDidMount() {
            window.addEventListener("resize", this.onResize);
        }

        componentWillUnmount() {
            window.removeEventListener("resize", this.onResize);
        }

        getSize(sizes) {
            let size;
            let difference = Number.MAX_SAFE_INTEGER;
            const width = window.innerWidth;
            for (const element in sizes) {
                const currentDiff = Math.abs(sizes[element] - width);
                if (width >= sizes[element] && currentDiff < difference) {
                    size = element;
                    difference = currentDiff;
                }
            }
            return size;
        }

        onResize() {
            let size;
            if (Object.keys(options.sizes).length > 0) {
                size = this.getSize(options.sizes);
            } else {
                size = webui.getUtils().getWindowSize().size;
            }
            if (this.state.size !== size) {
                this.setState({
                    size
                });
            }
        }

        render() {
            return (
                <InnerComponent size={this.state.size} {...this.props}>
                    {this.props.children}
                </InnerComponent>
            );
        }
    }

    return ResponsiveHOC;
}
