import React from "react";
import webui, { BaseComponent } from "@tinqin/tinqin-web-ui";
import { getAddressFromActions } from "../../actions/page/PageAddress";
import { xssFilter } from "../../utils/xssFilter";

class HyperLinkButton extends BaseComponent {
    onClick = event => {
        event.preventDefault();
        this.dispatchSirenActions();
    };
    render() {
        const data = this.props.data ? this.props.data.properties || {} : null;
        if (!data) {
            return "";
        }
        const dataProps = webui.getUtils().extractComponentProps(this.props, {
            additionalProps: [
                "label",
                "value",
                "extraClasses",
                "icon",
                "richText",
                "background",
                "noClassName",
                "noAdditionalClasses"
            ],
            propsDefaults: {
                extraClasses: ""
            }
        });
        let bakgroundExtraClasses = "";
        if (dataProps.background) {
            bakgroundExtraClasses = `tq-button-${dataProps.background}`;
        }
        let text = <span className="tq-text-node">{dataProps.label}</span>;
        if (dataProps.richText) {
            text = (
                <span
                    className="tq-text-node"
                    dangerouslySetInnerHTML={{ __html: xssFilter.process(dataProps.label) }}
                ></span>
            );
        }

        let href = false;
        if (this.props.data && this.props.data.actions) {
            href = getAddressFromActions(this.props.data.actions);
        }
        const classes = !dataProps.noClassName
            ? (!dataProps.noAdditionalClasses ? "un-anchor-custom tq-button " : "") +
              dataProps.extraClasses +
              " " +
              bakgroundExtraClasses
            : null;
        return (
            <a href={href ? href : undefined} className={classes} onClick={this.onClick}>
                {dataProps.icon && <i className={dataProps.icon}></i>}
                {text}
            </a>
        );
    }
}

export default webui.connectComponent({
    actions: {
        dispatchSirenActions: "siren.dispatchSirenActions"
    }
})(HyperLinkButton);
