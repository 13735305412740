import React, { useEffect, useState } from "react";
import webui from "@tinqin/tinqin-web-ui";
import IdleTimer from "./../idleTimer/IdleTimer";
import { IComponentProps } from "../../utils/commonTypes";
import { QUOTE_PROCESS_ID, HOST_TIMEOUT } from "../../actions/front-end/retrieveQuoteData";

const QuoteNotificationContainer: React.FC<IComponentProps> = props => {
    const [stateProps] = useState<IComponentProps>(props);
    let timer, timeout;
    // const dataProps: IExtractedProps = webui.getUtils().extractComponentProps(props, {
    //     additionalProps: ["timeout"],
    //     propsDefaults: { timeout: 0 }
    // });

    const startTimer = () => {
        if (localStorage.getItem(QUOTE_PROCESS_ID)) {
            timeout = localStorage.getItem(HOST_TIMEOUT);
            if (timeout) {
                timer = IdleTimer({
                    timeout: Number(timeout),
                    onTimeout: (): void => {
                        webui.getUtils().dispatchSirenActions(stateProps, ["default"]);
                    }
                });
            }
        }
    };

    const stopTimer = () => {
        if (timer) {
            timer.trackerCleanUp();
        }
    };

    const handleTimer = e => {
        // debugger;
        if (e.key === QUOTE_PROCESS_ID || e.key === HOST_TIMEOUT) {
            manageTimer();
        }
    };

    const manageTimer = () => {
        if (document.visibilityState === "visible") {
            startTimer();
        } else {
            stopTimer();
        }
    };
    /* eslint-disable */
    useEffect(() => {
        window.addEventListener("storage", handleTimer);
        document.addEventListener("visibilitychange", manageTimer);
        //timeout after 45 seconds by default. The timeout is set in SECONDS
        if (!document.hidden) {
            startTimer();
        }
        return () => {
            document.removeEventListener("visibilitychange", manageTimer);
            window.removeEventListener("storage", handleTimer);
            timer.trackerCleanUp();
        };
    }, [stateProps]);
    /* eslint-enable */
    return webui.getUtils().resolveChildren(props);
};

export default webui.connectComponent()(QuoteNotificationContainer);
