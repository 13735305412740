import React, { useState, useEffect, useRef, useCallback } from "react";
import webui from "@tinqin/tinqin-web-ui";
import EntityBuilder from "@tinqin/tinqin-web-ui/src/utils/EntityBuilder";
import debounce from "@tinqin/tinqin-utils/src/dom/debounce";

const getDate = () => {
    let today = new Date();
    let dd = String(today.getDate());
    if (dd.length === 1) {
        dd = "0" + dd;
    }
    let mm = String(today.getMonth() + 1); //January is 0!
    if (mm.length === 1) {
        mm = "0" + mm;
    }
    const yy = today
        .getFullYear()
        .toString()
        .slice(-2);

    today = dd + "." + mm + "." + yy;
    return today;
};

export const getCurrentPlayer = videoId => {
    return window.players?.find(player => player.playerInfo.playlistId === videoId);
};

const getElementVisibilityStats = (element, stickyHeaderHeight = 0) => {
    let rect;
    if (element) {
        rect = element.getBoundingClientRect();
        const elementArea = rect.width * rect.height;
        let visibleWidth, visibleHeight;

        //calculate visible videoWidth
        if (rect.left >= 0 && rect.right <= window.innerWidth) {
            visibleWidth = rect.width;
        } else if (rect.left < 0 && rect.right > window.innerWidth) {
            visibleWidth = window.innerWidth;
        } else if (rect.left < 0 && rect.right <= window.innerWidth) {
            visibleWidth = rect.width + rect.left;
        } else {
            visibleWidth = window.innerWidth - rect.left;
        }

        if (visibleWidth < 0) {
            visibleWidth = 0;
        }

        //calculate visible video height
        if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
            visibleHeight = rect.height;
        } else if (rect.top < 0 && rect.bottom > window.innerHeight) {
            visibleHeight = window.innerHeight;
        } else if (rect.top < 0 && rect.bottom <= window.innerHeight) {
            visibleHeight = rect.height + rect.top;
        } else {
            visibleHeight = window.innerHeight - rect.top;
        }
        // get the visible height NOT including the one under sticky header
        if (rect.top < stickyHeaderHeight) {
            if (rect.top < 0) {
                visibleHeight -= stickyHeaderHeight;
            } else {
                visibleHeight -= stickyHeaderHeight - rect.top;
            }
        }

        if (visibleHeight < 0) {
            visibleHeight = 0;
        }

        const visibleArea = visibleWidth * visibleHeight;

        return {
            elementArea,
            visibleWidth,
            visibleHeight,
            visibleArea,
            visiblePercentage: (visibleArea / elementArea) * 100
        };
    }
};

const VideoPlayer = props => {
    const [player, setPlayer] = useState();
    const videoRef = useRef();
    const [playerState, setPlayerState] = useState({
        isPlaying: false,
        isPaused: false
    });
    const mounted = useRef(false);
    const prevPlayerState = useRef(false);
    const hasFinished = useRef(false);
    const hasDispatchedPlay = useRef(false);

    const extractData = () => {
        const siren = EntityBuilder.of(props.data);
        return {
            videoId: siren.getProperty("videoId") || siren.getChild("video").getProperty("videoId"),
            isPlaylist: siren.getProperty("playlist") || false
        };
    };
    const { videoId, isPlaylist } = extractData();

    const dataProps = webui.getUtils().extractComponentProps(props, {
        additionalProps: [
            "extraClasses",
            "width",
            "height",
            "title",
            "customPlayButton",
            "autoplay",
            "contentName",
            "contentPiece",
            "thumbnailPreview"
        ],
        propsDefaults: {
            width: "100%",
            height: "100%",
            title: "YouTube video player",
            extraClasses: "",
            customPlayButton: true,
            autoplay: false,
            thumbnailPreview: false
        },
        aliases: { href: "src" }
    });

    const onPlayerReady = useCallback(
        e => {
            if (dataProps.autoplay) {
                e.target.playVideo();
            }
        },
        [dataProps.autoplay]
    );

    const onPlayerStateChange = useCallback(
        e => {
            switch (e.data) {
                case 0: // ENDED (not triggered on loop)
                case 3: // BUFFERING
                case 5: {
                    //CUED
                    //FINISH event
                    //because of the loop we track only the first finish
                    if (Number(prevPlayerState.current) === 1 && !hasFinished.current) {
                        webui.getUtils().dispatchSirenActions(props, ["finish"]);
                        hasFinished.current = true;
                    }
                    break;
                }
                case 1: //PLAY
                case -1: {
                    // UNSTARTED
                    // if VideoPlayer gets status "UNSTARTED" after it has been initially loaded, do NOTHING
                    if (e.data === -1 && prevPlayerState.current !== false) {
                        return;
                    }
                    setPlayerState({
                        ...playerState,
                        isPlaying: true,
                        isPaused: false
                    });
                    if (!hasFinished.current && !hasDispatchedPlay.current) {
                        webui.getUtils().dispatchSirenActions(props, ["play"]);
                        hasDispatchedPlay.current = true;
                    }
                    break;
                }
                case 2: {
                    //PAUSED
                    setPlayerState({
                        ...playerState,
                        isPlaying: false,
                        isPaused: true
                    });
                    if (!hasFinished.current) {
                        webui.getUtils().dispatchSirenActions(props, ["pause"]);
                        hasDispatchedPlay.current = false;
                    }
                    break;
                }
                default: {
                    if (playerState.isPlaying || playerState.isPaused) {
                        setPlayerState({
                            ...playerState,
                            isPlaying: false,
                            isPaused: false
                        });
                        break;
                    }
                }
            }
            prevPlayerState.current = e.data;
        },
        [playerState, setPlayerState, props]
    );

    const play = useCallback(() => {
        const playingVideo = window.players?.find(
            createdPlayer => createdPlayer.getPlayerState() === 1
        );
        if (playingVideo) {
            playingVideo.stopVideo();
        }
        let existingPlayer = player;
        if (!player) {
            existingPlayer = getCurrentPlayer(videoId);
        }
        existingPlayer.playVideo();
    }, [player, videoId]);

    const scroll = useCallback(() => {
        const header = document.querySelector(".tq-header.nav-down");
        let existingPlayer = player;
        if (!player) {
            existingPlayer = getCurrentPlayer(videoId);
        }
        const videoStats = getElementVisibilityStats(videoRef.current, header?.clientHeight);
        if (videoStats) {
            if (videoStats.visiblePercentage >= 50 && !playerState.isPlaying) {
                existingPlayer.mute();
                play();
                setPlayerState(state => ({
                    ...state,
                    isPlaying: true
                }));
            } else if (videoStats.visiblePercentage < 30 && playerState.isPlaying) {
                existingPlayer.stopVideo();
                setPlayerState(state => ({
                    ...state,
                    isPlaying: false
                }));
            }
        }
    }, [play, player, playerState, videoId]);

    const handleScroll = debounce(scroll, 100);

    useEffect(() => {
        if (!mounted.current) {
            const events = {
                onStateChange: onPlayerStateChange,
                onReady: onPlayerReady
            };
            window.YT.ready(function() {
                const playerVars = {
                    loop: 1
                };
                if (isPlaylist) {
                    playerVars.listType = "playlist";
                    playerVars.list = videoId;
                } else {
                    playerVars.playlist = videoId;
                }
                const newPlayer = new window.YT.Player(`${videoId}`, {
                    videoId,
                    width: dataProps.width,
                    height: dataProps.height,
                    playerVars,
                    title: dataProps.title,
                    events
                });
                setPlayer(newPlayer);
                if (window.players) {
                    window.players.push(newPlayer);
                } else {
                    window.players = [newPlayer];
                }
            });
            mounted.current = true;
        }
        if (dataProps.thumbnailPreview) {
            window.addEventListener("scroll", handleScroll);
        }
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [
        videoId,
        dataProps.autoplay,
        dataProps.width,
        dataProps.height,
        dataProps.title,
        onPlayerStateChange,
        onPlayerReady,
        isPlaylist,
        dataProps.thumbnailPreview,
        handleScroll
    ]);

    return (
        <>
            {dataProps.customPlayButton && !playerState.isPlaying && (
                <button className={"tq-button"} onClick={play}>
                    Play
                </button>
            )}
            <div
                ref={videoRef}
                className={`un-youtube-iframe ${dataProps.extraClasses} ${
                    !playerState.isPlaying && dataProps.customPlayButton
                        ? "un-youtube-iframe--paused"
                        : ""
                }`}
                data-track-content
            >
                {/* Needed by YouTube API and will be replaced with iframe automatically */}
                <div
                    id={`${videoId}`}
                    data-piwik-ignore
                    data-content-name={dataProps.contentName}
                    data-content-piece={`${dataProps.contentPiece} - ${getDate()}`}
                    data-content-target={`https://www.youtube.com/embed/${videoId}`}
                ></div>
            </div>
        </>
    );
};
export default webui.connectComponent({
    actions: {
        dispatchSirenActions: "siren.dispatchSirenActions"
    }
})(VideoPlayer);
