import webui from "@tinqin/tinqin-web-ui";
const { getEntityChildren } = webui.getActionByName("utils");

export function initialScrollToNextItem(sirenAction = {}) {
    return dispatch => {
        const { target, offset, itemMargin, childWidth, selectedFolderItemIndex } = dispatch(
            getHorizontalScrollParameters(sirenAction.selector)
        );
        if (selectedFolderItemIndex > 0) {
            const previousItemVisiblePart = 20; //in pixels
            target.scrollLeft =
                offset +
                (childWidth + itemMargin) * (selectedFolderItemIndex + 1) -
                itemMargin -
                previousItemVisiblePart;
        }
    };
}

export function scrollToSelectedFolderItem(sirenAction = {}) {
    return dispatch => {
        // https://jira.tinqin.com/browse/UCP-535
        // "leftPadded" aligns scrolling to content padding
        const { leftPadded } = sirenAction;
        const { target, offset, itemMargin, childWidth, selectedFolderItemIndex } = dispatch(
            getHorizontalScrollParameters(sirenAction.selector)
        );
        if (selectedFolderItemIndex > 0) {
            let scrollLeft = offset + (itemMargin + childWidth) * selectedFolderItemIndex;
            if (leftPadded) {
                const contentPadding = getPaddingLeft(".un-horizontal-elements", target);
                scrollLeft -= contentPadding;
            }
            target.scrollLeft = scrollLeft;
        }
    };
}

//extracts scrolling container padding
const getPaddingLeft = (selector, container = document) => {
    const scrollContainer = container.querySelector(selector);
    const containerStyles = window.getComputedStyle(scrollContainer);
    return parseInt(containerStyles.getPropertyValue("padding-left"), 10);
};

//extracts scrolling container element, its offset, childWidth and selected child
function getHorizontalScrollParameters(selector) {
    return dispatch => {
        let itemMargin;
        let offset;
        let childWidth;
        let selectedFolderItemIndex = 0;
        const target = document.querySelector(selector);
        if (target) {
            const targetChildren = target.children[0].children[0].children;
            if (targetChildren.length > 1) {
                childWidth = targetChildren[0].offsetWidth;
                offset = targetChildren[0].offsetLeft;
                itemMargin = targetChildren[1].offsetLeft - childWidth - offset;
                const folderItems = dispatch(getEntityChildren("folder.folder-articles"));
                folderItems.forEach((item, index) => {
                    const selected = item.properties ? item.properties.selected : false;
                    if (selected) {
                        selectedFolderItemIndex = index;
                    }
                });
            }
        }
        return {
            target,
            offset,
            itemMargin,
            childWidth,
            selectedFolderItemIndex
        };
    };
}
