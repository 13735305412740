export function createAction(type = "", options = {}) {
    //options should have "href" amd parameters
    if (type === "switchDashboard" || type === "switchContainer") {
        return Object.assign(
            {
                actionType: "switchContainer",
                contentType: "application/json",
                method: "GET",
                href: "",
                parameters: {},
                properties: {},
                targets: ["{root}"]
            },
            options
        );
    }
}
