import { createAction } from "../../utils/ActionBuilder";
import webui from "@tinqin/tinqin-web-ui";
const {
    setEntityProps,
    getEntityValue,
    getEntityActions
} = webui.getActionByName("utils");

export function removeElementValue(sirenAction, widgetId, currentPath) {
    const target = sirenAction.target || "";
    return dispatch => {
        const elementPath = target;
        const elementValue = dispatch(getEntityValue(elementPath));
        if (elementValue && elementValue !== "") {
            dispatch(
                setEntityProps(elementPath, {
                    value: ""
                })
            );
        }
    };
}

export function onSearchFAQ(sirenAction, widgetId, currentPath) {
    return dispatch => {
        const pageParams = {};
        const searchString = dispatch(getEntityValue("faq.search.input"));
        if (searchString) {
            pageParams["searchText"] = encodeURI(searchString);
        }
        const switchDashboardAction = createAction("switchDashboard", {
            href: "/widget/faq",
            parameters: pageParams,
            properties: { silent: true }
        });
        const dispatchAction = webui.getActionByName("siren.dispatchSirenActions");
        dispatch(dispatchAction([switchDashboardAction], widgetId, currentPath));
        //dispatch search actions defined on "faq.search" level
        const formActions = dispatch(getEntityActions("faq.search"));
        dispatch(dispatchAction(formActions, widgetId, currentPath));
    };
}

export function onItemExpandFAQ(address, widgetId) {
    return dispatch => {
        const switchDashboardAction = createAction("switchDashboard", {
            href: "/widget/faq",
            properties: {
                address: address,
                silent: true
            }
        });
        const dispatchAction = webui.getActionByName("siren.dispatchSirenActions");
        dispatch(dispatchAction([switchDashboardAction]));
    };
}

export function scrollToResults(sirenAction, widgetId, currentPath) {
    const target = sirenAction.target || "";
    return dispatch => {
        const resultsContainer = document.getElementsByClassName(target)[0];
        if (
            (!!window.MSInputMethodContext && !!document.documentMode) ||
            /Edge/.test(navigator.userAgent)
        ) {
            window.scrollTo(0, resultsContainer.offsetTop - window.outerHeight / 2);
        } else {
            window.scroll({
                top: resultsContainer.offsetTop - window.outerHeight / 2,
                behavior: "smooth"
            });
        }
    };
}
