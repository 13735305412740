import React from "react";
import webui, { BaseComponent } from "@tinqin/tinqin-web-ui";

//Container for rich text with hyperlinks
//catches click events on "capture" phase
class HyperlinksContainer extends BaseComponent {
    onClickCapture = async event => {
        this.props.handleHyperlink(event, this.props.widgetId);
    };
    render() {
        const data = this.props.data ? this.props.data.properties || {} : null;
        if (!data) {
            return "";
        }
        const dataProps = webui.getUtils().extractComponentProps(this.props, {
            additionalProps: ["value", "extraClasses", "tag", "overwriteClasses"],
            propsDefaults: {
                extraClasses: "",
                tag: "div"
            }
        });
        return React.createElement(
            dataProps.tag,
            {
                className: dataProps.extraClasses,
                onClickCapture: this.onClickCapture,
                "data-hyperlinks-container": true
            },
            this.resolveChildren()
        );
    }
}

export default webui.connectComponent({
    actions: {
        handleHyperlink: "uneo.handleHyperlink",
        dispatchSirenActions: "siren.dispatchSirenActions"
    }
})(HyperlinksContainer);
