import React from "react";

export default class DropdownSelection extends React.Component {
    determineSelectionVisualization() {
        let displayStyle;
        if (this.props.iconTrigger) {
            displayStyle = "IconTrigger";
        } else if (this.props.multiSelection) {
            //Note: multiSelection case does not support ItemText styling.
            displayStyle = "ItemCount"; //Multi selection default!
            if (this.props.displayStyle === "ItemBlocks") {
                displayStyle = "ItemBlocks";
            } else if (this.props.displayStyle === "ItemText" && this.props.maxSelection === 1) {
                displayStyle = "ItemText";
            }
        } else {
            //Note: single selection case supports only "ItemText".
            displayStyle = "ItemText";
        }

        return displayStyle;
    }

    render() {
        switch (this.determineSelectionVisualization()) {
            case "IconTrigger":
                //Note: can be used both in single selection and multi selection cases.
                let iconToShow = this.props.iconTrigger;
                if (!this.props.multiSelection && this.props.selection.length) {
                    const currentSelected = this.props.selection[0] || {};
                    if (this.props.options.get(currentSelected).icon) {
                        iconToShow = this.props.options.get(currentSelected).icon;
                    }
                }
                return (
                    <span className="tq-selected-item">
                        <i className={iconToShow} />
                    </span>
                );
            case "ItemCount":
                //Note: can be used only in multi-selection case!
                let countText;
                const itemsCount = this.props.options.size;
                if (!this.props.selection.length && this.props.noneSelectedText) {
                    countText = this.props.noneSelectedText;
                } else if (
                    itemsCount === this.props.selection.length &&
                    this.props.allSelectedText
                ) {
                    countText = this.props.allSelectedText;
                } else {
                    countText = this.props.selection.length + "/" + itemsCount + " ";
                    countText += this.props.selectedText || " selected";
                }
                return <span className="tq-selected-item">{countText}</span>;
            case "ItemBlocks":
                //Note: can be used only in multi-selection case!
                const selectedItems = this.props.selection.map((itemCode, index) => {
                    return (
                        <li
                            key={"pill-" + index}
                            className="tq-pill"
                            onClick={event => {
                                event.stopPropagation();
                            }}
                        >
                            {this.props.options.get(itemCode).label ||
                                this.props.options.get(itemCode).code}
                            <i
                                tabIndex="-1"
                                data-target="deleteX"
                                data-code={itemCode}
                                className="tq-icon-close"
                            />
                        </li>
                    );
                });
                if (this.props.autosuggestion) {
                    selectedItems.push(
                        <li key={"pill-suggestion-input"} className="tq-pill tq-input-area">
                            <input
                                value={this.props.suggestionValue}
                                onClick={event => {
                                    event.stopPropagation();
                                }}
                                onChange={this.props.onChangeSuggestionValue}
                                ref={this.props.suggestionRef}
                                type="text"
                                className="tq-input"
                            />
                        </li>
                    );
                }
                return (
                    <ul ref={this.props.pillsRef} className="tq-pills-list">
                        {selectedItems}
                    </ul>
                );
            case "ItemText":
                //Note: can be used only in single-selection case!
                if (this.props.autosuggestion && this.props.hasInputAutosuggestion) {
                    return (
                        <ul ref={this.props.pillsRef} className="tq-pills-list">
                            <li key={"pill-suggestion-input"} className="tq-pill tq-input-area">
                                <input
                                    value={this.props.suggestionValue}
                                    onClick={event => {
                                        event.stopPropagation();
                                    }}
                                    placeholder={this.props.autosuggestionPlaceholder}
                                    onChange={this.props.onChangeSuggestionValue}
                                    ref={this.props.suggestionRef}
                                    type="text"
                                    className="tq-input"
                                />
                            </li>
                        </ul>
                    );
                } else if (this.props.autosuggestion && this.props.suggestionInputVisible) {
                    return (
                        <ul ref={this.props.pillsRef} className="tq-pills-list">
                            <li key={"pill-suggestion-input"} className="tq-pill tq-input-area">
                                <input
                                    value={this.props.suggestionValue}
                                    onClick={event => {
                                        event.stopPropagation();
                                    }}
                                    placeholder={this.props.autosuggestionPlaceholder}
                                    onChange={this.props.onChangeSuggestionValue}
                                    ref={this.props.suggestionRef}
                                    type="text"
                                    className="tq-input"
                                />
                            </li>
                        </ul>
                    );
                } else {
                    let text = this.props.noneSelectedText || "";
                    let className = "tq-selected-item";
                    const options = this.props.options;
                    if (this.props.selection.length) {
                        const selectedLabel = options.get(this.props.selection[0]).label;
                        const selectedCode = options.get(this.props.selection[0]).code;
                        text = this.props.selectionPrefix + " " + selectedLabel || selectedCode;
                    } else {
                        className = `${className} tq-no-selection-dropdown${
                            this.props.required ? " tq-required" : ""
                        }`;
                    }
                    return <span className={className}>{text}</span>;
                }
            default:
                return;
        }
    }
}
