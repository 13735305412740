import React from "react";
import webui, { BaseComponent } from "@tinqin/tinqin-web-ui";
import EntityBuilder from "@tinqin/tinqin-web-ui/src/utils/EntityBuilder";
import { xssFilter } from "../../utils/xssFilter";
const extractComponentProps = webui.getUtils().extractComponentProps;

//used in homepage carousel
//same as story card but without the underline logic and with different contract
class BannerStoryCarouselItem extends BaseComponent {
    onClick = () => {
        this.dispatchSirenActions();
    };
    render() {
        const data = this.props.data ? this.props.data.properties || {} : null;
        if (!data) {
            return this.getEmptyContent();
        }
        const dataProps = extractComponentProps(this.props, {
            additionalProps: ["extraClasses", "bgImageClass"],
            propsDefaults: {
                extraClasses: "",
                bgImageClass: "un-carousel-bg"
            }
        });
        const dataEntity = EntityBuilder.of(this.props.data, { inplace: true });
        //image
        const imageEntity = dataEntity.getChild(`image`);
        const imgSrc = imageEntity.getProperty("src");
        // let imgAlt = imageEntity.getProperty("alt");

        //logo
        const logoEntity = dataEntity.getChild(`logo`);
        const logoSrc = logoEntity.getProperty("src");
        const logoAlt = logoEntity.getProperty("alt");

        const title = dataEntity.getChild("title").getValue();
        const text = dataEntity.getChild("text").getValue();
        const badge =
            dataEntity.getChild("badge").getValue() || dataEntity.getChild("badge").getLabel();

        return (
            <div className={"tq-panel tq-banner " + dataProps.extraClasses} onClick={this.onClick}>
                <div
                    className={dataProps.bgImageClass}
                    style={{ backgroundImage: `url("${imgSrc}")` }}
                ></div>
                <div className="tq-banner-content">
                    {badge && (
                        <span className=" tq-badge ">
                            <span className="tq-text-node">{badge}</span>
                        </span>
                    )}
                    {logoSrc && <img src={logoSrc} alt={logoAlt} />}
                    <h2 className="tq-panel-title">
                        <span
                            className="tq-text-node"
                            dangerouslySetInnerHTML={{
                                __html: xssFilter.process(title)
                            }}
                            data-hyperlinks-container={true}
                        ></span>
                    </h2>
                    <div className="tq-banner-description">
                        <span
                            className="tq-text-node"
                            dangerouslySetInnerHTML={{
                                __html: xssFilter.process(text)
                            }}
                            data-hyperlinks-container={true}
                        ></span>
                    </div>
                </div>
            </div>
        );
    }
}

export default webui.connectComponent({
    actions: { dispatchSirenActions: "siren.dispatchSirenActions" }
})(BannerStoryCarouselItem);
