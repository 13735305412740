import React from "react";
import webui, { BaseComponent } from "@tinqin/tinqin-web-ui";
import ResponsiveHOC from "./../../../HOC/components/responsive";
import visibleHOC from "./../../../HOC/components/visible/visibleHOC";

const extractComponentProps = webui.getUtils().extractComponentProps;
const TIMEOUT = 1000;

class horizontalScrollingContainer extends BaseComponent {
    constructor() {
        super();
        this.state = {
            isClicked: false,
            startX: 0,
            scrollLeft: 0,
            focused: false,
            animation: 0,
            startAnimationTimeout: 0,
            scrolling: false,
            childWidth: 0,
            childMargin: 0,
            momentumID: 0,
            infinityScrollPrecisionOffset: 2,
            timestamp: 0,
            mouseStartX: 0,
            arrowLeft: 0,
            arrowRight: 0,
            step: 1,
            childrenCount: 0,
            startClickCoords: {
                x: null,
                y: null
            }
        };
        this.initiateScroll = this.initiateScroll.bind(this);
        this.stopScroll = this.stopScroll.bind(this);
        this.unPauseAnimation = this.unPauseAnimation.bind(this);
        this.scroll = this.scroll.bind(this);
        this.addInfiniteScroll = this.addInfiniteScroll.bind(this);
        this.pauseAnimation = this.pauseAnimation.bind(this);
        this.addInertia = this.addInertia.bind(this);
        this.clickHandler = this.clickHandler.bind(this);
        this.updateChildDimensions = this.updateChildDimensions.bind(this);
        this.handleArrows = this.handleArrows.bind(this);
        this.initiateArrows = this.initiateArrows.bind(this);
        this.shouldScroll = this.shouldScroll.bind(this);
        this.getChildWidthAndMargin = this.getChildWidthAndMargin.bind(this);
        this.getTargetElements = this.getTargetElements.bind(this);
        this.getContainerWidth = this.getContainerWidth.bind(this);
        this.scrollableContainer = React.createRef();
        this.children = React.createRef();

        this.setClickStart = this.setClickStartCoords.bind(this);
        this.calculateDistance = this.calculateDistance.bind(this);
        this.stopChildrenEvents = this.stopChildrenEvents.bind(this);
    }

    componentDidMount() {
        new Promise((resolve, reject) => {
            if (this.shouldAnimate()) {
                const startAnimationTimeout = setTimeout(() => {
                    this.startAnimation();
                }, TIMEOUT);
                this.setState({
                    startAnimationTimeout
                });
            }
            resolve();
        }).then(() => {
            this.updateChildDimensions();
            if (
                this.props.config.targetElements &&
                this.props.data &&
                this.props.data.entities &&
                this.props.data.entities.length
            ) {
                this.setState({ childrenCount: this.props.data.entities.length });
            }
            this.initiateArrows();
            window.addEventListener("resize", this.updateChildDimensions, true);
        });
    }

    updateChildDimensions() {
        const { width, margin } = this.getChildWidthAndMargin();
        this.setState({
            childWidth: width,
            childMargin: margin,
            step: this.calculateStep()
        });
        this.initiateArrows();
    }

    calculateStep() {
        return Math.max(1, Math.ceil((1 / window.devicePixelRatio) * 10) / 10);
    }

    //scrolling animation should run only if the container is visible and not on small screens
    //and no animation is ongoing
    //and not disabled
    shouldAnimate() {
        const visibleOnLargeScreen =
            this.props.isVisible && !(this.props.size === "S") && !(this.props.size === "XS");
        return visibleOnLargeScreen && this.hasEnoughChildren() && this.allowedAnimation();
    }

    hasEnoughChildren() {
        const config = this.props.config || {};
        const minItemsToAnimate = config.minItemsToScroll || 5;
        if (!this.props.config.targetElements) {
            const data = this.props.data || {};
            const children = data.entities || [];
            return children.length >= minItemsToAnimate;
        } else {
            return this.state.childrenCount >= minItemsToAnimate;
        }
    }

    allowedInfiteScroll() {
        return !(this.props.config.infiniteScroll === false);
    }

    allowedOnClickScroll() {
        if (this.props.config.showNavigationArrows) {
            if (!this.props.config.showNavigationArrowsSizes) {
                return true;
            } else {
                return this.props.config.showNavigationArrowsSizes.includes(this.props.size);
            }
        }
        return false;
    }

    allowedAnimation() {
        return !(this.props.config.animation === false);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.shouldAnimate()) {
            if (!this.state.animation && !this.state.startAnimationTimeout) {
                const startAnimationTimeout = setTimeout(() => {
                    this.startAnimation();
                }, TIMEOUT);
                this.setState({
                    startAnimationTimeout
                });
            }
        } else {
            if (this.state.animation) {
                this.clearAnimation();
                //resets animation intervalID
                this.setState({
                    animation: 0
                });
            }
        }
        const scrollableRef = this.scrollableContainer.current;
        if (
            this.props.config.targetElements &&
            this.props.data &&
            this.props.data.entities &&
            this.state.childrenCount !== this.props.data.entities.length
        ) {
            this.setState({ childrenCount: this.props.data.entities.length });
        }
        if (prevProps.size !== this.props.size) {
            this.updateChildDimensions();
        }
        if (
            scrollableRef &&
            this.hasEnoughChildren() &&
            this.allowedInfiteScroll() &&
            scrollableRef.scrollLeft + scrollableRef.clientWidth >=
                scrollableRef.scrollWidth - this.state.infinityScrollPrecisionOffset
        ) {
            this.addInfiniteScroll();
        }
    }

    componentWillUnmount() {
        this.clearAnimation();
        cancelAnimationFrame(this.state.momentumID);
        window.removeEventListener("resize", this.updateChildDimensions, true);
    }

    getTargetElements() {
        if (this.scrollableContainer.current) {
            return this.scrollableContainer.current.getElementsByClassName(
                this.props.config.targetElements
            );
        }
    }

    getChildWidthAndMargin() {
        const childrenRef = this.children;
        let childWidth = 0;
        let childMargin = 0;
        let child;
        if (this.props.config.targetElements) {
            /*In case we do not want to handle scrollingContainer direct children but other nested elements
            passes className in the config by targetElements property to the scroller*/
            const targetElements = this.getTargetElements();
            if (targetElements[1]) {
                child = targetElements[1];
                childMargin = parseFloat(window.getComputedStyle(child).marginLeft);
                childWidth = child.offsetWidth;
            }
        } else if (childrenRef.current && childrenRef.current.children[1]) {
            child = childrenRef.current.children[1];
            childWidth = child.offsetWidth;
            childMargin = window.getComputedStyle(child).marginLeft;
            childMargin = +childMargin.substring(0, childMargin.length - 2);
        }
        return {
            width: childWidth,
            margin: childMargin
        };
    }
    getContainerWidth() {
        if (this.scrollableContainer.current) {
            return Math.min(
                this.scrollableContainer.current.clientWidth,
                this.scrollableContainer.current.parentElement.clientWidth
            );
        }
    }

    calculateVisibleChildren() {
        const margin = this.state.childMargin;
        const width = this.state.childWidth;
        const visibleSectionWidth = this.getContainerWidth();
        let childrenInScreen = 0;
        if (margin + width > 0) {
            let fullWidth = margin + width;
            fullWidth *= this.state.step;
            childrenInScreen = visibleSectionWidth / fullWidth;
        }
        return childrenInScreen;
    }

    buildChildren() {
        const children = this.resolveChildren();
        const extraChildren = [];
        if (this.hasEnoughChildren() && this.allowedInfiteScroll()) {
            for (let i = 0; i < children.length; i++) {
                const itemIndex = children.length + i;
                const child = children[i];
                extraChildren.push(
                    React.cloneElement(child, {
                        key: `clone-${itemIndex}`
                    })
                );
            }
        }

        return [...children, ...extraChildren];
    }
    shouldScroll() {
        return this.scrollableContainer.current.scrollWidth > this.getContainerWidth();
    }

    initiateScroll(e) {
        this.setClickStartCoords(e);
        if (!this.shouldScroll()) return;
        // if (!this.state.focused) this.pauseAnimation();
        const scrollableRef = this.scrollableContainer.current;
        let x = e.pageX - scrollableRef.offsetLeft;
        let mouseX = e.screenX;
        if (this.isTouchEvent(e)) {
            x = e.touches[0].pageX - scrollableRef.offsetLeft;
            mouseX = e.touches[0].screenX;
        } else {
            e.preventDefault();
            e.stopPropagation();
        }
        this.setState({
            isClicked: true,
            startX: x,
            mouseStartX: mouseX,
            scrollLeft: scrollableRef.scrollLeft,
            timestamp: Date.now()
        });
        cancelAnimationFrame(this.state.momentumID);
        if (this.state.momentumID !== 0) {
            this.setState({ momentumID: 0 });
        }
    }

    momentumLoop = mouseSpeedX => {
        if (this.allowedOnClickScroll()) {
            this.handleArrows(true);
        }
        const slider = this.scrollableContainer.current;
        slider.scrollLeft += mouseSpeedX; // Apply the velocity to the scroll position
        mouseSpeedX *= 0.95; // Slow the velocity slightly
        if (Math.abs(mouseSpeedX) > 0.5) {
            // Still moving?
            const momentumID = requestAnimationFrame(() => this.momentumLoop(mouseSpeedX)); // Keep looping
            if (momentumID !== this.state.momentumID) {
                this.setState({ momentumID });
            }
        }
    };

    beginMomentumTracking = mouseSpeedX => {
        cancelAnimationFrame(this.state.momentumID);
        const momentumID = requestAnimationFrame(() => this.momentumLoop(mouseSpeedX));
        if (momentumID !== this.state.momentumID) {
            this.setState({ momentumID });
        }
    };

    addInertia(mouseSpeedX) {
        this.beginMomentumTracking(mouseSpeedX);
    }

    scroll(e) {
        if (!this.state.isClicked) return;
        this.setState({ scrolling: true });
        const scrollableRef = this.scrollableContainer.current;
        let scrollingCoefficient = 2;
        let x = e.pageX - scrollableRef.offsetLeft;
        if (this.isTouchEvent(e)) {
            scrollingCoefficient = 0.75;
            x = e.touches[0].pageX - scrollableRef.offsetLeft;
        } else {
            e.preventDefault();
            e.stopPropagation();
        }
        const walk = (x - this.state.startX) / scrollingCoefficient;
        scrollableRef.scrollLeft = this.state.scrollLeft - walk;
    }

    isTouchEvent(event) {
        return !!event.touches;
    }

    addInfiniteScroll() {
        const scrollableRef = this.scrollableContainer.current;
        const childrenContainer = this.children.current;
        //on small screen it is possible that the calculation precision messes up the condition
        const precisionOffset = this.state.infinityScrollPrecisionOffset;
        const containerWidth = this.getContainerWidth();
        if (
            this.hasEnoughChildren() &&
            this.allowedInfiteScroll() &&
            scrollableRef.scrollLeft + containerWidth >= scrollableRef.scrollWidth - precisionOffset
        ) {
            const width = this.state.childWidth;
            const margin = this.state.childMargin;
            const childrenCount = childrenContainer.children.length / 2;
            const childrenOnScreen = this.calculateVisibleChildren();
            const childrenWidth = width * childrenOnScreen;
            const childrenMargin = margin * childrenOnScreen;
            const cutPart = childrenWidth + childrenMargin - window.innerWidth;
            let extra = 0;
            const extraChildrenCount = childrenCount - childrenOnScreen;
            if (extraChildrenCount > 0) {
                extra = (width + margin) * extraChildrenCount;
            }
            const newRight = scrollableRef.scrollLeft - cutPart - extra;
            const newLeft = newRight - window.innerWidth;

            scrollableRef.scrollLeft = newLeft >= 0 ? newLeft : newLeft * -1;
        }
    }

    setClickStartCoords(event) {
        this.setState({
            startClickCoords: {
                x: event.pageX,
                y: event.pageY
            }
        });
    }
    calculateDistance(event) {
        const endClickCoords = {
            x: event.pageX,
            y: event.pageY
        };
        const diffX = this.state.startClickCoords.x - endClickCoords.x;
        const diffY = this.state.startClickCoords.y - endClickCoords.y;

        const distance = Math.sqrt(Math.pow(diffX, 2) + Math.pow(diffY, 2));
        return distance;
    }
    stopChildrenEvents(e) {
        const mouseDistance = this.calculateDistance(e);
        const clickThreshold = this.props.config.clickThreshold || 30;
        if (mouseDistance > clickThreshold) {
            e.stopPropagation();
            e.preventDefault();
        }
    }
    stopScroll(e) {
        let screenX;
        let inertiaCoefficient = 0.1;
        let addInertiaLimit = 20;
        if (!this.isTouchEvent(e)) {
            screenX = e.screenX;
            if (this.state.focused) this.unPauseAnimation();
            this.setState({
                isClicked: false,
                scrolling: false
            });
        } else {
            screenX = e.changedTouches[0].screenX;
            inertiaCoefficient = 0.2;
            addInertiaLimit = 10;
            if (this.state.focused) this.unPauseAnimation();
            this.setState({
                isClicked: false,
                scrolling: false
            });
        }
        const now = Date.now();
        const traveledTime = now - this.state.timestamp;
        const traveledDistance = screenX - this.state.mouseStartX;
        const speedX = Math.round((traveledDistance / traveledTime) * 100) * inertiaCoefficient;
        if (speedX > addInertiaLimit || speedX < addInertiaLimit * -1) {
            this.addInertia(speedX * -1);
        }
    }

    startAnimation() {
        const scrollableRef = this.scrollableContainer.current;
        const shouldHandleArrows = this.allowedOnClickScroll();
        const animation = setInterval(() => {
            if (!this.state.focused) {
                scrollableRef.scrollLeft = Math.ceil(scrollableRef.scrollLeft) + this.state.step;
                this.addInfiniteScroll();
                if (shouldHandleArrows) {
                    this.handleArrows(true);
                }
            }
        }, 15);
        this.setState({ animation, startAnimationTimeout: 0 });
    }

    clearAnimation() {
        window.clearInterval(this.state.animation);
        if (this.state.startAnimationTimeout) {
            window.clearTimeout(this.state.startAnimationTimeout);
        }
    }

    pauseAnimation() {
        this.setState({ focused: true });
    }

    unPauseAnimation() {
        if (
            this.props.data?.properties &&
            this.props.data.properties.hasOwnProperty("pauseScroll") &&
            this.props.data.properties.pauseScroll
        ) {
            return;
        }
        this.setState({
            focused: false,
            isClicked: false,
            scrolling: false
        });
    }
    initiateArrows() {
        if (this.allowedOnClickScroll()) {
            const childrenRef = this.children;
            if (
                this.props.config.targetElements &&
                this.props.data &&
                this.props.data.entities &&
                this.props.data.entities.length > this.calculateVisibleChildren()
            ) {
                //Handle nested children components passed by className in targetElements property
                this.handleArrows(true);
            } else if (
                childrenRef.current &&
                childrenRef.current.children &&
                childrenRef.current.children.length > 1
            ) {
                //Handle the direct childrens passed to the scrollingContainer
                this.handleArrows(true);
            }
        }
    }

    handleArrows = (forceHandle = false, distance = null) => {
        if (this.state.scrolling || forceHandle) {
            const scrollableRef = this.scrollableContainer.current;
            let width = this.state.childWidth + this.state.childMargin;
            const coef = 100;
            distance = distance !== null ? distance : scrollableRef.scrollLeft;
            if (
                this.props.config.targetElements &&
                this.props.data &&
                this.props.data.entities &&
                this.props.data.entities.length > this.calculateVisibleChildren()
            ) {
                const itemsOnScreen = Math.floor(this.getContainerWidth() / width);
                width = this.state.childMargin * (itemsOnScreen - 1);
                width += this.state.childWidth * (itemsOnScreen + 1);
            }
            if (
                Math.ceil(distance + coef + scrollableRef.clientWidth) < scrollableRef.scrollWidth
            ) {
                this.setState({ arrowRight: 1 });
            } else {
                this.setState({ arrowRight: 0 });
            }
            if (distance <= coef) {
                this.setState({ arrowLeft: 0 });
            } else {
                this.setState({ arrowLeft: 1 });
            }
        }
    };

    scrollToSlide = direction => {
        const scrollableRef = this.scrollableContainer.current;
        const browserOffsetBuffer = 3;
        if (
            this.hasEnoughChildren() &&
            this.allowedOnClickScroll() &&
            scrollableRef.scrollLeft + scrollableRef.clientWidth <
                scrollableRef.scrollWidth + browserOffsetBuffer
        ) {
            const width = this.state.childWidth + this.state.childMargin;

            let elementOffset = scrollableRef.scrollLeft % width;
            if (elementOffset + browserOffsetBuffer >= width) {
                elementOffset = 0;
            }
            if (direction === "right") {
                if (scrollableRef.scrollLeft + width !== scrollableRef.scrollWidth) {
                    const distance = scrollableRef.scrollLeft + width - elementOffset;
                    this.scrollToSlideAnimate(scrollableRef, distance);
                }
            } else if (direction === "left") {
                if (elementOffset <= width / 2) {
                    elementOffset += width;
                }
                const distance = scrollableRef.scrollLeft - (width - (width - elementOffset));
                this.scrollToSlideAnimate(scrollableRef, distance);
            }
        }
    };

    scrollToSlideAnimate = (scrollableRef, distance) => {
        const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
        if (isIE11) {
            scrollableRef.scrollLeft = distance;
        } else {
            scrollableRef.scroll({ left: distance, behavior: "smooth" });
        }
        setTimeout(() => {
            this.handleArrows(true, distance);
        }, 200);
    };

    clickHandler = (e, direction) => {
        e.preventDefault();
        cancelAnimationFrame(this.state.momentumID);
        this.pauseAnimation();
        if (!this.state.scrolling) {
            this.scrollToSlide(direction);
        }
    };

    render() {
        const props = extractComponentProps(this.props, {
            additionalProps: [
                "extraClasses",
                "animation",
                "infiniteScroll",
                "showNavigationArrows",
                "showNavigationArrowsSizes",
                "targetElements"
            ],
            propsDefaults: {
                extraClasses: "",
                animation: true,
                infiniteScroll: true,
                showNavigationArrows: false,
                showNavigationArrowsSizes: false,
                targetElements: false
            }
        });
        const children = this.buildChildren();
        const arrows = [];
        if (props.showNavigationArrows) {
            if (this.state.arrowLeft) {
                arrows.push(
                    <div
                        className="un-horizontal-arrow-left"
                        key="arrow-left"
                        onClick={e => this.clickHandler(e, "left")}
                    />
                );
            }
            if (this.state.arrowRight) {
                arrows.push(
                    <div
                        className="un-horizontal-arrow-right"
                        key="arrow-right"
                        onClick={e => this.clickHandler(e, "right")}
                    />
                );
            }
        }
        //const children = this.resolveChildren({ items, entities: items });
        return (
            <div
                className={"un-overflow un-horizontal-scroll " + props.extraClasses}
                ref={this.scrollableContainer}
            >
                <div
                    className={"un-translateX"}
                    onClick={e => {
                        this.pauseAnimation();
                    }}
                >
                    <div
                        ref={this.children}
                        className={"un-horizontal-elements un-translateY noSelect"}
                        onMouseDown={this.initiateScroll}
                        onMouseLeave={this.unPauseAnimation}
                        onClickCapture={this.stopChildrenEvents}
                        onMouseUp={this.stopScroll}
                        onMouseMove={e => {
                            this.pauseAnimation();
                            this.scroll(e);
                            if (this.allowedOnClickScroll()) {
                                this.handleArrows();
                            }
                        }}
                        onTouchStart={e => {
                            this.pauseAnimation(e);
                            this.initiateScroll(e);
                        }}
                        onTouchMove={e => {
                            this.scroll(e);
                            if (this.allowedOnClickScroll()) {
                                this.handleArrows();
                            }
                        }}
                        onTouchEnd={e => {
                            this.stopScroll(e);
                            this.unPauseAnimation(e);
                        }}
                    >
                        {children}
                    </div>
                </div>
                {arrows ? arrows : null}
            </div>
        );
    }
}

export default webui.connectComponent()(
    visibleHOC(ResponsiveHOC(horizontalScrollingContainer), {
        partialVisibility: false
    })
);
