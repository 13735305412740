import React from "react";
import BasicInput from "./BasicInput";
import utils from "@tinqin/tinqin-web-ui/src/app/UtilityService";
import BaseComponent from "@tinqin/tinqin-web-ui/src/components/base/BaseComponent";
import translationService from "@tinqin/tinqin-web-ui/src/app/TranslationService";
import containerUtils from "@tinqin/tinqin-web-ui/src/utils/containerUtils";
import debounce from "@tinqin/tinqin-utils/src/dom/debounce";

export class ReduxInput extends BaseComponent {
    constructor(props) {
        super(props);

        this.onBlur = this.onBlur.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onClickLabelButton = this.onClickLabelButton.bind(this);

        const debounceOptions = props.config.debounce || {};
        const debounceTimeout = debounceOptions.wait || 20;
        const debounceImmediate = !!debounceOptions.immediate;

        this.onChangeDebounced = debounce(
            state => {
                this.saveState(state.value, state);
                this.dispatchSirenActions("change");
            },
            debounceTimeout,
            debounceImmediate
        );
    }
    onBlur(state) {
        this.saveState(state.value, state);
        this.dispatchSirenActions(["default", "focusOut"]);
    }

    onFocus() {
        this.dispatchSirenActions("focusIn");
    }

    onClickLabelButton() {
        this.dispatchSirenActions("clickLabelButton");
    }

    onChange(state) {
        this.onChangeDebounced(state);
    }

    //triggered in case of actionEvent: "keypressEnter"
    //or in case actionOnEnter: true
    onKeyPress(key, state, event) {
        if (key === "Enter") {
            this.saveState(state.value, state);
            this.dispatchSirenActions("pressEnter");
        }
    }

    render() {
        if (this.props.data) {
            const configProps = this.props.config;
            const props = containerUtils.extractComponentProps(this.props, {
                componentProps: BasicInput.propTypes,
                additionalProps: ["isInMultiInput", "phoneNumberInputValidationSchema"],
                propsDefaults: {
                    discreteHint: true,
                    dataType: configProps.validationType
                },
                validatable: true
            });
            if (!props.phoneNumberInputValidationSchema)
                props.phoneNumberInputValidationSchema = this.props.phoneNumberInputValidationSchema;
            props.onChange = this.onChange;
            props.onBlur = this.onBlur;
            props.onFocus = this.onFocus;
            props.onKeyPress = this.onKeyPress;
            props.onClickLabelButton = this.onClickLabelButton;

            // A hint that notifies the user in the,
            // proper language that pasting is not allowed.
            if (props.preventPaste && !props.hint) {
                props.hint = translationService.translate("preventPasteHint");
            }

            //disable letters input in case of numeric or decimal
            if (
                (props.dataType === "numeric" || props.dataType === "decimal") &&
                !props.hasOwnProperty("preventInvalidInput")
            ) {
                props["preventInvalidInput"] = true;
            }

            if (configProps.actionOnEnter || configProps.actionEvent === "keypressEnter") {
                props.onKeyPress = this.onKeyPress;
            }
            return <BasicInput {...props} />;
        }
        return this.getEmptyContent();
    }
}

ReduxInput.displayName = "ReduxInput"; //minification concern

export default utils.connectComponent({
    actions: {
        dispatchSirenActions: "siren.dispatchSirenActions",
        saveState: "component.saveState"
    },
    uiState: true
})(ReduxInput);
