import * as xss from "xss";
const defaultWhiteList: Record<string, string[]> = xss.getDefaultWhiteList();
const additionalWhitelist: Record<string, string[]> = {
    iframe: [
        "frameborder",
        "height",
        "width",
        "marginheight",
        "marginwidth",
        "scrolling",
        "src",
        "allow",
        "allowfullscreen",
        "loading",
        "name",
        "referrerpolicy",
        "sandbox",
        "srcdoc"
    ]
};

export const whiteList: Record<string, string[]> = Object.assign({}, defaultWhiteList, additionalWhitelist);
Object.keys(whiteList).forEach(key => {
    //no "class" attribute is allowed
    whiteList[key].push("style");
    whiteList[key].push("class");
});

export const xssFilter: xss.FilterXSS = new xss.FilterXSS({
    whiteList,
    css: false
});
