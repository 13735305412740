import React from "react";
import PropTypes from "prop-types";
import { xssFilter } from "../../utils/xssFilter";

export default class Button extends React.Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        if (this.props.onClick && !this.props.disabled) {
            this.props.onClick();
        }
    }

    render() {
        const backgroundClass = this.props.background ? ` tq-button-${this.props.background}` : "";
        const extraClasses = this.props.extraClasses ? ` ${this.props.extraClasses}` : "";
        const disabledClass = this.props.disabled ? " tq-disabled" : "";
        const className = `tq-button${extraClasses}${backgroundClass}${disabledClass}`;
        const ariaLabel = this.props.ariaLabel || undefined;
        const id = this.props.id;
        const label = this.props.label;
        const noLabel = this.props.noLabel;
        const icon = this.props.icon;
        const iconAfter = this.props.iconAfter;
        const richTextLabel = this.props.richText;
        const imageSrc = this.props.imageSrc;

        return (
            <button id={id} className={className} onClick={this.onClick} aria-label={ariaLabel}>
                {icon && <i className={icon} />}
                {imageSrc && (
                    <img src={imageSrc} alt={!richTextLabel ? label : xssFilter.process(label)} />
                )}
                {!noLabel && label && !richTextLabel && (
                    <span className="tq-text-node">{label}</span>
                )}
                {!noLabel && label && richTextLabel && (
                    <span
                        className="tq-text-node"
                        dangerouslySetInnerHTML={{ __html: xssFilter.process(label) }}
                    />
                )}
                {iconAfter && <i className={iconAfter} />}
            </button>
        );
    }
}

Button.displayName = "Button";

Button.propTypes = {
    extraClasses: PropTypes.string,
    loadingClass: PropTypes.string,
    ariaLabel: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    noLabel: PropTypes.bool,
    imageSrc: PropTypes.string,
    icon: PropTypes.string,
    iconAfter: PropTypes.string,
    disabled: PropTypes.bool,
    background: PropTypes.string,
    onClick: PropTypes.func
};
