import React, { useState } from "react";
import webui from "@tinqin/tinqin-web-ui";
import { IComponentProps } from "../../utils/commonTypes";

//component for triggering actions when mounted and unmounted
//this solutions uses workaround for React effects guidelines
//such an approach is not recommended for any other purpose
//copying props to state, will make sure useEffect is called only once on mounting and unmounting
export const Trigger: React.FC<IComponentProps> = props => {
    const [stateProps] = useState(props);
    React.useEffect(() => {
        // webui.log("Triggering mount actions for " + stateProps.parentBindTo);
        webui.getUtils().dispatchSirenActions(stateProps);
        return () => {
            // webui.log("Triggering unmount actions for " + stateProps.parentBindTo);
            webui.getUtils().dispatchSirenActions(stateProps, ["unmount"]);
        };
    }, [stateProps]);
    return null;
};

export default webui.connectComponent()(Trigger);
