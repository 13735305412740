import webui from "@tinqin/tinqin-web-ui";
import { ISirenAction, TAppThunk } from "../../utils/commonTypes";

const { getEntityValue, getEntityProps, setEntityValue } = webui.getActionByName("utils");

export const triggerMatomoSiteSearch = (
    keyword: string,
    category: string | false = false,
    resCount: number | false = false
) => {
    if (window._paq) {
        window._paq.push([
            "trackSiteSearch",
            // Search keyword searched for
            keyword,
            // Search category selected in your search engine. If you do not need this, set to false
            category,
            // Number of results on the Search results page. Zero indicates a 'No Result Search Keyword'. Set to false if you don't know
            resCount
        ]);
    }
};

export const trackSiteSearch = (
    sirenAction: ISirenAction,
    _widgetId: string,
    _currentPath: string
): TAppThunk => dispatch => {
    const source = sirenAction?.sources?.[0] || "header.search.input";
    const searchString = dispatch(getEntityValue(source));
    if (!searchString) return;
    const categoryId = dispatch(getEntityValue("search.filterSection.filters"));
    const categoryLabel = dispatch(
        getEntityProps(`search.filterSection.filters.${categoryId}`, "label")
    );
    let resultsCount = dispatch(getEntityValue(`search.filterSection.filters.${categoryId}.count`));
    if (!resultsCount) {
        resultsCount = dispatch(getEntityValue("search.results.total")) || 0;
    }
    triggerMatomoSiteSearch(searchString, categoryLabel, resultsCount);
    //Clear value from the search input
    if (sirenAction.properties?.clearValue) {
        dispatch(setEntityValue(source, ""));
    }
};
