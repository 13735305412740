import { getFieldValue } from "./utils";

export default (sirenAction, widgetId, currentPath) => {
    //Items in targetDependencies will be strings representing the classes that identify the element(s) to impact.
    const targets = sirenAction.targetDependencies || sirenAction.targets || [];
    const fields = sirenAction.fields || [];
    const selectBy = getFieldValue(fields, "selectBy") || "id";
    return (dispatch, getState) => {
        targets.forEach(target => {
            let element;
            if (selectBy === "class") {
                const elements = document.getElementsByClassName(target);
                element = elements.item(0);
            } else {
                element = document.getElementById(target);
            }
            element.style.display = "none";
        });
    };
};
