import React, { useCallback } from "react";
import webui from "@tinqin/tinqin-web-ui";
import EntityBuilder from "@tinqin/tinqin-web-ui/src/utils/EntityBuilder";
import { xssFilter } from "../../utils/xssFilter";
import { getAddressFromActions } from "../../actions/page/PageAddress";

const BannerSuperStory = props => {
    const dataProps = webui.getUtils().extractComponentProps(props, {
        additionalProps: ["extraClasses", "titleTag", "tag"],
        propsDefaults: {
            extraClasses: "",
            titleTag: "h3",
            tag: "div"
        }
    });
    const extractData = useCallback(() => {
        const siren = EntityBuilder.of(props.data);
        const imageSrc = siren.getChild("image").getProperty("src") || "";
        const title = siren.getChild("title").getValue();
        const storyTag = siren.getChild("tag").getValue();

        return {
            imageSrc,
            title,
            storyTag
        };
    }, [props.data]);

    const onClick = useCallback(
        e => {
            e.preventDefault();
            webui.getUtils().dispatchSirenActions(props, ["default", "click"]);
        },
        [props]
    );

    if (!props.data) {
        return webui.getUtils().getEmptyContent();
    }
    const children = [];
    const { imageSrc, title, storyTag } = extractData();
    if (storyTag) {
        children.push(
            <span className={"tq-badge"} key={children.length}>
                <span className={"tq-text-node"}>{storyTag}</span>
            </span>
        );
    }
    if (title) {
        const address = getAddressFromActions(props.data.actions);
        const componentTitle = (
            <a href={address} key={children.length}>
                {React.createElement(
                    dataProps.titleTag,
                    {
                        className: "tq-panel-title"
                    },
                    <span
                        className="tq-text-node"
                        dangerouslySetInnerHTML={{
                            __html: xssFilter.process(title)
                        }}
                    ></span>
                )}
            </a>
        );
        children.push(componentTitle);
    }
    return React.createElement(
        dataProps.tag,
        {
            className: dataProps.extraClasses,
            onClick,
            style: {
                backgroundImage: `url("${imageSrc}")`
            }
        },
        children
    );
};

export default webui.connectComponent()(BannerSuperStory);
