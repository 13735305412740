import React from "react";
import webui, { BaseComponent } from "@tinqin/tinqin-web-ui";
import "@tinqin/design-uneo-site-cp/less/browser-upgrade.less";

// http://jira.tinqin.com/browse/UGP-1814
const unsupportedBrowsersLT = {
    chrome: { version: 76, updateUrl: "https://www.google.com/chrome/" },
    crios: { version: 56, updateUrl: "https://apps.apple.com/us/app/google-chrome/id535886823" },
    fxios: {
        version: 15,
        updateUrl: "https://apps.apple.com/us/app/firefox-private-safe-browser/id989804926"
    },
    safari: {
        mac: { version: 13, updateUrl: "http://support.apple.com/en-us/HT204416" },
        win: { version: 5, updateUrl: "http://support.apple.com/en-us/HT204416" }
    },
    firefox: { version: 72, updateUrl: "https://www.mozilla.org/en-GB/firefox/new/" },
    edg: { version: 74, updateUrl: "https://www.microsoft.com/en-us/edge" },
    samsungbrowser: {
        version: 9,
        updateUrl:
            "https://play.google.com/store/apps/details?id=com.sec.android.app.sbrowser&hl=en&gl=us"
    }
};
export function getBrowserData() {
    // http://jira.tinqin.com/browse/UGP-1814
    const { userAgent } = window.navigator;
    let browser;
    let osName, updateUrl;
    let isSafari = false;
    //  https://developer.mozilla.org/en-US/docs/Web/API/Window/navigator
    if (userAgent.indexOf("SamsungBrowser") > -1) {
        browser = "SamsungBrowser";
    } else if (userAgent.indexOf("Edg") > -1) {
        browser = "Edg";
    } else if (userAgent.indexOf("Chrome") > -1) {
        browser = "Chrome";
    } else if (userAgent.indexOf("Firefox") > -1) {
        browser = "Firefox";
    } else if (userAgent.indexOf("CriOS") > -1) {
        browser = "CriOS";
    } else if (userAgent.indexOf("FxiOS") > -1) {
        browser = "FxiOS";
    } else if (userAgent.indexOf("Safari") > -1) {
        browser = "Safari";
        isSafari = true;
        if (userAgent.indexOf("Windows") > -1) {
            osName = "win";
        } else if (userAgent.indexOf("Mac") > -1) {
            osName = "mac";
        }
    }
    if (!browser) {
        return false;
    }
    const versionString = isSafari ? "Version" : browser;
    const browserInfo = userAgent.split(" ").find(info => info.includes(versionString));
    const browserVersion = Number(
        browserInfo
            .split("/")
            .pop()
            .split(".")[0]
    );
    if (browser === "Safari") {
        if (!osName) {
            return false;
        }
        updateUrl = unsupportedBrowsersLT[browser.toLowerCase()][osName].updateUrl;
        return {
            browserName: browser,
            osName: osName,
            browserUpdateUrl: updateUrl,
            browserVersion: browserVersion
        };
    } else {
        updateUrl = unsupportedBrowsersLT[browser.toLowerCase()].updateUrl;
        return {
            browserName: browser,
            browserUpdateUrl: updateUrl,
            browserVersion: browserVersion
        };
    }
}
class SupportedBrowsersUpdateNotification extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            browser: null
        };
        this.closeMessage = this.closeMessage.bind(this);
        this.openLink = this.openLink.bind(this);
    }

    componentDidMount() {
        if (this.shouldDisplay()) {
            const currentBrowserData = getBrowserData();
            this.setState({ browser: currentBrowserData, visible: true });
        }
    }

    closeMessage() {
        this.setState({ visible: false });
    }

    shouldDisplay() {
        const currentBrowserData = getBrowserData();
        if (currentBrowserData) {
            if (currentBrowserData.browserName === "Safari") {
                if (currentBrowserData.hasOwnProperty("osName")) {
                    return (
                        currentBrowserData.browserVersion <
                        unsupportedBrowsersLT[currentBrowserData.browserName.toLowerCase()][
                            currentBrowserData.osName
                        ].version
                    );
                }
                return false;
            }
            return (
                currentBrowserData.browserVersion <
                unsupportedBrowsersLT[currentBrowserData.browserName.toLowerCase()].version
            );
        }
        return false;
    }

    openLink(url) {
        window.open(url, "_blank");
    }

    render() {
        return (
            this.state.visible && (
                <div className="un-browser-upgrade">
                    <p>
                        Votre navigateur Web (
                        {this.state.browser.browserName !== "Edg"
                            ? this.state.browser.browserName
                            : this.state.browser.browserName + "e"}
                        ) n’est pas à jour. Mettez à jour votre navigateur pour plus de sécurité et
                        rapidité et une meilleure expérience sur ce site.
                    </p>
                    <div className="un-browser-upgrade-btns">
                        <button
                            className="tq-button un-button-green"
                            onClick={() => this.openLink(this.state.browser.browserUpdateUrl)}
                        >
                            <span className="tq-text-node">Mettre à jour le navigateur</span>
                        </button>
                        <button
                            className="tq-button un-button-border-gray tq-button-border"
                            onClick={this.closeMessage}
                        >
                            <span className="tq-text-node">Ignorer</span>
                        </button>
                    </div>
                </div>
            )
        );
    }
}

export default webui.connectComponent()(SupportedBrowsersUpdateNotification);
