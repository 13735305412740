import webui from "@tinqin/tinqin-web-ui";
const { getEntityName, getEntityChildren } = webui.getActionByName("utils");

export const getFieldValue = (fields, name) => {
    const currentField = fields.find(field => field.name === name);
    return currentField ? currentField.value : undefined;
};

//========= Utility for managing the font size ======================================
//Gets the size of computed style porperty of an element, which are passed as arguments
// the property should have a numeric value and some units (ex. padding-left/right, font-size etc.)
// not working for "font-family" for example.
function getCurrentPropertyAsNumber(element, porpertyName) {
    const currentPropertySize = getComputedStyle(element)[porpertyName];
    const porpertySizeNumber = Number(currentPropertySize.replaceAll(/[^0-9.]+/g, ""));
    return porpertySizeNumber;
}

//calculates the width of the simulator to determine if the font should be shrinked or enlarged
export function adjustFontSize(input, simulator, initalFontSize) {
    if (!input || !simulator) {
        return false;
    }
    let fontSizeNumber = getCurrentPropertyAsNumber(input, "font-size");
    while (parseInt(simulator.clientWidth) > parseInt(input.clientWidth)) {
        simulator.style.fontSize = parseFloat(fontSizeNumber) - 1 + "px";
        input.style.fontSize = parseFloat(fontSizeNumber) - 1 + "px";
        fontSizeNumber = getCurrentPropertyAsNumber(simulator, "font-size");
    }
    while (
        parseInt(simulator.clientWidth) < parseInt(input.clientWidth) &&
        fontSizeNumber + 1 <= initalFontSize
    ) {
        simulator.style.fontSize = parseFloat(fontSizeNumber) + 1 + "px";
        input.style.fontSize = parseFloat(fontSizeNumber) + 1 + "px";
        fontSizeNumber = getCurrentPropertyAsNumber(simulator, "font-size");
    }
}

export function validateNumberOnInput(
    inputValue,
    prevInputValue,
    minValue = 0,
    maxValue = 99999.99,
    maxDecimalDigits = 2
) {
    const maxIntDigits = parseInt(maxValue).toString().length;
    let regex = new RegExp(`^\\d{0,${maxIntDigits}}(\\.\\d{0,${maxDecimalDigits}})?$`, "gm");

    if (minValue < 0) {
        regex = new RegExp(`^-?\\d{0,${maxIntDigits}}(\\.\\d{0,${maxDecimalDigits}})?$`, "gm");
    }
    inputValue = inputValue.replaceAll(/,/g, ".");
    if (
        !regex.test(inputValue) ||
        (regex.test(inputValue) && (Number(inputValue) < minValue || Number(inputValue) > maxValue))
    ) {
        if (isNaN(inputValue)) {
            return prevInputValue;
        }
        if (
            (inputValue.length === maxIntDigits + 1 && inputValue >= 0) ||
            (inputValue.length === maxIntDigits + 2 && inputValue < 0)
        ) {
            inputValue = `${inputValue.substr(0, inputValue.length - 1)}.${
                inputValue[inputValue.length - 1]
            }`;
        }
        if (Number(inputValue) > maxValue) {
            inputValue = maxValue;
        } else if (Number(inputValue) < minValue) {
            inputValue = minValue;
        } else if (inputValue.includes(".")) {
            const [number, decimals] = inputValue.split(".");
            if (decimals.length > maxDecimalDigits) {
                return prevInputValue;
            }
            inputValue = `${number}.${decimals}`;
        } else {
            if (inputValue.length > maxIntDigits + 1 + maxDecimalDigits) {
                return prevInputValue;
            }
        }
    } else {
        if (inputValue.startsWith(".")) {
            inputValue = `0${inputValue}`;
        }
        if (inputValue.startsWith("-.")) {
            inputValue = `-0${inputValue.slice(1)}`;
        }
        if (/^0\d/.test(inputValue)) {
            inputValue = `${inputValue.slice(1)}`;
        }
    }

    return inputValue;
}

export function getAllEntityOccurrences(path = "", source = "") {
    return dispatch => {
        const occurrences = [];
        const searchedEntity = getEntityName(path);
        const children = dispatch(getEntityChildren(source));
        return occurrences.concat(searchInDepth(children, source, searchedEntity, occurrences));
    };
}

function searchInDepth(children, path, searchedEntity, occurrences) {
    if (children.length) {
        for (const child of children) {
            if (child.hasOwnProperty("class")) {
                const newPath = `${path}.${child.class[0]}`;
                if (getEntityName(newPath) === searchedEntity) {
                    occurrences.push(newPath);
                }
                if (child.hasOwnProperty("entities")) {
                    const newSource = child.entities;
                    searchInDepth(newSource, newPath, searchedEntity, occurrences);
                }
            }
        }
    }
    return occurrences;
}
