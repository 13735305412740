import React from "react";
import ReactDOM from "react-dom";
import webui, { BaseComponent } from "@tinqin/tinqin-web-ui";
import { ReduxInput as Input } from "./ReduxInput";

class InputPhone extends BaseComponent {
    componentDidMount() {
        if (this.input) {
            this.input.focus();
        }
    }

    render() {
        return (
            <Input
                ref={component => {
                    const element = ReactDOM.findDOMNode(component);
                    if (element) {
                        this.input = element.querySelector(".tq-input-phone");
                    }
                }}
                phoneNumberInputValidationSchema={/^((\+3?)?|(\+33|0)(\s?\d){0,9}\s?)$/m}
                {...this.props}
            />
        );
    }
}
export default webui.connectComponent({
    actions: {
        triggerSet: "component.triggerSet",
        dispatchSirenActions: "siren.dispatchSirenActions",
        saveState: "component.saveState"
    }
})(InputPhone);
