export default (sirenAction, widgetId, currentPath) => {
    return () => {
        const currentPathArr = currentPath.split(".");
        currentPathArr.pop();
        const targetParentPath = currentPathArr.join(".");
        const targetParent = document.querySelector(`[data-tq-id="${targetParentPath}"]`);
        if (!targetParent) {
            return;
        }
        const target = targetParent.querySelector('[data-tq-id*="contactFormSection"]');
        if (!target) {
            return;
        }
        target.classList.toggle("tq-hide");
    };
};
