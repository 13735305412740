import React from "react";
import webui, { BaseComponent } from "@tinqin/tinqin-web-ui";
import ResponsiveHOC from "../../../HOC/components/responsive";
import { xssFilter } from "../../utils/xssFilter";
import "@tinqin/design-uneo-site-cp/less/form-slider.less";

const extractComponentProps = webui.getUtils().extractComponentProps;

class StepBulletSlider extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            isClicked: false,
            startX: 0,
            closestCenter: 0,
            leftBorder: 0,
            rightBorder: Number.MAX_SAFE_INTEGER
        };
        this.initiateScroll = this.initiateScroll.bind(this);
        this.scroll = this.scroll.bind(this);
        this.stopScroll = this.stopScroll.bind(this);
        this.onLineClick = this.onLineClick.bind(this);
        this.slider = React.createRef();
    }

    initiateScroll(e) {
        const { centers } = this.getLocationsOfOptions(e);
        let x = e.pageX - this.slider.current.offsetLeft;
        if (!!e.touches) {
            x = e.touches[0].pageX - this.slider.current.offsetLeft;
        } else {
            window.addEventListener("mousemove", this.scroll);
            window.addEventListener("mouseup", this.stopScroll);
        }
        this.setState({
            isClicked: true,
            startX: x,
            rightBorder: centers[centers.length - 1]
        });
    }

    scroll(e) {
        if (this.state.isClicked) {
            let scrollingCoefficient = 2;
            let x = e.pageX - this.slider.current.offsetLeft;
            if (!!e.touches) {
                x = e.touches[0].pageX - this.slider.current.offsetLeft;
                scrollingCoefficient = 4;
            }
            const walk = (x - this.state.startX) / scrollingCoefficient;
            let marginLeft = this.slider.current.style.marginLeft;
            marginLeft = +marginLeft.slice(0, marginLeft.length - 2);
            if (marginLeft + walk > this.state.rightBorder) {
                this.slider.current.style.marginLeft = `${this.state.rightBorder}px`;
            } else if (marginLeft + walk < this.state.leftBorder) {
                this.slider.current.style.marginLeft = `${this.state.leftBorder}px`;
            } else {
                this.slider.current.style.marginLeft = `${marginLeft + walk}px`;
            }
        }
    }

    stopScroll(e) {
        if (this.state.isClicked) {
            this.onLineClick(e);
            this.setState({ isClicked: false });
            if (!e.touches) {
                window.removeEventListener("mousemove", this.scroll);
                window.removeEventListener("mouseup", this.stopScroll);
            }
        }
    }

    getXPosition(event) {
        if (!!event.touches) {
            return event.changedTouches[0].clientX;
        } else {
            return event.clientX;
        }
    }

    getLocationsOfOptions(e) {
        const options = this.buildEnumData() || [];
        const optCoords = options.map(opt => {
            const currentElementRect = document
                .getElementById(opt.properties.id)
                .getBoundingClientRect();
            return [currentElementRect.left, currentElementRect.right];
        });
        const distances = [];
        let centers = [];
        const xPos = this.getXPosition(e);
        optCoords.forEach(optCoord => {
            const center = (optCoord[1] - optCoord[0]) / 2 + optCoord[0];
            let distance = xPos - center;
            if (distance < 0) distance *= -1;
            distances.push(distance);
            centers.push(center);
        });
        centers = centers.map(c => c - optCoords[0][0] - 6);

        return { distances, centers };
    }

    onLineClick(e) {
        const options = this.buildEnumData() || [];
        const { distances, centers } = this.getLocationsOfOptions(e);
        const firstElementRect = document
            .getElementById(options[0].properties.id)
            .getBoundingClientRect();
        const xPos = this.getXPosition(e);
        const closestDistance = distances.indexOf(Math.min(...distances));
        this.setState({
            closestCenter: centers[closestDistance],
            leftBorder: 0,
            rightBorder: centers[centers.length - 1]
        });
        if (xPos < firstElementRect.left + 25) {
            this.saveState(undefined);
            this.slider.current.style.marginLeft = "0px";
        } else if (this.props.data.properties.value === options[closestDistance].properties.id) {
            this.slider.current.style.marginLeft = centers[closestDistance] + "px";
        } else {
            this.saveState(options[closestDistance].properties.id);
        }
    }

    buildItems() {
        const itemsData = this.buildEnumData() || [];
        const count = itemsData.length;
        return itemsData.map((item, index) => {
            const props = item.properties || {};
            const code = props.code || props.id;
            const left = `${index * (77 / (count - 1))}%`;
            return (
                <div
                    key={`option-${index}`}
                    className={"un-range-slider-positions tq-clickable"}
                    id={code}
                    data-code={code}
                    onClick={this.onLineClick}
                    style={{ left: left }}
                    dangerouslySetInnerHTML={{
                        __html: xssFilter.process(props.label)
                    }}
                />
            );
        });
    }

    buildTrack(props) {
        //The component doesn't support predefined values for the slider!!
        const style = {};
        if (props.hasOwnProperty("value") && props.value !== undefined && props.value !== null) {
            style.marginLeft = this.state.closestCenter + "px";
            style.cursor = "pointer";
        }
        return (
            <div
                onMouseDown={this.initiateScroll}
                onTouchStart={this.initiateScroll}
                onTouchMove={this.scroll}
                ref={this.slider}
                className="un-range-slider-track"
                style={style}
            />
        );
    }

    render() {
        const props = extractComponentProps(this.props, {
            additionalProps: ["label", "value"]
        });
        const options = this.buildItems();
        const track = this.buildTrack(props);
        return [
            <h3 key="title" className="tq-title noSelect">
                <span className="tq-text-node">{props.label}</span>
            </h3>,
            <div key="slider" className="un-profile-slider noSelect">
                <div className="un-range-slider">
                    <div className="un-range-slider-flex">
                        <div className="tq-relative">{options}</div>
                    </div>
                    <div
                        className="un-range-slider-line"
                        onClick={this.onLineClick}
                        onTouchEnd={this.stopScroll}
                        style={{ position: "relative", cursor: "pointer" }}
                    >
                        {track}
                    </div>
                </div>
            </div>
        ];
    }
}

export default webui.connectComponent({
    actions: {
        dispatchSirenActions: "siren.dispatchSirenActions",
        saveState: "component.saveState"
    }
})(ResponsiveHOC(StepBulletSlider));
