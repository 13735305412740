import React from "react";
import Dropdown from "./Dropdown";
import typeOfOperand from "@tinqin/tinqin-utils/src/base/typeOfOperand";
import webui, { BaseComponent } from "@tinqin/tinqin-web-ui";
import containerUtils from "@tinqin/tinqin-web-ui/src/utils";

export class UneoDropdown extends BaseComponent {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onOpen = this.onOpen.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onClickLabelButton = this.onClickLabelButton.bind(this);
        this.onChangeSuggestionValue = this.onChangeSuggestionValue.bind(this);
    }

    onClickLabelButton() {
        this.dispatchSirenActions("clickLabelButton");
    }

    onChange(state) {
        let newValue = state.selection ? state.selection : null;

        const props = containerUtils.extractComponentProps(this.props, {
            additionalProps: ["arrayValue"]
        });

        //We are doing the || check because in this case we are using the multi selection as a single selection with deselect and we want the data to be the same as the data for single selection
        if (
            (!this.props.config.multiSelection && !props.arrayValue) ||
            (this.props.config.multiSelection && this.props.config.maxSelection === 1)
        ) {
            newValue = state.selection[0];
        }
        //Propagate the news to the store.
        this.saveState(newValue, state);
        this.dispatchActions(state, "change");
    }

    onChangeSuggestionValue(state) {
        let newValue = state.selection ? state.selection : null;
        if (!this.props.config.multiSelection) {
            newValue = state.selection[0];
        }
        const suggestionValue = state.suggestionValue;
        //Propagate the news to the store.
        this.saveState(newValue, state, { suggestionValue });
        //Note: may be we want to debounce here!?.
        this.dispatchSirenActions("changeSuggestionValue");
    }

    onBlur(state) {
        //Note: we have already called saveState so we skip it here!
        this.dispatchActions(state, ["default", "focusOut"]);
    }

    onFocus(state) {
        this.dispatchActions(state, "focusIn");
    }

    onOpen(state) {
        this.dispatchActions(state, "open");
    }

    onClose(state) {
        this.dispatchActions(state, "close");
    }

    dispatchActions(state, event) {
        let selection = state.selection;
        if (
            !this.props.config.multiSelection ||
            (this.props.config.multiSelection && this.props.config.maxSelection === 1)
        ) {
            selection = state.selection[0];
        }
        const entitiesData = this.buildEnumData();
        const selectedOption = entitiesData.find(entity => {
            const entityProps = entity.properties || {};
            return entityProps.id === selection;
        });
        const entityActions = {};
        if (selectedOption && selectedOption.actions) {
            entityActions["actions"] = selectedOption.actions;
        }

        this.dispatchSirenActions(event, entityActions);
    }

    buildItems(properties) {
        const options = new Map();
        const selection = properties.selection || [];
        const itemsData = this.buildEnumData();
        itemsData.forEach(item => {
            const props = item.properties || {};
            let specialDisabled = false;
            if (selection.length >= properties.selectionSize && !selection.includes(props.id)) {
                specialDisabled = true;
            }
            let disabled = !!props.disabled;
            if (props.hasOwnProperty("enabled")) {
                disabled = !props.enabled;
            }
            if (props.id) {
                options.set(props.id, {
                    label: props.label,
                    icon: props.icon,
                    code: props.id,
                    disabled: disabled || specialDisabled
                });
            }
        });

        return options;
    }

    componentDidMount() {
        this.dispatchSirenActions("mount");
    }

    render() {
        const data = this.props.data;
        if (data) {
            let defaultMultiSelection = false;
            //Note: For some time we will support both multiselect and dropdown in config type.
            if (this.props.config.type === "multiselect") {
                //todo consider
                defaultMultiSelection = true;
            }
            const props = containerUtils.extractComponentProps(this.props, {
                componentProps: Dropdown.propTypes,
                additionalProps: ["isInMultiInput", "autoSearchableCount", "selectionPrefix"],
                //For the single selection case noneSelectedText is interpret as placeholder
                aliases: { selection: "value", noneSelectedText: "placeholder" },
                propsDefaults: {
                    filterStrategy: "includes",
                    multiSelection: defaultMultiSelection,
                    selection: [],
                    selectionPrefix: ""
                },
                validatable: true
            });

            //Note: tinqin-ui-components works only with arrays.
            if (typeOfOperand(props.selection) !== "array") {
                props.selection = [props.selection];
            }
            props.options = this.buildItems(props);

            props.onChangeSuggestionValue = this.onChangeSuggestionValue;
            props.onChange = this.onChange;
            props.onBlur = this.onBlur;
            props.onFocus = this.onFocus;
            props.onOpen = this.onOpen;
            props.onClose = this.onClose;
            props.onClickLabelButton = this.onClickLabelButton;
            if (props.options.size > props.autoSearchableCount) {
                props.autosuggestion = true;
            }

            return <Dropdown {...props} />;
        }

        return this.getEmptyContent();
    }
}

UneoDropdown.displayName = "UneoDropdown"; //minification concern

export default webui.connectComponent({
    actions: {
        dispatchSirenActions: "siren.dispatchSirenActions",
        saveState: "component.saveState"
    },
    uiState: true
})(UneoDropdown);
