import React, { useCallback, useMemo } from "react";
import webui from "@tinqin/tinqin-web-ui";
import { IComponentProps, SirenEntityBuilder } from "../../utils/commonTypes";
import EntityBuilder from "@tinqin/tinqin-web-ui/src/utils/EntityBuilder";

export const BackgroundContainer: React.FC<IComponentProps> = props => {
    const onClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.preventDefault();
            webui.getUtils().dispatchSirenActions(props, ["default", "click"]);
        },
        [props]
    );

    const getBackgroundClass = useMemo(() => {
        if (props.data) {
            const siren: SirenEntityBuilder = EntityBuilder.of(props.data);
            return siren.getChild("color").getValue();
        }
    }, [props]);

    if (!props.data) {
        return webui.getUtils().getEmptyContent();
    }

    const {
        extraClasses,
        defaultBgColor
    }: {
        extraClasses: string;
        defaultBgColor: string;
    } = webui.getUtils().extractComponentProps(props, {
        additionalProps: ["defaultBgColor", "extraClasses"],
        propsDefaults: {
            extraClasses: "",
            defaultBgColor: ""
        }
    });
    let backgroundClass = getBackgroundClass || defaultBgColor;
    backgroundClass = backgroundClass ? ` un-background-${backgroundClass}` : "";


    return (
        <div
            className={`${extraClasses}${backgroundClass}`}
            onClick={e => onClick(e)}
        >
            {webui.getUtils().resolveChildren(props)}
        </div>
    );
};

export default webui.connectComponent()(BackgroundContainer);