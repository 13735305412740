import React from "react";

const Grid = props => {
    let dataTqId = {};
    if (props.dataTqId) {
        dataTqId = { "data-tq-id": props.dataTqId };
    }

    return React.createElement(
        props.tag,
        {
            className: `tq-grid-a ${props.extraClasses}`,
            ...dataTqId
        },
        props.children
    );
};

export default Grid;
