import { getFieldValue } from "./utils";

export default (sirenAction, widgetId, currentPath) => {
    //Items in targetDependencies will be strings representing the classes that identify the element(s) to impact.
    const targets = sirenAction.targetDependencies || sirenAction.targets || [];
    const fields = sirenAction.fields || [];
    const toRemove = getFieldValue(fields, "toRemove");
    return (dispatch, getState) => {
        if (toRemove) {
            targets.forEach(target => {
                const elements = document.getElementsByClassName(target);
                for (let i = 0; i < elements.length; i++) {
                    const element = elements.item(i);
                    element.classList.remove(toRemove);
                }
            });
        }
    };
};
