import React, { useCallback, useRef } from "react";
import webui from "@tinqin/tinqin-web-ui";
import { IComponentProps } from "../../utils/commonTypes";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";

export const BackgroundImageContainer: React.FC<IComponentProps> = props => {
    const ref: React.MutableRefObject<HTMLDivElement> = useRef<HTMLDivElement | null>(null);
    //InterSectionObserver determines if the dom element is in the viewport
    const isVisible = useIntersectionObserver(ref);
    const onClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.preventDefault();
            webui.getUtils().dispatchSirenActions(props, ["default", "click"]);
        },
        [props]
    );
    if (!props.data) {
        return webui.getUtils().getEmptyContent();
    }
    const {
        src,
        extraClasses,
        contentName,
        contentPiece
    }: {
        src?: string;
        extraClasses: string;
        contentName: string;
        contentPiece: string;
    } = webui.getUtils().extractComponentProps(props, {
        additionalProps: ["src", "extraClasses", "contentName", "contentPiece"],
        propsDefaults: {
            extraClasses: "",
            contentName: "",
            contentPiece: ""
        }
    });
    const styles: React.CSSProperties = {
        backgroundImage: `url("${src}")`
    };
    const attributes = {};
    if (contentName) {
        attributes["data-content-name"] = contentName;
    }
    if (contentPiece) {
        attributes["data-content-piece"] = contentPiece;
    }
    if (contentName || contentPiece) {
        attributes["data-content-target"] = src;
    }
    return (
        <div
            ref={ref}
            className={extraClasses}
            style={isVisible ? styles : null}
            onClick={e => onClick(e)}
            {...attributes}
        >
            {webui.getUtils().resolveChildren(props)}
        </div>
    );
};

export default webui.connectComponent()(BackgroundImageContainer);