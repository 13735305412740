import React from "react";
import webui, { BaseComponent } from "@tinqin/tinqin-web-ui";
import EntityBuilder from "@tinqin/tinqin-web-ui/src/utils/EntityBuilder";
import CheckboxItem from "@tinqin/tinqin-ui-components/src/checkbox-group/CheckboxItem";
import { xssFilter } from "../../utils/xssFilter";

const DONOTDISPLAYFIELD = "doNotDisplay";

class MaintenancePopUp extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
            visible: true,
            doNotDisplay: false,
            active: false,
            showModal: true
        };
        this.closeModal = this.closeModal.bind(this);
        this.handelCLick = this.handelCLick.bind(this);
        this.handleShowModal = this.handleShowModal.bind(this);
    }

    componentDidUpdate() {
        if (!this.props.globalProperties.activeModal && !this.state.showModal) {
            this.props.setGlobalProperty({ name: "activeModal", value: true });
            this.setState({ showModal: true });
        }
    }

    componentDidMount() {
        if (localStorage.hasOwnProperty(DONOTDISPLAYFIELD)) {
            let endDate = localStorage.getItem(DONOTDISPLAYFIELD).split("T");
            endDate = new Date(endDate[0] + " " + endDate[1]);
            const today = new Date();
            if (endDate < today) {
                localStorage.removeItem(DONOTDISPLAYFIELD);
                this.handleShowModal();
            } else {
                this.setState({ doNotDisplay: true });
            }
        } else {
            this.handleShowModal();
        }
    }

    handleShowModal() {
        if (!this.props.globalProperties.activeModal && this.state.showModal) {
            this.props.setGlobalProperty({ name: "activeModal", value: true });
        } else {
            this.setState({ showModal: false });
        }
    }

    closeModal() {
        this.setState({ visible: false, showModal: false });
        if (localStorage.hasOwnProperty(DONOTDISPLAYFIELD)) {
            this.setState({ doNotDisplay: true });
        }
        //settimeout adds some delay between closing and opening the next modal
        setTimeout(() => {
            this.props.setGlobalProperty({ name: "activeModal", value: false });
        }, 1000);
    }

    handelCLick(date) {
        this.setState({ active: !this.state.active });
        if (this.state.active) {
            localStorage.removeItem(DONOTDISPLAYFIELD);
        } else {
            localStorage.setItem(DONOTDISPLAYFIELD, date);
        }
    }

    render() {
        if (!this.props.data) return this.getEmptyContent();
        const dataEntity = EntityBuilder.of(this.props.data, { inplace: true });
        const title = dataEntity.getChild("title").getValue();
        const description = dataEntity.getChild("description").getValue();
        const doNotDisplay = dataEntity.getChild("doNotDisplay").getValue();
        const endDate = dataEntity.getChild("endDate").getValue();
        return (
            this.state.showModal &&
            this.state.visible &&
            !this.state.doNotDisplay && (
                <div
                    className="tq-modal tq-modal-visible un-custom-modal un-maintenance-modal"
                    id="modal-sizes"
                >
                    <div className="tq-modal-overlay" />
                    <div className="tq-modal-l tq-modal-container">
                        <header className="tq-modal-header">
                            <div className="tq-sub-title">
                                <ul className="tq-menu tq-borders tq-selectable">
                                    <li onClick={this.closeModal}>
                                        <span className="tq-item">
                                            <i className="tq-icon-close" />
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </header>
                        <div className="tq-modal-scroll">
                            <main className="tq-modal-content">
                                <h3 className="un-modal-title">
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: xssFilter.process(title)
                                        }}
                                    />
                                </h3>
                                <p
                                    className="un-modal-subtitle"
                                    dangerouslySetInnerHTML={{
                                        __html: xssFilter.process(description)
                                    }}
                                    data-hyperlinks-container={true}
                                />
                                <div
                                    className="tq-form-element tq-vertical tq-no-label un-maintenance-checkbox"
                                    data-tq-id="home-corporate.event-calendar.public-access"
                                >
                                    <div className="tq-input-container tq-checkbox-group tq-horizontal-group">
                                        <CheckboxItem
                                            value={""}
                                            label={doNotDisplay}
                                            data-tq-id="home-corporate.event-calendar.public-access"
                                            active={this.state.active}
                                            onClick={() => this.handelCLick(endDate)}
                                        />
                                    </div>
                                </div>
                            </main>
                        </div>
                        <footer className="tq-modal-footer" />
                    </div>
                </div>
            )
        );
    }
}
export default webui.connectComponent({
    globalProperties: ["activeModal"],
    actions: {
        dispatchSirenActions: "siren.dispatchSirenActions",
        setGlobalProperty: "global.setGlobalProperty"
    }
})(MaintenancePopUp);
