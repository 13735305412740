import webui from "@tinqin/tinqin-web-ui";
import ResponsiveHOC from "../../../HOC/components/responsive/index";

//container for displaying different components based on screenSize instead of config.type
//to consider this component for default component when there is no config.type but there is config.bindTo
const ResponsiveVideoComponent = props => {
    const getChildrenByScreenSize = ({
        customPlayButton,
        extraClasses,
        autoplay,
        modalTarget,
        actions,
        contentPiece,
        contentName
    }) => {
        if (!modalTarget) {
            webui.log(
                "ResponsiveVideoComponent: No target has been set for the mobile modal video."
            );
            return;
        }

        if (["L", "XL"].includes(props.size)) {
            return {
                type: "container",
                extraClasses: `un-banner-story-wrapper un-banner-story-wrapper--video`,
                children: [
                    {
                        type: "container",
                        extraClasses: "tq-clickable un-banner-story un-banner-story--video",
                        children: [
                            {
                                type: "textOutput",
                                tag: "div",
                                extraClasses: "un-banner-story--video-badge",
                                bindTo: "{parent}.badge"
                            },
                            {
                                type: "videoPlayer",
                                bindTo: "{parent}",
                                contentPiece,
                                contentName,
                                customPlayButton,
                                extraClasses,
                                autoplay,
                                actions
                            }
                        ]
                    }
                ]
            };
        }
        return {
            type: "videoCard",
            thumbnailPreview: true,
            extraClasses: `un-banner-story-wrapper un-banner-story-wrapper--video ${extraClasses}`,
            bindTo: "{parent}",
            customPlayButton,
            actions: [
                {
                    actionType: "buildVideoModal",
                    targets: [modalTarget],
                    actions: [
                        {
                            actionType: "openModal",
                            targets: [modalTarget]
                        }
                    ]
                }
            ]
        };
    };

    if (!props.data) {
        return webui.getUtils().getEmptyContent();
    }
    const dataProps = webui.getUtils().extractComponentProps(props, {
        additionalProps: [
            "customPlayButton",
            "extraClasses",
            "autoplay",
            "modalTarget",
            "contentName",
            "contentPiece"
        ],
        propsDefaults: {
            customPlayButton: true,
            extraClasses: "",
            autoplay: false,
            modalTarget: false
        }
    });
    const actions = props.config.actions || [];

    const resolvedConfig = getChildrenByScreenSize({ ...dataProps, actions });
    const childrenConfig = Array.isArray(resolvedConfig) ? resolvedConfig : [resolvedConfig];
    return webui
        .getUtils()
        .resolveChildren({ ...props, config: { ...props.config, children: childrenConfig } });
};

export default webui.connectComponent()(ResponsiveHOC(ResponsiveVideoComponent));
