import React from "react";
import webui, { BaseComponent } from "@tinqin/tinqin-web-ui";
import MatomoEventsContainer from "./matomoEventsContainer";
import MetaTags from "./../meta-tags/MetaTags";
import EntityBuilder from "@tinqin/tinqin-web-ui/src/utils/EntityBuilder";

class AnalyticsContainer extends BaseComponent {
    render() {
        const data = this.props.data || null;
        const children = this.resolveChildren() || [];

        //if no widget data do not return content
        if (!data) {
            return this.getEmptyContent();
        }

        //build entity to get the its child meta and pass it to MetaTags
        const metaEntity = EntityBuilder.of(data, { inplace: true }).getChild("meta");

        //convert metaEntity to SIREN because the component expects it to be able to work independently
        const metaData = metaEntity.toSIREN();

        return (
            <MatomoEventsContainer analyticsData={data}>
                <MetaTags data={metaData} />
                {children}
            </MatomoEventsContainer>
        );
    }
}

export default webui.connectComponent()(AnalyticsContainer);
