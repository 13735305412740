import webui from "@tinqin/tinqin-web-ui"; 
const { getEntityValue } = webui.getActionByName("utils");

// if the user is on contacts page we are searching for selected type of contact form: "member" or "not a member"
//and then we are looking for the target in the container based on the contact form selection type.
// Otherwise if the user is not on contact page, it is just looking for the target in document.
export function contactFormSmoothScrollTo(sirenAction, widgetId, currentPath) {
    const target = sirenAction.targets || [];
    const isContactPage = /^widget-contact.*/g.test(widgetId);
    return dispatch => {
        let elementToScrollTo;
        if (isContactPage) {
            const pathArray = currentPath.split(".");
            const formTypePath = `${pathArray.splice(0, pathArray.length - 2).join(".")}.type`;
            const visibleContactFormSection = dispatch(getEntityValue(formTypePath));
            const parentSectionClass = getSectionCLass(visibleContactFormSection);
            const parentContainer = getParentSection(`div.${parentSectionClass}`);
            elementToScrollTo = parentContainer.querySelector(`[data-unique-id="${target}"]`);
        } else {
            elementToScrollTo = document.querySelector(`[data-unique-id="${target}"]`);
        }
        if (elementToScrollTo) {
            const elTop = elementToScrollTo.getBoundingClientRect().top;
            const scrollTop = elTop + (window.pageYOffset || document.documentElement.scrollTop) - 50;
            window.scrollTo({
                top: scrollTop,
                behavior: "smooth"
            });
        }
    };
}

function getParentSection(cssClass) {
    return document.querySelector(cssClass);
}

function getSectionCLass(selectedValue) {
    switch (selectedValue) {
        case "adherent":
            return "adherentSection";
        case "not-adherent":
            return "nonAdherentSection";
        default:
            return "nonAdherentSection";
    }
}
