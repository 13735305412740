import { createAction } from "../../utils/ActionBuilder";
import { isCorporate } from "./isCorporate";
import webui from "@tinqin/tinqin-web-ui";

const { getEntityValue, setEntityProps, getEntityActions, getParentPath } = webui.getActionByName(
    "utils"
);

const SEARCH_FILTER_PATHS = [
    "search.results.filters.formats",
    "search.results.filters.topics",
    "search.results.filters.profiles"
];

export function onSearch(sirenAction, widgetId, currentPath) {
    return (dispatch, getState) => {
        const pageParams = {};
        const sources = sirenAction?.sources || "search.form.input";
        const searchString = dispatch(getEntityValue(sources[0]));
        if (searchString) {
            pageParams["searchText"] = encodeURI(searchString);
        }
        const isCorporateFlag = dispatch(isCorporate(widgetId));
        let currentDashboard = getState().getIn(["routes", "location", "state", "dashboard"]);
        if (currentDashboard) {
            currentDashboard = currentDashboard.toJS();
        }
        const dispatchAction = webui.getActionByName("siren.dispatchSirenActions");
        if (!currentDashboard?.id.includes("search")) {
            let newDashboardHref = "/widget/search";
            if (isCorporateFlag) {
                newDashboardHref = "/widget/search-corp";
            }
            const switchDashboardAction = createAction("switchDashboard", {
                href: newDashboardHref,
                parameters: pageParams,
                method: "POST"
            });
            dispatch(dispatchAction([switchDashboardAction], widgetId, currentPath));
            //dispatch search actions defined on "form" level
            const formActions = dispatch(getEntityActions(getParentPath(sources[0])));
            dispatch(dispatchAction(formActions, widgetId, currentPath));
        } else {
            const category = dispatch(getEntityValue(sources[1]));
            dispatch(
                searchActionWithParameters(
                    sirenAction?.targets,
                    searchString,
                    category,
                    currentPath
                )
            );
        }
    };
}

//custom action for search input in the header sending to BE
function searchActionWithParameters(targets, searchText, category = [], currentPath) {
    return dispatch => {
        const dispatchAction = webui.getActionByName("siren.dispatchSirenActions");
        const updateAction = {
            actionType: "update",
            contentType: "application/json",
            id: "searchActionWithParameters",
            method: "POST",
            parameters: {
                searchText,
                category
            },
            sources: [],
            targets,
            successActions: [
                {
                    actionType: "trackSiteSearch"
                }
            ]
        };
        dispatch(dispatchAction([updateAction], "widget-search", currentPath));
    };
}

//when one of the filters change, disable the other two while waiting for the server response
export function onSearchFilterChange(sirenAction, widgetId, currentPath) {
    return dispatch => {
        const targetsToDisable = SEARCH_FILTER_PATHS.filter(item => {
            return !(item === currentPath);
        });
        targetsToDisable.forEach(path => {
            dispatch(
                setEntityProps(path, {
                    disabled: true,
                    extraClasses: "un-search-dropdown--loading"
                })
            );
        });
    };
}
