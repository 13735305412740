import { useEffect, useState } from "react";

/**
 * Represents custom hook uses IntersectionObserver to determine if dom element is visible in the root
 * @param elementRef dom element which we want to check visibility
 * @param options
 * threshold - A threshold of 1.0 means that when 100% of the target is visible within the element specified by the root option, the callback is invoked.)
 * root - The element that is used as the viewport for checking visibility of the target. Must be the ancestor of the target. Defaults to the browser viewport if not specified or if null.
 * rootMargin - Margin around the root. Can have values similar to the CSS margin property, e.g. "10px 20px 30px 40px" (top, right, bottom, left). The values can be percentages - could be negative number
 * @returns boolean if the target is visible in the root viewport
 */
const useIntersectionObserver = (
    elementRef: React.MutableRefObject<Element>,
    { threshold = 0, root = null, rootMargin = "200px" }: IntersectionObserverInit = {}
): boolean => {
    const [entry, setEntry] = useState<IntersectionObserverEntry>();
    const [visible, setVisible] = useState<boolean>(false);

    useEffect(() => {
        const node: Element = elementRef?.current;
        if (!node || visible) return;
        const observerOptions = { threshold, root, rootMargin };
        const observer = new IntersectionObserver(item => {
            setEntry(item[0]);
            if (item[0].isIntersecting) {
                setVisible(true);
                observer.disconnect();
            }
        }, observerOptions);
        observer.observe(node);
        return () => observer.disconnect();
    }, [elementRef, threshold, root, rootMargin, visible]);
    //If the browser does not support IntersectionObserver return true to show the image
    if (!window.IntersectionObserver) return true;
    return !!entry?.isIntersecting;
};

export default useIntersectionObserver;
