import { createAction } from "../../utils/ActionBuilder";
import urlUtils from "@tinqin/tinqin-utils/src/url";
import request from "../request";
const MATOMO_EVENT_CATEGORY = "URL non existant";
const MATOMO_EVENT_NAME = `Tentative ouverture URL non existant`;

export function buildPageAddress({ sirenAction = {} } = {}) {
    let pathname = "";
    let search = "";
    if (sirenAction.properties && sirenAction.properties.address !== undefined) {
        const address = sirenAction.properties.address || "";
        const urlQuery = sirenAction.properties.urlQuery || {};
        const currentURL = new URL(address, window.location.origin);
        pathname = currentURL.pathname;
        search = urlUtils.updateUrlParameters(currentURL.search, urlQuery);
        if (pathname[0] !== "/") {
            pathname = `/${pathname}`;
        }
    }
    return {
        pathname,
        search
    };
}

function buildFullPageAddress({ sirenAction = {} } = {}) {
    const origin = window.location.origin;
    const { pathname, search } = buildPageAddress({ sirenAction });
    return origin + pathname + search;
}

export function getAddressFromActions(actions = []) {
    const switchAction = actions.find(item => {
        if (!item) {
            return false;
        }
        return item.actionType === "switchContainer";
    });
    const openTargetAction = actions.find(item => {
        if (!item) {
            return false;
        }
        return !!item?.href;
    });
    if (switchAction) {
        return buildFullPageAddress({ sirenAction: switchAction });
    } else if (openTargetAction) {
        return openTargetAction.href;
    } else {
        return "";
    }
}

export async function buildBootDashboardAction() {
    let dashboardAction;
    const searchURL = window.location.pathname + window.location.search;
    try {
        dashboardAction = await request({
            method: "POST",
            url: "/api/identify",
            body: {
                address: window.location.pathname + window.location.search
            }
        });
        if (dashboardAction.properties.address === "404") {
            const eventAction = `${searchURL}`;
            if (window._paq) {
                window._paq.push([
                    "trackEvent",
                    MATOMO_EVENT_CATEGORY,
                    eventAction,
                    MATOMO_EVENT_NAME
                ]);
            }
        }
    } catch (error) {
        dashboardAction = getDashboard404();
    }
    return dashboardAction;
}

const lastLocation = {
    pathname: window.location.pathname,
    search: window.location.search
};
export function compareDashboardAddresses({ pathname, search, sirenAction }) {
    const sameLocation = lastLocation.pathname === pathname && lastLocation.search === search;
    if (!sameLocation) {
        lastLocation.pathname = pathname;
        lastLocation.search = search;
    }
    return sameLocation;
}

function getDashboard404() {
    return createAction("switchContainer", {
        href: "/widget/404",
        parameters: {}
    });
}
