import React from "react";
import webui, { BaseComponent } from "@tinqin/tinqin-web-ui";

//Container rendering one fo 2 regions, one if the bound entity is in "loading" state
// and "default" region if not
// regions are named: "loading" and "default"
// Component is always rendered, even of not bound correctoly to an entity
// since it is common for it to be bound to an entity that is yet to be added
// Example: contact form successMessage, which is loaded on submit, but not available initially
export class LoadingContainer extends BaseComponent {
    render() {
        const children = this.resolveRegions();
        if (this.props.uiState && this.props.uiState.loading) {
            return <>{children["loading"]}</>;
        } else {
            return <>{children["default"]}</>;
        }
    }
}

export default webui.connectComponent({
    uiState: true
})(LoadingContainer);
