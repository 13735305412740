import webui from "@tinqin/tinqin-web-ui";
import { ISirenAction, SirenEntity, TAppThunk } from "../../utils/commonTypes";
import { buildPageAddress } from "../page/PageAddress";
const {
    getEntity,
    getEntityValue,
    getEntityChildren,
    getEntityProps,
    getEntityActions,
    getParentPath,
    getEntityName
} = webui.getActionByName("utils");

const WIDGET_CATALOG_MARKETPLPACE_OFFERS = "widget-landing-marketplace-offers";
const WIDGET_MY_PROJECTS = "widget-my-projects";
const WIDGET_JOB_BOARD = "widget-job-board";
const WIDGET_LANDING_EXPERTS = "widget-landing-experts";
const WIDGET_MY_COMMUNITY = "widget-landing-community";
const SELECTED_CATEGORY_PATH = `marketplaceOffers.selectedCategory`;
const SELECTED_PROJECT_PATH = `landing.selectedProject`;
const SELECTED_SUBCATEGORY_PATH = `marketplaceOffers.selectedSubcategory`;
const CATEGORIES_PATH = "marketplaceOffers.categories.contents";
const PROJECTS_PATH = "landing.projects.contents";
const EXPERTS_FILTERS_PATH = "landing.articles.filterSection.filters";
const JOB_BOARD_FILTERS_PATH = "jobBoard.filterSection.filters";
const JOB_BOARD_SELECTED_FILTERS_PATH = `jobBoard.filterSection.selectedFilters`;
const HOMEPAGE_CP_WIDGET_ID = "widget-homepage";
const MEMBER_PATH = "header.userType";

const VIDEO_PLAY = "Jouer";
const VIDEO_PAUSE = "Pause";
const VIDEO_FINISH = "Termine";
const VIDEO_UNKNOWN = "Unknown";

const MEMBER_SUFFIX = "adherent";
const USER_SUFFIX = "Uneonaute";
const RESERVE_SUFFIX = "reserve";
const USER_TYPE = {
    GUEST: "guest",
    MEMBER: "member",
    USER: "user"
};

declare global {
    interface Window {
        _paq: any[];
    }
}

export const trackPageView = (
    sirenAction: ISirenAction,
    widgetId: string,
    currentPath: string
): TAppThunk => dispatch => {
    let { pageTitle } = sirenAction.properties || {};
    if (!pageTitle) {
        pageTitle = document.title;
    }
    triggerMatomoEvents(pageTitle);
};
//on Analytics container load
export const triggerMatomoEvents = (pageTitle: string) => {
    const currentUrl: string = window.location.href;
    if (window._paq) {
        // window._paq.push(['HeatmapSessionRecording::enable']);
        // window._paq.push(['HeatmapSessionRecording::setNewPageView']);

        //set the Referrer. (https://matomo.org/faq/troubleshooting/faq_51/)
        // Matomo only record the first referer of a visit. When testing Matomo and search engine detection, make sure you make the test more than 30 minutes after your last page view on your website.
        window._paq.push(["setReferrerUrl", currentUrl]);
        window._paq.push(["setCustomUrl", currentUrl]);
        //set the Title
        window._paq.push(["setDocumentTitle", pageTitle]);
        window._paq.push(["trackPageView"]);

        // make Matomo aware of newly added content
        const content: HTMLElement = document.getElementById("root");
        // window._paq.push(["MediaAnalytics::scanForMedia", content]);
        window._paq.push(["trackVisibleContentImpressions"]);
        window._paq.push(["FormAnalytics::scanForForms", content]);
        // window._paq.push(["trackContentImpressionsWithinNode", content]);
        window._paq.push(["enableLinkTracking"]);
    }
};

export function trackEvent(category: string, eventAction: string, eventName: string): void {
    if (eventName) {
        window._paq.push(["trackEvent", `${category}`, `${eventAction}`, `${eventName}`]);
    } else {
        window._paq.push(["trackEvent", `${category}`, `${eventAction}`]);
    }
}

const getAddressFromData = (path: string): TAppThunk => (dispatch): string => {
    const actions: ISirenAction[] = dispatch(getEntityActions(path)) || [];
    //Internal links
    const switchAction: ISirenAction = actions.find(
        action => action.actionType === "switchContainer"
    );
    // let url = "Unknown address";
    let url: string;
    if (switchAction) {
        const { pathname, search }: { pathname: string; search: string } = buildPageAddress({
            sirenAction: switchAction
        });
        url = `${pathname}${search}`;
    } else { //External links with openTarget
        const openTarget: ISirenAction = actions.find(
            action => action.actionType === "openTarget"
        );
        url = openTarget?.href;

    }

    if (url && !url.startsWith("/")) {
        url = "/" + url;
    }

    return url;
};
export const getTemplates = (string: string): RegExpMatchArray | null => {
    const templateRegex: RegExp = new RegExp("{%.+?%}", "g");
    const matches: RegExpMatchArray = string.match(templateRegex);
    return matches;
};

export const resolvePath = (path: string, currentPath: string): string => {
    const currentPathRegEx: RegExp = /{\s*currentPath\s*}/g;
    if (currentPathRegEx.test(path)) {
        return path.replace(currentPathRegEx, currentPath);
    } else {
        const parentRegex: RegExp = /{parent\*?(\d?)}/;
        const parentMatches: RegExpMatchArray = path.match(parentRegex);
        //check for "{parent*N}. ..." path
        if (parentMatches) {
            const times: string = parentMatches[1] || "1";
            const parentPath: string = getParentPath(currentPath, Number(times));
            return path.replace(parentRegex, parentPath);
        } else {
            return path;
        }
    }
};

export const resolveProperty = (
    property: string,
    resolvedPath: string,
    fallbackString: string
): string => {
    const dispatch = webui.getDispatchMethod();
    switch (property) {
        case "pathUrlTo": {
            return dispatch(getAddressFromData(resolvedPath)) || fallbackString;
        }
        case "selectedEnumLabels": {
            const selectedEnums: string | string[] = dispatch(getEntityValue(resolvedPath)) || [];
            const enums: SirenEntity[] = dispatch(getEntityChildren(resolvedPath)) || [];
            const labels: string[] = enums
                .filter(item => selectedEnums.includes(item.properties.id))
                .map(entity => entity.properties?.label);
            if (labels.length) {
                return labels.join(", ");
            }
            return "";
        }
        default: {
            const propValue: any =
                dispatch(getEntityProps(resolvedPath, property)) || fallbackString;
            //remove tags and styling if it is a Rich Text
            return propValue?.replace(/(<([^>]+)>)/gi, "");
        }
    }
};

export const resolveTemplate = (template: string, currentPath: string): string => {
    const initialTemplate: string = template.replaceAll(/{%\s?|\s?%}/g, "");
    if (initialTemplate === "currentPathUrl") {
        return window.location.pathname;
    }
    if (initialTemplate.startsWith("pageTitle")) {
        const templateProps: string[] = initialTemplate.split("||");
        return document.title || templateProps[1];
    }
    if (initialTemplate.startsWith("userType")) {
        return getUserTypeLabel();
    }
    const [path, value] = initialTemplate.split("->");
    const resolvedPath: string = resolvePath(path, currentPath);
    const [property, fallbackString] = value.split("||");
    return resolveProperty(property, resolvedPath, fallbackString);
};

export const resolveTemplates = (
    templates: string[],
    initialString: string,
    currentPath: string
) => {
    let result: string = initialString;
    templates.forEach((template: string) => {
        const resolvedTemplate: string = resolveTemplate(template, currentPath);
        result = result.replace(template, resolvedTemplate);
    });
    return result;
};

export const triggerMatomoEvent = (
    sirenAction: ISirenAction,
    widgetId: string,
    currentPath: string
): TAppThunk => (): void => {
    if (!window._paq) {
        return;
    }
    const matomoStrings: Record<string, string> = Object.assign({}, sirenAction.properties) || {};
    Object.keys(matomoStrings).forEach((key: string) => {
        //get the template string
        const templateString: string = matomoStrings[key];
        const templates: RegExpMatchArray | null = getTemplates(templateString);
        if (templates) {
            matomoStrings[key] = resolveTemplates(templates, matomoStrings[key], currentPath);
        }
    });
    const { category, name, action }: Record<string, string> = matomoStrings;
    trackEvent(category, action, name);
};

const getUserType = () => dispatch => dispatch(getEntityValue(MEMBER_PATH, "userType"));
const isOfferForAdherent = (currentPath: string) => dispatch => {
    //Check if the offer has Reserve badge - dynamic level of nesting - so we need to check
    //article.offer-presentation.offers.1?.badge no matter how deep is the currentPath
    const offerBadgePath: string = currentPath
        .split(".")
        .slice(0, 4)
        .join(".");
    return dispatch(getEntity(`${offerBadgePath}.badge`));
};

export const trackMySpaceButtonMatomoEvent = (
    sirenAction: ISirenAction,
    widgetId: string,
    currentPath: string
): TAppThunk => dispatch => {
    if (!window._paq) {
        return;
    }
    const userType: string = dispatch(getUserType());
    if (userType !== USER_TYPE.MEMBER) {
        const { category, action, name }: Record<string, any> = sirenAction.properties;
        trackEvent(category, action, name);
    }
};

export const trackOfferDependingOnUserType = (
    sirenAction: ISirenAction,
    widgetId: string,
    currentPath: string
): TAppThunk => (dispatch, getState) => {
    if (!window._paq) {
        return;
    }
    const userType: string = dispatch(getUserType());
    // https://jira.tinqin.com/browse/UCP-564
    let { category, eventAction }: Record<string, any> = sirenAction.properties;
    const { type }: Record<string, any> = sirenAction.properties;
    if (!eventAction) {
        if (type === "phoneButton") {
            const phoneNumber: string = dispatch(getEntityValue(`${currentPath}.number`));
            eventAction = `BTN Appel - ${phoneNumber}`;
        } else {
            eventAction = `BTN ${dispatch(getEntityProps(currentPath, "label"))}`;
        }
    }
    switch (userType) {
        case USER_TYPE.MEMBER:
            eventAction += ` - ${MEMBER_SUFFIX}`;
            category += ` ${MEMBER_SUFFIX}`;
            if (dispatch(isOfferForAdherent(currentPath))) {
                eventAction += ` ${RESERVE_SUFFIX}`;
            }
            break;
        case USER_TYPE.GUEST:
            if (dispatch(isOfferForAdherent(currentPath))) {
                eventAction += ` - ${MEMBER_SUFFIX}`;
            }
            break;
        case USER_TYPE.USER:
            eventAction += ` - ${USER_SUFFIX}`;
            category += ` ${USER_SUFFIX}`;
            break;
        default:
            break;
    }
    const eventName: string = window.location.pathname;
    trackEvent(category, eventAction, eventName);
};

const getUserTypeLabel = (): string => {
    const dispatch = webui.getDispatchMethod();
    const userType: string = dispatch(getUserType());
    if (userType === USER_TYPE.MEMBER) {
        return MEMBER_SUFFIX;
    }
    if (userType === USER_TYPE.USER) {
        return USER_SUFFIX;
    }
    return "";
};

const getCategory = (widgetId: string): string => {
    switch (widgetId) {
        case HOMEPAGE_CP_WIDGET_ID: {
            return "Page accueil";
        }
        case WIDGET_LANDING_EXPERTS: {
            return "LP Conseils d'experts";
        }
        case WIDGET_MY_COMMUNITY: {
            return "LP Ma communaute";
        }
        default: {
            return "Unknown page";
        }
    }
};

export const trackCatalogCategories = (
    sirenAction: ISirenAction,
    widgetId: string,
    currentPath: string
): TAppThunk => dispatch => {
    if (!window._paq) {
        return;
    }
    let { eventAction }: Record<string, any> = sirenAction.properties;
    const { category, responsive }: Record<string, any> = sirenAction.properties;

    const selected: boolean = dispatch(getEntityProps(currentPath, "selected"));
    let eventName: string;
    if (selected) {
       //after discussion with BE we agreed that the last part of the url (selected category) will, if it is not already, the entity id.
       eventName = `Categorie "${dispatch(getEntityValue(`${currentPath}.title`))}\n${window.location.pathname}/${getEntityName(currentPath)}"`;
    } else {
        eventName = `Categorie non selection`;
        eventAction = `deselection`;
    }

    if (responsive) {
        trackEvent(
            `${category} responsive`,
            `${eventAction} responsive`,
            `${eventName} responsive`
        );
    } else {
        trackEvent(category, eventAction, eventName);
    }
};

export const trackProjectsFilter = (
    sirenAction: ISirenAction,
    widgetId: string,
    currentPath: string
): TAppThunk => dispatch => {
    if (!window._paq) {
        return;
    }
    const { category, eventAction, responsive }: Record<string, any> = sirenAction.properties;
    const eventName: string = `"${dispatch(getEntityValue(`${currentPath}.title`))}"`;

    if (responsive) {
        trackEvent(
            `${category} responsive`,
            `${eventAction} responsive`,
            `${eventName} responsive`
        );
    } else {
        trackEvent(category, eventAction, eventName);
    }
};

export const trackApplyButton = (
    sirenAction: ISirenAction,
    widgetId: string,
    currentPath: string
): TAppThunk => dispatch => {
    if (!window._paq) {
        return;
    }
    const { eventAction }: Record<string, any> = sirenAction.properties;
    let { category }: Record<string, any> = sirenAction.properties;
    let eventName: string;
    switch (widgetId) {
        case WIDGET_CATALOG_MARKETPLPACE_OFFERS: {
            const selectedCategory: string = dispatch(getEntityValue(SELECTED_CATEGORY_PATH));
            let selectedCategoryName: string = "non selection";
            if (selectedCategory) {
                selectedCategoryName = dispatch(
                    getEntityValue(`${CATEGORIES_PATH}.${selectedCategory}.title`)
                );
            }
            const selectedSubcategories: SirenEntity = dispatch(
                getEntity(SELECTED_SUBCATEGORY_PATH)
            );
            const selectedValues: string[] = selectedSubcategories.properties?.value || [];
            const labels: string[] = selectedSubcategories?.entities
                ?.filter(entity => selectedValues.includes(entity.properties.id))
                .map(entity => entity.properties?.label);
            eventName = `Categorie: "${selectedCategoryName}" Sous-categorie: [ ${labels.join(
                ", "
            )} ]`;
            break;
        }
        case WIDGET_MY_PROJECTS: {
            const selectedProjects: string = dispatch(getEntityValue(SELECTED_PROJECT_PATH));
            if (selectedProjects) {
                const applyLabel: string = dispatch(getEntityProps(currentPath, "label"));
                const selectedProjectName: string = dispatch(
                    getEntityValue(`${PROJECTS_PATH}.${selectedProjects}.title`)
                );
                eventName = `"${selectedProjectName}" + BTN "${applyLabel}"`;
            }
            break;
        }
        case WIDGET_LANDING_EXPERTS: {
            const selectedFilters: string[] = dispatch(getEntityValue(EXPERTS_FILTERS_PATH)) || [];
            const filters: SirenEntity[] =
                dispatch(getEntityChildren(EXPERTS_FILTERS_PATH)) || [];
            const labels: string[] = filters
                .filter(filter => selectedFilters.includes(filter.properties.id))
                .map(entity => entity.properties?.label);
            eventName = labels.join(", ") + " + BTN Valider";
            break;
        }
        case WIDGET_JOB_BOARD: {
            const filters: SirenEntity[] =
                dispatch(getEntityChildren(JOB_BOARD_SELECTED_FILTERS_PATH)) || [];
            const labels: string[] = [];
            filters.forEach(entity => {
                    const id = entity.class[0];
                    const selected = dispatch(getEntityValue(`${JOB_BOARD_FILTERS_PATH}.${id}.subcategories`));
                    if (!selected || !selected.length) return;
                    let category = dispatch(getEntityValue(`${JOB_BOARD_SELECTED_FILTERS_PATH}.${id}.title`));
                    const subcategories = dispatch(getEntityChildren(`${JOB_BOARD_SELECTED_FILTERS_PATH}.${id}.subcategories`));
                    const subcategoriesLabels: string[] = [];
                    subcategories.forEach((subcategory: SirenEntity) => {
                        if (selected.includes(subcategory.properties.id)) {
                            subcategoriesLabels.push(subcategory.properties?.label);
                        }
                    });
                    if (subcategoriesLabels.length) {
                        category += ` [${subcategoriesLabels.join(", ")}]`;
                    }
                    labels.push(category);
                });
            eventName = `Filtre: {${labels ? labels.join(", ") : ""}} ${getUserTypeLabel()}`;
            category += ` ${getUserTypeLabel()}`;
            break;
        }
        default: {
            return;
        }
    }
    trackEvent(category, eventAction, eventName);
};

export const trackVideo = (
    sirenAction: ISirenAction,
    widgetId: string,
    currentPath: string
): TAppThunk => dispatch => {
    if (!window._paq) {
        return;
    }
    let { eventNameAfter }: Record<string, any> = sirenAction.properties || {};
    //if eventNameAfter is not specified, it will be searched inside data.properties as "matomoVideoSection"
    if (!eventNameAfter) {
        const matomoVideoSection: string = dispatch(
            getEntityProps(currentPath, "matomoVideoSection")
        );
        if (matomoVideoSection) {
            eventNameAfter = `- ${matomoVideoSection}`;
        }
    }
    const category: string = getCategory(widgetId);
    const videoId: string = dispatch(getEntityProps(currentPath, "videoId"));
    const videoUrl: string = `https://www.youtube.com/embed/${videoId}`;
    let eventName: string = `${videoUrl}`;
    if (eventNameAfter) {
        eventName = eventName + " " + eventNameAfter;
    }
    const eventAction: string = getVideoEventAction(sirenAction.events[0]);
    trackEvent(category, eventAction, eventName);
};

const getVideoEventAction = (eventName: string): string => {
    switch (eventName) {
        case "play":
            return VIDEO_PLAY;
        case "pause":
            return VIDEO_PAUSE;
        case "finish":
            return VIDEO_FINISH;
        default:
            return VIDEO_UNKNOWN;
    }
};

//The current path is manually created in Links/SocialLinks.js dynamically because there is no binding for each child
//and we get the current social sharing link type from the classIdentifier
export const trackSocialLinksItem = (
    sirenAction: ISirenAction,
    widgetId: string,
    currentPath: string
): TAppThunk => dispatch => {
    if (!window._paq) {
        return;
    }
    const entityName: string = getEntityName(currentPath);
    //Capitalize first letter
    const eventName: string = entityName[0].toUpperCase() + entityName.slice(1);
    const { category, eventAction }: Record<string, any> = sirenAction.properties;
    trackEvent(category, eventAction, eventName);
};

export const trackContactFormSent = (
    sirenAction: ISirenAction,
    widgetId: string,
    currentPath: string
) => dispatch => {
    if (!window._paq) {
        return;
    }
    const { category, typePath }: Record<string, any> = sirenAction.properties || {};
    let { eventAction }: Record<string, string> = sirenAction.properties || {};
    const formType: string = dispatch(getEntityValue(typePath));
    if (formType === "not-adherent") {
        eventAction += " non adherent";
    } else if (formType === "adherent") {
        eventAction += " adherent";
    }
    window._paq.push(["trackEvent", `${category}`, `${eventAction}`]);
};

export const trackSearchSuggestions = (
    sirenAction: ISirenAction,
    _widgetId: string,
    currentPath: string
) => _dispatch  => {
    if (!window._paq) {
        return;
    }
    const props = sirenAction.properties;
    const template: RegExpMatchArray | null = getTemplates(props.name);
    const name: string = resolveTemplates(template, props.name, currentPath);
    const action = props?.value ? props.action + props?.value : props.action;
    trackEvent(props.category, action, name);
};