import React from "react";

const Row = props => {
    return React.createElement(
        props.tag,
        {
            className: "tq-row"
        },
        props.children
    );
};

export default Row;
