import { useEffect, useState } from "react";

declare global {
    interface Window {
        globalSettings: any;
    }
}

interface FeatureSettings {
    src: string;
    "cp-key": string;
    "image-src"?: string;
    text?: string;
}

interface Feature {
    feature: string;
    enabled: boolean;
    settings: FeatureSettings;
}

export default function FacilitiLoader() {
    const feature: string = window.globalSettings.featureEndpoint + "/faciliti";
    const [data, setData] = useState<Feature>();
    if (!data) {
        fetch(feature, {
            method: "GET",
            headers: {
                Accept: "application/json"
            }
        })
            .then(res => res.json())
            .then(data => {
                setData(data);
            });
    }
    const settings = data?.settings;
    const enabled = data?.enabled;
    const src = settings?.["script-url"];
    const identifier = settings?.["cp-key"];
    const [scriptLoaded, setScriptLoaded] = useState(false);

    useEffect(() => {
        if (!scriptLoaded && !!document.head.querySelector("script#faciliti")) {
            setScriptLoaded(true);
            return;
        }
        if (enabled && identifier && src && !scriptLoaded) {
            //Setup Facil'iti
            const fs = document.createElement("script");
            fs.type = "text/javascript";
            fs.id = "faciliti";
            fs.src = src;
            //Set custom data attributes to use in the faciliti button

            fs.dataset.applicationIdentifier = identifier;
            fs.dataset.popinPosition = "left";
            document.head.appendChild(fs);

            setScriptLoaded(true);
        }
    }, [enabled, identifier, src, scriptLoaded, settings]);
    return null;
}
