import React from "react";
import webui, { BaseComponent } from "@tinqin/tinqin-web-ui";

const extractProps = webui.getUtils().extractComponentProps;

class ClickContainer extends BaseComponent {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        this.dispatchSirenActions(["default", "click"]);
    }

    isClickable() {
        const data = this.props.data || {};
        const actions = data.actions || [];
        return !!actions.length;
    }

    getProps() {
        return extractProps(this.props, {
            additionalProps: ["extraClasses"],
            propsDefaults: {
                extraClasses: ""
            }
        });
    }

    render() {
        const clickableClass = this.isClickable() ? " tq-clickable" : "";
        const props = this.getProps();
        return (
            <div className={props.extraClasses + clickableClass} onClick={this.onClick}>
                {this.resolveChildren()}
            </div>
        );
    }
}

export default webui.connectComponent({
    actions: { dispatchSirenActions: "siren.dispatchSirenActions" }
})(ClickContainer);
