import React, { useCallback } from "react";
import webui from "@tinqin/tinqin-web-ui";
import { IComponentProps, SirenEntityBuilder, ISirenAction } from "../../utils/commonTypes";
import EntityBuilder from "@tinqin/tinqin-web-ui/src/utils/EntityBuilder";
import { getAddressFromActions } from "../../actions/page/PageAddress";

//Simple breadcrumb
export const Breadcrumb: React.FC<IComponentProps> = props => {
    const {
        extraClasses
    }: {
        extraClasses: string;
    } = webui.getUtils().extractComponentProps(props, {
        additionalProps: ["extraClasses"],
        propsDefaults: {
            extraClasses: ""
        }
    });

    const onClick = useCallback(
        (
            e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
            actions: ISirenAction[],
            id: string
        ): void => {
            e.preventDefault();
            if (actions.length) {
                const dispatchAction = webui.getActionByName("siren.dispatchSirenActions");
                const dispatch = webui.getDispatchMethod();
                const childPath = props.computedBindTo + "." + id;
                dispatch(dispatchAction([...actions], props.widgetId, childPath));
                webui.getUtils().dispatchSirenActions(props, "click");
            }
        },
        [props]
    );

    const buildItems = useCallback((): JSX.Element[] => {
        if (props.data) {
            const siren: SirenEntityBuilder = EntityBuilder.of(props.data);
            const children: SirenEntityBuilder[] = siren.getChildren();
            return children.map((entity: SirenEntityBuilder, index: number) => {
                const id: string = entity.getId();
                const label: string = entity.getLabel();
                const currentItemClass: string = index === children.length - 1 ? "tq-current" : "";
                const actions: ISirenAction[] = entity.getActions();
                return (
                    <li key={id} className={currentItemClass}>
                        {!currentItemClass ? (
                            <a href={getAddressFromActions(actions)} onClick={e => onClick(e, actions, id)}>{label}</a>
                        ) : (
                            <span>{label}</span>
                        )}
                    </li>
                );
            });
        }
    }, [props, onClick]);

    if (!props.data) {
        return webui.getUtils().getEmptyContent();
    }
    return (
        <div className={`tq-breadcrumb-container ${extraClasses}`}>
            <ul className="tq-breadcrumb-content">{buildItems()}</ul>
        </div>
    );
};

export default webui.connectComponent()(Breadcrumb);
