import { defaultProps } from "./defaultProps";

export default class AutoPlay {
    constructor(props, carouselRef, incrementHandler, stopOnHoverHandler, startOnLeaveHandler) {
        this.props = props;
        this.carouselRef = carouselRef;
        this.incrementHandler = incrementHandler;
        this.stopOnHoverHandler = stopOnHoverHandler;
        this.startOnLeaveHandler = startOnLeaveHandler;

        this.bindEventHandlers();
    }

    bindEventHandlers() {
        this.stopOnHover = this.stopOnHover.bind(this);
        this.startOnLeave = this.startOnLeave.bind(this);
    }

    setupAutoPlay() {
        this.autoPlay(this.props.autoPlayTimeInterval || defaultProps.autoPlayTimeInterval);
        const carouselWrapper = this.carouselRef.current;

        if (this.props.stopOnHover && carouselWrapper) {
            carouselWrapper.addEventListener("mouseenter", this.stopOnHover);
            carouselWrapper.addEventListener("mouseleave", this.startOnLeave);
        }
    }

    destroyAutoPlay() {
        this.clearAutoPlay();
        const carouselWrapper = this.carouselRef.current;

        if (this.props.stopOnHover && carouselWrapper) {
            carouselWrapper.removeEventListener("mouseenter", this.stopOnHover);
            carouselWrapper.removeEventListener("mouseleave", this.startOnLeave);
        }
    }

    autoPlay(interval) {
        if (this.autoPlayTimer) {
            this.clearAutoPlay();
        }

        this.autoPlayTimer = setTimeout(() => {
            this.incrementHandler();
        }, interval);
    }

    clearAutoPlay() {
        clearTimeout(this.autoPlayTimer);
        this.autoPlayTimer = undefined;
    }

    resetAutoPlay() {
        this.clearAutoPlay();
        this.autoPlay(this.props.autoPlayTimeInterval || defaultProps.autoPlayTimeInterval);
    }

    stopOnHover() {
        this.stopOnHoverHandler();
        this.clearAutoPlay();
    }

    startOnLeave() {
        this.startOnLeaveHandler();
        this.autoPlay(this.props.autoPlayTimeInterval || defaultProps.autoPlayTimeInterval);
    }
}
