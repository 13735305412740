import React from "react";
import PropTypes from "prop-types";
import Scrollbar from "@tinqin/tinqin-react-custom-scrollbars";
import DomUtils from "@tinqin/tinqin-utils/src/dom";
import MapUtils from "@tinqin/tinqin-utils/src/map";
import ObjectUtils from "@tinqin/tinqin-utils/src/object";
import ArrayUtils from "@tinqin/tinqin-utils/src/array";
import DropdownOption from "./DropdownOption";
import DropdownSelection from "./DropdownSelection";
import documentedProps from "./documentation/DropdownDocumentedProps";
import Validatable from "@tinqin/tinqin-ui-components/src/validatable/Validatable";
import fetchLabeledProps from "@tinqin/tinqin-ui-components/src/label/fetchLabeledProps";
import Popover from "@tinqin/tinqin-ui-components/src/popover/Popover";
import Labeled from "@tinqin/tinqin-ui-components/src/label/Labeled";

const selectAllCode = "__selectAll__"; //Note: we need that querySelector friendly (for now)!

export default class Dropdown extends Validatable {
    constructor(props) {
        super(props);

        this.anchor = React.createRef();
        this.content = React.createRef();
        this.scrollbar = React.createRef();
        this.suggestionInput = React.createRef();
        this.pills = React.createRef();

        //Note marker to use for keyboard interactions! React re-rendering leads to very poor performance,
        //in case there are many items in the Dropdown, so we do not store and re-render when changing the
        //marker using arrow keys!
        //TODO see what we can do with /bvaughn/react-virtualized and how facebook are doing this, so we
        //TODO can copy the approach, may be they do not re-render as well, may be not :). We need to know!
        this.keyboardMarker = null;
        this.state = {
            focused: false,
            isSuggestionInputVisibleInSingleSelection: false,
            active: false,
            suggestionValue: "",
            selection: this.props.selection || [],
            valid: props.valid,
            failedValidations: props.failedValidations || {}
        };

        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onOpen = this.onOpen.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onChangeSuggestionValue = this.onChangeSuggestionValue.bind(this);
    }

    componentDidUpdate() {
        if (this.state.active && !this.props.multiSelection && this.state.selection.length) {
            const items = this.content.current;
            if (items) {
                const selected = items.querySelector(`li[data-code="${this.state.selection[0]}"]`);
                let scrollTop = 0;
                if (selected && selected.offsetTop > 0) {
                    scrollTop = selected.offsetTop - selected.offsetHeight;
                }
                this.scrollbar.current.scrollTop(scrollTop);
            }
        }
        const scrollbarElement = document.querySelector("#scrollbar");
        if (scrollbarElement) {
            const rect = scrollbarElement.getBoundingClientRect();
            const newMaxHeight = window.innerHeight - rect.top + "px";
            if (newMaxHeight !== scrollbarElement.style.maxHeight) {
                scrollbarElement.style.maxHeight = window.innerHeight - rect.top + "px";
                scrollbarElement.firstChild.style.maxHeight = window.innerHeight - rect.top + "px";
            }
        }
        //mark the first option in case of autsuggestionServerSide
        //options are usually added asynchronously
        //and marker should be added on componentDidUpdate
        if (
            !this.keyboardMarker &&
            this.props.autosuggestionServerSide &&
            this.state.active &&
            this.state.suggestionValue &&
            this.props.options &&
            this.props.options.size
        ) {
            const newMarker =
                MapUtils.find(this.props.options, (key, value) => {
                    return !value.disabled;
                }) || null;
            this.markKeyboardSelection(this.keyboardMarker, newMarker);
            this.keyboardMarker = newMarker;
        }
    }

    componentWillReceiveProps(newProps) {
        const toSet = {};
        const different = !ArrayUtils.shallowEquals(this.state.selection, newProps.selection);
        if (newProps.selection && different) {
            toSet.selection = newProps.selection;
            toSet.failedValidations = {};
        }

        if (newProps.valid !== this.state.valid) {
            toSet.valid = newProps.valid;
        } else if (toSet.hasOwnProperty("selection")) {
            toSet.valid = undefined;
        }

        if (!this.validationsEqual(newProps.failedValidations, this.state.failedValidations)) {
            toSet.failedValidations = newProps.failedValidations;
        }

        if (newProps.options.size > 0) {
            toSet.options = newProps.options;
        }

        if (!ObjectUtils.isEmpty(toSet)) {
            this.setState(toSet);
        }
    }

    onChange(event, newCode, state = {}, stateCallbacks = []) {
        event.stopPropagation();
        //Determine New selection!
        const stateToSet = {};
        stateToSet.isSuggestionInputVisibleInSingleSelection = false;

        const code = newCode || event.currentTarget.getAttribute("data-code");
        const options = this.props.options;
        if (options.get(code) && options.get(code).disabled) {
            //We do nothing on attempt to select disabled item!
            return;
        }
        if (code === selectAllCode) {
            //Deal with select all.
            if (this.state.selection.length === options.size) {
                stateToSet.selection = this.state.selection.filter(itemCode => {
                    return options.get(itemCode).disabled;
                });
            } else {
                stateToSet.selection = MapUtils.keys(
                    MapUtils.filter(options, (code, value) => {
                        return !value.disabled;
                    })
                );
            }
        } else {
            if (!this.props.multiSelection) {
                stateToSet.selection = [code];
            } else {
                const filteredSelection = this.state.selection.filter(selectedItem => {
                    return selectedItem !== code;
                });
                if (filteredSelection.length !== this.state.selection.length) {
                    stateToSet.selection = filteredSelection;
                } else {
                    if (this.props.multiSelection && this.props.maxSelection === 1) {
                        stateToSet.selection = [code];
                    } else {
                        stateToSet.selection = Array.from(this.state.selection);
                        stateToSet.selection.push(code);
                    }
                }
            }
        }
        if (this.props.autosuggestion && this.props.hasInputAutosuggestion) {
            stateToSet.suggestionValue = "";
        }

        //Deal with callbacks and single/multi selection!
        if (
            (!this.props.multiSelection ||
                (this.props.multiSelection && this.props.maxSelection === 1)) &&
            this.state.active
        ) {
            //active is for the keyboard interaction case.
            //Note that onClose will deal with validations in this case!
            this.onClose(
                event,
                Object.assign(state, {
                    state: stateToSet,
                    callback: this.props.onChange
                }),
                stateCallbacks
            );
        } else {
            if (!this.state.active) {
                //This is the case when we select with keyboard and onClose will not validate.
                const selection = stateToSet.selection;
                if (selection.length) {
                    Object.assign(stateToSet, this.performFrontEndValidation(selection));
                } else {
                    Object.assign(stateToSet, this.performRequiredValidation(selection));
                }
            }
            this.setState(stateToSet, () => {
                if (this.props.onChange) {
                    this.props.onChange(this.state);
                }
                stateCallbacks.forEach(callback => {
                    callback();
                });
            });
        }
    }

    onBlur(event) {
        //Note: We will always come here with event.type === "blur"
        //Note: Check for IE browser. For other browsers it is true, because
        //event.target cannot be undefined and activeElement is undefined!
        //let ieCheck = event.target !== document.activeElement;
        const outsideContent = DomUtils.isClickOutside(event, this.content.current);
        const newFocusedElement = event.relatedTarget || document.activeElement;
        const newFocusIsDeleteIcon = newFocusedElement.getAttribute("data-target") === "deleteX";
        const newFocusOnAnchor = this.anchor.current === newFocusedElement;
        const outsidePillsContainer = DomUtils.isClickOutside(event, this.pills.current);
        if (!outsideContent || (newFocusIsDeleteIcon && !outsidePillsContainer)) {
            //Note: If we blur on option, we must invoke change with that option.
            const newSelectionCode = newFocusedElement.getAttribute("data-code");
            this.onChange(event, newSelectionCode);
            //Internet Explorer will blur again if we don't wait a bit!
            setTimeout(() => {
                if (!this.props.hasInputAutosuggestion && this.props.options) {
                    this.anchor.current.focus();
                }
            });
        } else if (outsidePillsContainer && !newFocusOnAnchor) {
            if (this.state.active) {
                this.onClose(event, {
                    state: { focused: false }
                });
            } else {
                this.setState({
                    focused: false,
                    isSuggestionInputVisibleInSingleSelection: false
                });
            }
            super.onBlur();
        }
    }

    onFocus(event) {
        const previousFocusState = this.state.focused;
        let isInputVisibleSingleSelect;
        //show options on Focus in case of "hasInputAutosuggestion"
        if (this.props.autosuggestion && this.props.hasInputAutosuggestion) {
            if (this.props.options) {
                this.onOpen();
            }
        }

        if (!previousFocusState) {
            const newFocusedElement = event.target;
            const dataTarget = newFocusedElement.getAttribute("data-target");
            const isDeleteIcon = dataTarget === "deleteX";
            isInputVisibleSingleSelect =
                !!this.props.autosuggestion &&
                (!this.props.multiSelection || this.props.maxSelection === 1);
            const stateToSet = {
                focused: true,
                isSuggestionInputVisibleInSingleSelection: isInputVisibleSingleSelect
            };
            const setStateCallbacks = [
                () => {
                    if (this.props.autosuggestion) {
                        this.suggestionInput.current.focus();
                    } else {
                        this.anchor.current.focus();
                    }
                }
            ];
            if (isDeleteIcon) {
                const newCode = newFocusedElement.getAttribute("data-code");
                this.onChange(event, newCode, stateToSet, setStateCallbacks);
            } else {
                this.setState(stateToSet, setStateCallbacks[0]);
            }
        }
        super.onFocus(event);
    }

    onOpen() {
        const stateToSet = { active: true, valid: undefined };
        if (
            this.props.autosuggestion &&
            (!this.props.multiSelection || this.props.maxSelection === 1)
        ) {
            stateToSet.isSuggestionInputVisibleInSingleSelection = true;
        }
        this.setState(stateToSet, () => {
            if (this.props.onOpen) {
                this.props.onOpen(this.state);
            }
            //Whenever Dropdown is opened the focus is in the suggestion input.
            if (this.props.autosuggestion) {
                this.suggestionInput.current.focus();
            }
        });
    }

    onClose(event, options = {}, stateCallbacks = []) {
        const outsidePillsContainer = DomUtils.isClickOutside(event, this.pills.current);
        if (!outsidePillsContainer && event.type === "click") {
            //In case we are in multiselection case with pills, clicking inside pill container,
            //will not toggle the Dropdown active state!
            return;
        }

        const stateToSet = Object.assign({ active: false }, options.state || {});

        stateToSet.isSuggestionInputVisibleInSingleSelection = false;
        const selection = stateToSet.selection || this.state.selection;

        if (selection.length) {
            Object.assign(stateToSet, this.performFrontEndValidation(selection));
        } else {
            Object.assign(stateToSet, this.performRequiredValidation(selection));
        }

        if (this.props.autosuggestion && this.props.hasInputAutosuggestion && this.props.options) {
            if (selection && selection.length > 0) {
                stateToSet.suggestionValue = this.props.options.get(selection[0]).label;
            } else {
                stateToSet.suggestionValue = this.state.suggestionValue;
            }
        } else {
            stateToSet.suggestionValue = "";
        }
        //We preserve the keyboard Marker only when opened.
        this.markKeyboardSelection(this.keyboardMarker, null);
        this.keyboardMarker = null;
        this.setState(stateToSet, () => {
            if (options.keyboardSelection && !this.props.hasInputAutosuggestion) {
                //If we close the Dropdown due to keyboardSelection (and only on that case)
                //We want to return the focus on the suggestion input.
                //but not in case of "hasInputAutosuggestion"
                this.anchor.current.focus();
            }
            if (this.props.onClose) {
                this.props.onClose(this.state);
            }
            if (options.callback) {
                options.callback(this.state);
            }
            stateCallbacks.forEach(callback => {
                callback();
            });
        });
    }

    onChangeSuggestionValue(event) {
        if (this.state.selection.length > 0) {
            this.setState({ selection: [] });
        }
        this.setState({ suggestionValue: event.currentTarget.value }, () => {
            const filteredOptions = this.filterOptions();
            const newMarker =
                MapUtils.find(filteredOptions, (key, value) => {
                    return !value.disabled;
                }) || null;
            this.markKeyboardSelection(this.keyboardMarker, newMarker);
            this.keyboardMarker = newMarker;
            if (this.props.onChangeSuggestionValue) {
                this.props.onChangeSuggestionValue(this.state);
            }
        });
    }

    removeAccents(string) {
        //Canonical decomposition of the character - removing the listed accents
        //task http://jira.tinqin.com/browse/UGP-1819
        return string.normalize("NFD").replace(/[\u0300\u0301\u0302\u0308\u0327]/g, "");
    }

    filterOptions() {
        if (this.props.autosuggestionServerSide) {
            //We won't filter the options if BE will manage the suggestions.
            return this.props.options;
        }
        let filter = this.state.suggestionValue.toLowerCase();
        return MapUtils.filter(this.props.options, (key, value) => {
            let optionLabel = (value.label || "").toLowerCase();

            if (this.props.ingoreAccentOnSearch && optionLabel) {
                optionLabel = this.removeAccents(optionLabel);
                filter = this.removeAccents(filter);
            }
            if (this.props.filterStrategy === "startsWith") {
                return !optionLabel || optionLabel.indexOf(filter) === 0;
            } else {
                return !optionLabel || optionLabel.includes(filter);
            }
        });
    }

    buildItems() {
        const selectAllOption = [];
        if (
            this.props.multiSelection &&
            this.props.enableSelectAll &&
            !this.state.suggestionValue
        ) {
            selectAllOption.push([
                selectAllCode,
                {
                    label: this.props.selectAllText || "Select All"
                }
            ]);
        }
        const filteredOptions = this.filterOptions();
        const options = MapUtils.assign(new Map(selectAllOption), filteredOptions);

        return MapUtils.map(options, (key, value, index) => {
            let selected = this.state.selection.includes(key);
            if (key === selectAllCode) {
                const selectionLength = this.state.selection.length;
                const optionsLength = this.props.options.size;
                selected = selectionLength === optionsLength;
            }
            return (
                <DropdownOption
                    key={"option-" + index}
                    withCheckbox={this.props.optionsWithCheckboxes}
                    selected={selected}
                    code={key}
                    label={value.label}
                    icon={value.icon}
                    disabled={value.disabled}
                />
            );
        });
    }

    markKeyboardSelection(oldSelectionCode, newSelectedCode) {
        const content = this.content.current;
        if (content) {
            let newMarker, oldMarker;
            //Note: its important to first remore the oldSelection then set newSelection.
            //That way if oldSelection === newSelection, the selection will remain checked!
            if (oldSelectionCode) {
                oldMarker = content.querySelector(`li[data-code="${oldSelectionCode}"]`);
                if (oldMarker) {
                    oldMarker.classList.remove("tq-keyboard-nav");
                }
            }
            if (newSelectedCode) {
                newMarker = content.querySelector(`li[data-code="${newSelectedCode}"]`);
                newMarker.classList.add("tq-keyboard-nav");
            }
            let scrollTop = 0;
            if (newMarker && newMarker.offsetTop > 0) {
                scrollTop = newMarker.offsetTop - newMarker.offsetHeight;
            }

            return scrollTop;
        }
    }

    onKeyDown(event) {
        switch (event.key) {
            case "Tab":
                if (this.state.active) {
                    event.preventDefault();
                    this.onClose(event);
                }
                break;
            case "Backspace":
                if (
                    this.props.multiSelection &&
                    this.state.selection.length &&
                    this.props.autosuggestion &&
                    !this.state.suggestionValue
                ) {
                    this.onChange(event, this.state.selection[this.state.selection.length - 1]);
                }
                break;
            case "Enter":
                event.preventDefault();
                if (!this.state.active) {
                    event.stopPropagation();
                    this.onOpen(event);
                } else if (this.props.multiSelection) {
                    if (this.keyboardMarker) {
                        this.onChange(event, this.keyboardMarker);
                    } else {
                        this.onClose(event);
                    }
                } else if (this.keyboardMarker) {
                    this.onChange(event, this.keyboardMarker, { keyboardSelection: true });
                } else {
                    this.onClose(event);
                }
                break;
            case "ArrowUp":
            case "ArrowDown":
                event.preventDefault();
                let options = [];
                if (this.state.active) {
                    let firstOptionIndex = 0;
                    if (this.props.enableSelectAll && !this.state.suggestionValue) {
                        options.push(selectAllCode);
                        firstOptionIndex = 1;
                    }
                    const filteredOptions = this.filterOptions();
                    options = options.concat(MapUtils.keys(filteredOptions));
                    const keyboardMarker = this.keyboardMarker || options[firstOptionIndex];
                    let newMarkerIndex = options.indexOf(keyboardMarker) - 1;
                    if (event.key === "ArrowDown") {
                        newMarkerIndex = options.indexOf(this.keyboardMarker) + 1;
                    }
                    if (newMarkerIndex >= 0 && newMarkerIndex < options.length) {
                        const newKeyboardMarker = options[newMarkerIndex];
                        const scrollTop = this.markKeyboardSelection(
                            this.keyboardMarker,
                            newKeyboardMarker
                        );
                        this.keyboardMarker = newKeyboardMarker;
                        this.scrollbar.current.scrollTop(scrollTop);
                    }
                } else if (
                    !this.props.multiSelection &&
                    (!this.props.autosuggestion ||
                        !this.state.isSuggestionInputVisibleInSingleSelection)
                ) {
                    options = MapUtils.keys(this.props.options);
                    const enabledOptions = options.filter(option => {
                        const notDisabled = !this.props.options.get(option).disabled;
                        const isCurrentSelection = option === this.state.selection[0];
                        return notDisabled || isCurrentSelection;
                    });
                    let newSelectionIndex = 0;
                    if (this.state.selection.length) {
                        const currentSelectionIndex = enabledOptions.indexOf(
                            this.state.selection[0]
                        );
                        newSelectionIndex = currentSelectionIndex - 1;
                        if (!currentSelectionIndex) {
                            newSelectionIndex = enabledOptions.length - 1;
                        }
                        if (event.key === "ArrowDown") {
                            newSelectionIndex = currentSelectionIndex + 1;
                            if (currentSelectionIndex === enabledOptions.length - 1) {
                                newSelectionIndex = 0;
                            }
                        }
                    }
                    this.onChange(event, enabledOptions[newSelectionIndex]);
                }

                break;
            case "Escape":
                event.preventDefault();
                if (this.state.active) {
                    this.onClose(event);
                }
                break;
            default:
                const isInputSymbol = event.key.length === 1; //ignore special keys
                //Note: Android does not support onKeyDown properly.
                const superSpecialAndroidShit =
                    event.key === "Unidentified" && event.keyCode === 229;
                if (
                    !this.state.active &&
                    this.props.autosuggestion &&
                    event.key &&
                    event.key !== " " && //ignore "Space" key, which key is " " for unknown reasons
                    (isInputSymbol || superSpecialAndroidShit) &&
                    !event.ctrlKey &&
                    !event.altKey
                ) {
                    if (this.props.hasInputAutosuggestion && this.state.suggestionValue) {
                        this.onOpen();
                    } else if (!this.state.suggestionValue) {
                        return;
                    } else {
                        this.onOpen();
                    }
                }
                return;
        }
    }

    render() {
        //Deal with error message to be displayed and explicit hint.
        const isHintDiscrete = this.props.discreteHint !== false; //discrete by default.
        let explicitHint;
        const errorToDisplay = this.retrieveValidationMessages();
        if (!isHintDiscrete && this.props.hint) {
            explicitHint = <div className="tq-help-text">{this.props.hint}</div>;
        }
        //Deal with Dropdown CSS classes
        const singleSelectionWithSuggest = this.state.isSuggestionInputVisibleInSingleSelection;
        let valueContainerClass;
        if (this.props.iconTrigger) {
            valueContainerClass = "tq-select-image";
        } else {
            valueContainerClass = "tq-select";
            if (
                this.props.displayStyle === "ItemBlocks" ||
                singleSelectionWithSuggest ||
                this.props.hasInputAutosuggestion
            ) {
                valueContainerClass += " tq-has-pills";
                if (!this.props.skipExpandCaret) {
                    valueContainerClass += " tq-has-action";
                }
            }
            if (this.props.truncateLongValue) {
                valueContainerClass += " tq-overflow";
            }
        }
        let selectionClass = "tq-drop-down-selected";
        if (
            this.props.displayStyle === "ItemBlocks" ||
            singleSelectionWithSuggest ||
            this.props.hasInputAutosuggestion
        ) {
            selectionClass = "tq-pills-container";
        }
        if (this.state.focused || this.props.hasInputAutosuggestion) {
            selectionClass += " tq-active";
        }
        //Deal with Labeled component props.
        const labelProps = fetchLabeledProps(this.props, this.state, {
            valueContainerClass: valueContainerClass,
            hasFeedback: !!errorToDisplay
        });
        //Deal with toggle handler
        let onToggle;
        if (!this.props.disabled) {
            onToggle = this.state.active ? this.onClose : this.onOpen;
        }
        //Deal with suggestion input visibility
        let suggestionInputVisible = this.state.focused || this.props.hasInputAutosuggestion;
        if (!this.props.multiSelection || this.props.maxSelection === 1) {
            suggestionInputVisible = this.state.isSuggestionInputVisibleInSingleSelection;
        }
        let popoverMinWidthToAnchor = true;
        if (this.props.hasOwnProperty("popoverMinWidthToAnchor")) {
            popoverMinWidthToAnchor = this.props.popoverMinWidthToAnchor;
        }
        return (
            <Labeled {...labelProps}>
                <div
                    ref={this.anchor}
                    onClick={onToggle}
                    tabIndex={this.props.disabled ? "-1" : "0"}
                    onFocus={!this.props.disabled ? this.onFocus : undefined}
                    onKeyDown={!this.props.disabled ? this.onKeyDown : undefined}
                    onBlur={!this.props.disabled ? this.onBlur : undefined}
                    className={selectionClass}
                >
                    <DropdownSelection
                        {...this.props}
                        options={this.props.options}
                        suggestionValue={this.state.suggestionValue}
                        onChangeSuggestionValue={this.onChangeSuggestionValue}
                        selection={this.state.selection}
                        noneSelectedText={this.props.noneSelectedText}
                        suggestionInputVisible={suggestionInputVisible}
                        suggestionRef={this.suggestionInput}
                        pillsRef={this.pills}
                        hasInputAutosuggestion={this.props.hasInputAutosuggestion}
                    />
                    <div className="tq-feedback-container">
                        {this.props.iconTriggerShowSelected && (
                            <div className="tq-input-feedback">
                                <i className="tq-feedback-icon tq-select-counter">
                                    {this.state.selection.length}
                                </i>
                            </div>
                        )}
                        {!this.props.iconTrigger && errorToDisplay}
                        {!this.props.iconTrigger && !this.props.skipExpandCaret && !errorToDisplay && (
                            <div className="tq-input-feedback tq-select-trigger">
                                <i className="tq-icon-arrow-down-big" />
                            </div>
                        )}
                    </div>
                </div>
                <Popover
                    extraClasses={this.props.popoverClasses}
                    popoverType={this.props.popoverType}
                    preferredPosition="bottom-right"
                    minWidthToAnchor={popoverMinWidthToAnchor}
                    maxWidthToAnchor={this.props.popoverMaxWidthToAnchor}
                    anchor={this.anchor.current}
                    active={this.state.active}
                >
                    {this.props.options && this.props.options.size > 0 && (
                        <ul className="tq-drop-down-options" ref={this.content}>
                            <Scrollbar
                                id={"scrollbar"}
                                ref={this.scrollbar}
                                hideTracksWhenNotNeeded
                                autoHeight
                                autoHeightMin={0}
                                autoHeightMax={200}
                            >
                                {this.buildItems()}
                            </Scrollbar>
                        </ul>
                    )}
                </Popover>
                {explicitHint}
            </Labeled>
        );
    }
}

Dropdown.displayName = "Dropdown";

Dropdown.propTypes = Object.assign({}, Validatable.propTypes, Labeled.propTypes, {
    autosuggestion: PropTypes.bool,
    filterStrategy: PropTypes.oneOf(["includes", "startsWith"]),
    autosuggestionServerSide: PropTypes.bool,
    skipExpandCaret: PropTypes.bool,
    extraClasses: PropTypes.string,
    popoverClasses: PropTypes.string,
    selectedText: PropTypes.string,
    allSelectedText: PropTypes.string,
    noneSelectedText: PropTypes.string,
    selection: PropTypes.array,
    selectionPrefix: PropTypes.string,
    multiSelection: PropTypes.bool,
    maxSelection: PropTypes.number,
    options: PropTypes.object.isRequired,
    optionsWithCheckboxes: PropTypes.bool,
    iconTrigger: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]), //In case icon is derived from the option we can use boolean!
    iconTriggerShowSelected: PropTypes.bool,
    displayStyle: PropTypes.oneOf(["ItemCount", "ItemBlocks", "ItemText"]),
    truncateLongValue: PropTypes.bool,
    enableSelectAll: PropTypes.bool,
    selectAllText: PropTypes.string,
    popoverType: PropTypes.oneOf(["default", "keep", "load"]),
    popoverMinWidthToAnchor: PropTypes.bool,
    popoverMaxWidthToAnchor: PropTypes.bool,
    onChange: PropTypes.func,
    onChangeSuggestionValue: PropTypes.func,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    hasInputAutosuggestion: PropTypes.bool,
    ingoreAccentOnSearch: PropTypes.bool
});

Dropdown.documentedProps = documentedProps;
