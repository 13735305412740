import React from "react";
import webui from "@tinqin/tinqin-web-ui";

const TextOutput = props => {
    const onClick = () => {
        webui.getUtils().dispatchSirenActions(props, ["click", "default"]);
    };
    const dataProps = webui.getUtils().extractComponentProps(props, {
        additionalProps: ["tag", "extraClasses", "value", "noValue"],
        propsDefaults: {
            tag: "p",
            extraClasses: ""
        },
        aliases: { value: "label" }
    });
    if (!dataProps.noValue && !dataProps.value && dataProps.value !== 0) {
        return webui.getUtils().getEmptyContent();
    }
    return React.createElement(dataProps.tag, { className: dataProps.extraClasses, onClick }, [
        dataProps.value,
        ...webui.getUtils().resolveChildren(props)
    ]);
};

export default webui.connectComponent()(TextOutput);
