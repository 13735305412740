/**
 * Created by m.igrachev on 6/16/2017.
 */
import Validatable from "@tinqin/tinqin-ui-components/src/validatable/Validatable";
import Labeled from "@tinqin/tinqin-ui-components/src/label/Labeled";

const ValidatableProps = Validatable.documentedProps;
const LabeledProps = Labeled.documentedProps;

const documentedProperties = [].concat(ValidatableProps, LabeledProps, [
    {
        name: "extraClasses",
        type: "String",
        defaultValue: "n/a",
        isRequired: "false",
        description: "Extra css classes to be added to Dropdown container."
    },
    {
        name: "selection",
        type: "Array",
        defaultValue: "n/a",
        isRequired: "false",
        description: "An array of options codes representing the Dropdown selection."
    },
    {
        name: "noneSelectedString",
        type: "String",
        defaultValue: "---",
        isRequired: "false",
        description: "A string to be displayed when no value is selected."
    },
    {
        name: "selectionPrefix",
        type: "String",
        defaultValue: "",
        isRequired: "false",
        description: "A string to be displayed in front of the selected value."
    },
    {
        name: "onChange",
        type: "Function",
        defaultValue: "n/a",
        isRequired: "false",
        description: "A callback function to be called when an item of the dropdown is selected."
    },
    {
        name: "onOpen",
        type: "Function",
        defaultValue: "n/a",
        isRequired: "false",
        description: "A callback function to be called when dropdown is opened."
    },
    {
        name: "onClose",
        type: "Function",
        defaultValue: "n/a",
        isRequired: "false",
        description: "A callback function to be called when dropdown is closed."
    },
    {
        name: "truncateLongValue",
        type: "Boolean",
        defaultValue: "false",
        isRequired: "false",
        description: "If set to true will truncate values longer than value container's width."
    },
    {
        name: "popoverType",
        type: '["default", "keep", "load"]',
        defaultValue: "default",
        isRequired: "false",
        description:
            "Determine when we will have popover content of the dropdown in dom tree.\n" +
            "Тhe default behavior is to render the content only when the popover is active and to remove it when not active.\n" +
            "If 'keep', renders the content when active and keep it in the dom tree when is not active.\n" +
            "If 'load', always render the content in the dom.\n" +
            "Accepts oneOf(['keep', 'load'])"
    },
    {
        name: "popoverMinWidthToAnchor",
        type: "Boolean",
        defaultValue: "true",
        isRequired: "false",
        description: "If set to false will not force popover width to be minimum the anchor width"
    },
    {
        name: "popoverMaxWidthToAnchor",
        type: "Boolean",
        defaultValue: "false",
        isRequired: "false",
        description: "If set to true will force popover width to be maximum the anchor width"
    },
    {
        name: "popoverClasses",
        type: "String",
        defaultValue: "n/a",
        isRequired: "false",
        description: "Extra classes for Popover. It is the way for custom styling."
    },
    {
        name: "selectedText",
        type: "String",
        defaultValue: "n/a",
        isRequired: "false",
        description: "The text to show in ItemsCount mode after X/Y."
    },
    {
        name: "allSelectedText",
        type: "String",
        defaultValue: "n/a",
        isRequired: "false",
        description: "The text to show in ItemsCount mode after X/Y."
    },
    {
        name: "multiSelection",
        type: "Boolean",
        defaultValue: "false",
        isRequired: "false",
        description: "If set to true enables multi-selection for the Dropdown."
    },
    {
        name: "maxSelection",
        type: "Number",
        defaultValue: "false",
        isRequired: "false",
        description: "If set to true enables multi-selection for the Dropdown."
    },
    {
        name: "options",
        type: "Object",
        defaultValue: "n/a",
        isRequired: "true",
        description: "Object representing the Dropdown options."
    },
    {
        name: "optionsWithCheckboxes",
        type: "Boolean",
        defaultValue: "false",
        isRequired: "false",
        description: "If set to true will place checkbox before options icon and text."
    },
    {
        name: "iconTrigger",
        type: "String",
        defaultValue: "n/a",
        isRequired: "false",
        description: "An icon to be used as trigger for the Dropdown."
    },
    {
        name: "iconTriggerShowSelected",
        type: "Boolean",
        defaultValue: "false",
        isRequired: "false",
        description: "If set to true will display additional helper count aside of the iconTrigger."
    },
    {
        name: "displayStyle",
        type: '["ItemCount", "ItemBlocks", "ItemText"]',
        defaultValue: "false",
        isRequired: "false",
        description: "The visualization type of the Dropdown selection."
    },
    {
        name: "enableSelectAll",
        type: "Boolean",
        defaultValue: "false",
        isRequired: "false",
        description:
            "If set to true enabled Select All option. Considered only with multi-selection!"
    },
    {
        name: "selectAllText",
        type: "String",
        defaultValue: "n/a",
        isRequired: "false",
        description: "Defines Select All option's text. Considered only with enableSelectAll!"
    },
    {
        name: "autosuggestion",
        type: "Boolean",
        defaultValue: "false",
        isRequired: "false",
        description: "If set to true autosiggestion functionality will become true."
    },
    {
        name: "autosuggestionServerSide",
        type: "Boolean",
        defaultValue: "false",
        isRequired: "false",
        description: "If set to true autosiggestion filtering will be done only on BE side."
    },
    {
        name: "skipExpandCaret",
        type: "Boolean",
        defaultValue: "false",
        isRequired: "false",
        description: "If set to true will skip the Dropdown expand/collapse icon."
    },
    {
        name: "onChangeSuggestionValue",
        type: "Function",
        defaultValue: "false",
        isRequired: "false",
        description:
            "A callback function to be executed when suggestion value is changed. Not debounced here! If you want it debounced you shall write it!"
    },
    {
        name: "popoverClasses",
        type: "String",
        defaultValue: "n/a",
        isRequired: "false",
        description: "Extra css classes to be added to Popover content container of the dropdown."
    },
    {
        name: "popoverType",
        type: '["default", "keep", "load"]',
        defaultValue: "default",
        isRequired: "false",
        description:
            "Determine when we will have popover content of the dropdown in dom tree.\n" +
            "Тhe default behavior is to render the content only when the popover is active and to remove it when not active.\n" +
            "If 'keep', renders the content when active and keep it in the dom tree when is not active.\n" +
            "If 'load', always render the content in the dom.\n" +
            "Accepts oneOf(['keep', 'load'])"
    },
    {
        name: "filterStrategy",
        type: '["includes", "startsWith"]',
        defaultValue: "includes",
        isRequired: "false",
        description:
            "Sets the client side filter strategy.\n" +
            "If 'includes', renders all the options which label includes the searching criteria.\n" +
            "If 'startsWith', renders all the options which label starts with the searching criteria.\n" +
            "Accepts oneOf(['includes', 'startsWith'])"
    }
]);

export default documentedProperties;
