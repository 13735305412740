import React from "react";
import webui, { BaseComponent } from "@tinqin/tinqin-web-ui";

const extractComponentProps = webui.getUtils().extractComponentProps;

class FlexContainer extends BaseComponent {
    buildItems() {
        const config = this.props.config || {};
        const children = config.children || [];
        const hasItemBinding = !!config.item;
        const hasItemsBinding = !!config.items;
        const hasFlexItemChild = !!children.find(child => child.type === "flexItem");

        //Consumer can define configurable children
        //Note that in this case all flex container items are expected to be "flexItem"
        if (hasItemBinding || hasItemsBinding || hasFlexItemChild) {
            return this.resolveChildren();
        }

        //In case flex item needs no configuration we can omit it in the configuration.
        //Note that in this case flex container will wrap its children in flex item.
        return this.resolveChildren().map(element => {
            return (
                <div className={"tq-flex-item"} key={element.key}>
                    {element}
                </div>
            );
        });
    }

    computeClassName() {
        const props = extractComponentProps(this.props, {
            additionalProps: ["itemsLarge", "itemsMedium", "itemsSmall", "stretch", "extraClasses"]
        });

        let className = "tq-flex-container";
        if (props.extraClasses) {
            className += " " + props.extraClasses;
        }
        if (props.itemsLarge) {
            className += ` tq-flex-item-l-${props.itemsLarge}`;
        }
        if (props.itemsMedium) {
            className += ` tq-flex-item-m-${props.itemsMedium}`;
        }
        if (props.itemsSmall) {
            className += ` tq-flex-item-s-${props.itemsSmall}`;
        }
        if (props.stretch) {
            className += " tq-flex-item-stretch";
        }

        return className;
    }

    render() {
        const dataProps = extractComponentProps(this.props, {
            additionalProps: ["tag"],
            propsDefaults: {
                tag: "div"
            }
        });
        const className = this.computeClassName();
        const items = this.buildItems();

        if (this.props.config.bindTo && !this.props.data) {
            return this.getEmptyContent();
        }

        // return <div className={className}>{items}</div>;
        return React.createElement(
            dataProps.tag,
            {
                className
            },
            items
        );
    }
}

export default webui.connectComponent()(FlexContainer);
