import webui, { BaseComponent } from "@tinqin/tinqin-web-ui";
import React from "react";
import * as ReactDOM from "react-dom";

const extractComponentProps = webui.getUtils().extractComponentProps;

class Portal extends BaseComponent {
    render() {
        const props = extractComponentProps(this.props, {
            additionalProps: ["placeToPortId"]
        });
        const placeToPort = document.querySelector(`#${props.placeToPortId}`);
        if (!placeToPort) {
            return this.getEmptyContent();
        }
        const children = this.resolveChildren();

        return <>{ReactDOM.createPortal(children, placeToPort)}</>;
    }
}

export default webui.connectComponent()(Portal);
