import React from "react";
import webui, { BaseComponent } from "@tinqin/tinqin-web-ui";
import ResponsiveHOC from "../../../HOC/components/responsive";

//component for showing "see more" button on Small screens for related articles
class RelatedArticlesContainer extends BaseComponent {
    container = React.createRef();

    state = {
        shownOnce: false
    };

    onShowMore = () => {
        this.setState({
            shownOnce: true
        });
    };

    shouldRenderChildren = () => {
        return !this.smallScreen() || this.state.shownOnce;
    };

    smallScreen = () => {
        return this.props.size === "S" || this.props.size === "XS";
    };

    getInnerChildren = () => {
        return this.container.current.children;
    };
    //removing any tq-hide classes
    adjustChildren = () => {
        const children = this.getInnerChildren();
        for (let i = 0; i < children.length; ++i) {
            children[i].classList.add("un-article-show");
        }
    };

    componentDidMount = () => this.adjustChildren();

    componentDidUpdate = () => this.adjustChildren();

    render() {
        const dataProps = webui.getUtils().extractComponentProps(this.props, {
            additionalProps: ["label", "extraClasses"],
            propsDefaults: {
                //default label
                label: "Voir davantage",
                extraClasses: ""
            }
        });
        const renderChildren = this.shouldRenderChildren();
        return (
            <div className={dataProps.extraClasses} ref={this.container}>
                {this.resolveChildren().filter((x, i) => {
                    if (this.smallScreen() && !this.state.shownOnce && i === 0) {
                        return x;
                    } else if (this.state.shownOnce || !this.smallScreen()) {
                        return x;
                    } else {
                        return false;
                    }
                })}
                {!renderChildren && (
                    <div className="tq-flex seeMoreButton" style={{ width: "100%" }}>
                        <button
                            onClick={this.onShowMore}
                            className="tq-button tq-button-border tq-offset-top-s un-button-border-blue"
                        >
                            <span className="tq-text-node">{dataProps.label}</span>
                        </button>
                    </div>
                )}
            </div>
        );
    }
}

export default webui.connectComponent()(ResponsiveHOC(RelatedArticlesContainer));
