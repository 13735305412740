import webui from "@tinqin/tinqin-web-ui";
const { getUiState, getEntityPaths } = webui.getActionByName("utils");

export const toggleSection = (action, widgetId, currentPath) => dispatch => {
    let target = action.target ?? currentPath;
    target = dispatch(getEntityPaths(target))[0];
    const setUiState = webui.getActionByName("component.setUiState");
    let expanded = dispatch(getUiState(target)).expanded;
    if (action.properties && action.properties.collapse) {
        expanded = true;
    }
    if (action.properties && action.properties.expand) {
        expanded = false;
    }
    dispatch(setUiState(widgetId, target, { expanded: !expanded }));
};
