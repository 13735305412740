export function computeTargetSlide(slideIndex, slidesCount) {
    // Shifting targetSlide back into the range
    let targetSlide;

    if (slideIndex < 0) {
        targetSlide = slideIndex + slidesCount;
    } else if (slideIndex >= slidesCount) {
        targetSlide = slideIndex - slidesCount;
    } else {
        targetSlide = slideIndex;
    }

    return targetSlide;
}

export function computeCurrentSlide(slideIndex, slidesCount, isEndlessRotation) {
    let currentSlide;

    if (slideIndex < 0) {
        // Pre-cloned slides
        if (isEndlessRotation) {
            currentSlide = slidesCount - 1;
        }
    } else if (slideIndex >= slidesCount) {
        // Post-cloned slides
        if (isEndlessRotation) {
            currentSlide = 0;
        }
    } else {
        // Origin slides
        currentSlide = slideIndex;
    }

    return currentSlide;
}
