const location = window.location;
let locationOrigin = location.origin;
const locationSearch = location.search || "";

//IE10 and below issue, also application in Compatibility Mode in IE
if (!locationOrigin) {
    locationOrigin =
        location.protocol + "//" + location.hostname + (location.port ? ":" + location.port : "");
}

if (!window.appContext && window.appContext !== "") {
    window.appContext = "";
}

//webui specific parameters
export const MIXIN_ROOT = "/configuration";
export const ROOT_PATH = window.appContext;
export const ROOT_PATH_FULL = `${locationOrigin}${ROOT_PATH}`;
export const API_ROOT = `${locationOrigin}${window.appContext}/api`;
export const BOOT = "/application-boot" + locationSearch;
export const FLOW_ROOT = "/flow";
export const STEP_ROOT = "/step";
export const DASHBOARD_ROOT = "/dashboard";

export default {
    MIXIN_ROOT,
    ROOT_PATH,
    ROOT_PATH_FULL,
    API_ROOT,
    BOOT,
    FLOW_ROOT,
    STEP_ROOT,
    DASHBOARD_ROOT
};
