export default (sirenAction, widgetId, currentPath) => {
    const tag = sirenAction.properties?.tag;
    const attributes = sirenAction.properties?.attr || {};
    const appendTo = sirenAction.properties?.appendTo || "body";

    const element = document.createElement(tag);
    Object.keys(attributes).forEach(key => {
        element[key] = attributes[key];
    });

    document[appendTo].appendChild(element);
};
