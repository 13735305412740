import { ISirenAction, TAppThunk } from "../../utils/commonTypes";
const URL_SCROLL_MAP = "scrollMap";
interface ISirenActionCustom extends ISirenAction {
    historyNavigation: boolean;
}
interface IScrollMap {
    [key: string]: number
}
//keeps the last visited url string
let lastVisitedUrl: string;

//On dashboard load scrollToTop if not opened from history
export const onDashboardLoaded = (
    sirenAction: ISirenActionCustom
): TAppThunk => async (_dispatch: any) => {
    if (!sirenAction.historyNavigation) {
        await saveScrollPositionForCurrentDashboard();
        //Scroll to top
        window.scroll(0, 0);
    }
};

//On dashboard load save url with the scrollY position
const saveScrollPositionForCurrentDashboard = async () => {
    //Skip query params
    const url: string = (window.location.origin + window.location.pathname).replace(/\/$/, ""); //remove trailing slash if any
    const scrollMap: IScrollMap = JSON.parse(window.sessionStorage.getItem(URL_SCROLL_MAP)) || {};
    //save scrollY for current dashboard
    scrollMap[url] = window.scrollY;
    //Save new data to sessionStorage
    window.sessionStorage.setItem(URL_SCROLL_MAP, JSON.stringify(scrollMap));
};

//On widgetsLoaded restores the url saved scroll position
export const restoreScrolledPositionForDashboard = (
    sirenAction: ISirenActionCustom
): TAppThunk => (_dispatch: any) => {
    //Skip query params
    const url: string = (window.location.origin + window.location.pathname).replace(/\/$/, ""); //remove trailing slash if any
    const scrollMap: IScrollMap = JSON.parse(window.sessionStorage.getItem(URL_SCROLL_MAP));
    if (sirenAction.historyNavigation && scrollMap.hasOwnProperty(url)) {
        //Scroll to previously remembered position
        window.scroll(0, scrollMap[url]);
    }
    if (!sirenAction.historyNavigation) {
        //update the current url
        lastVisitedUrl = url;
    }
};

//In case we go to a page and scroll, but instead of click on some link we go back
//As a result the url is changed first, so we need to save the scroll for the previous url before the new dashboard is loaded
//The first event that happens is the "popstate" - so save the scroll position for the previous url in the event listener
window.addEventListener("popstate", () => {
    //save scrollY for previous url before dashboard is changed
    const scrollMap: IScrollMap = JSON.parse(window.sessionStorage.getItem(URL_SCROLL_MAP));
    scrollMap[lastVisitedUrl] = window.scrollY;
    //Skip query params
    lastVisitedUrl = (window.location.origin + window.location.pathname).replace(/\/$/, ""); //remove trailing slash if any
    //Save new data to sessionStorage
    window.sessionStorage.setItem(URL_SCROLL_MAP, JSON.stringify(scrollMap));
});
